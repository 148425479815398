export enum ReferencesText {
  r0 = `[1] HealthEngine Blog. 2021. Anatomy of the human heart and cardiovascular system | myVMC. [online] Available at: <https://healthengine.com.au/info/cardiovascular-system-heart> [Accessed July 2021].

[2] Rchsd.org.2021. Heart and Circulatory System.[online] Available at: <https://www.rchsd.org/health-articles/heart-and-circulatory-system/> [Accessed July 2021].

[3] World Heart Federation. 2021. What is CVD ? - World Heart Federation. [online] Available at: <https://world-heart-federation.org/what-is-cvd/> [Accessed July 2021].

[4] Yale Medicine. 2021. Heart Surgery for Heart Failure. [online] Available at: <https://www.yalemedicine.org/conditions/heart-failure-surgery> [Accessed July 2021].

[5] nhs.uk. 2021. Heart failure - Treatment. [online] Available at: <https://www.nhs.uk/conditions/heart-failure/treatment/> [Accessed July 2021].

[6] nhs.uk. 2021. Heart failure - Living with. [online] Available at: <https://www.nhs.uk/conditions/heart-failure/living-with/> [Accessed July 2021].

[7] nhs.uk. 2021. Symptoms of a heart attack. [online] Available at: <https://www.nhs.uk/conditions/heart-attack/symptoms/> [Accessed  July 2021].

[8] nhs.uk. 2021. Heart failure - Symptoms. [online] Available at: <https://www.nhs.uk/conditions/heart-failure/symptoms/> [Accessed July 2021].

[9] Cassata, C.and Michael Cutler, P., 2021. What Is Congestive Heart Failure ? Symptoms, Causes, Diagnosis, Treatment, and Prevention | Everyday Health. [online] EverydayHealth.com.Available at: <https://www.everydayhealth.com/congestive-heart-failure/guide/#:~:text=Coronary%20artery%20disease%20is%20the,muscle's%20ability%20to%20pump%20normally.> [Accessed July 2021].

[10] Information, H., Disease, K., Work, Y., Work, Y.and Health, N., 2021. Your Kidneys & How They Work | NIDDK. [online] National Institute of Diabetes and Digestive and Kidney Diseases.Available at: <https://www.niddk.nih.gov/health-information/kidney-disease/kidneys-how-they-work> [Accessed July 2021].

[11] Information, H., Disease, K., (CKD), C., Disease ?, W., Disease ?, W.and Health, N., 2021. What Is Chronic Kidney Disease ? | NIDDK. [online] National Institute of Diabetes and Digestive and Kidney Diseases.Available at: <https://www.niddk.nih.gov/health-information/kidney-disease/chronic-kidney-disease-ckd/what-is-chronic-kidney-disease> [Accessed July  2021].

[12] National Kidney Foundation. 2021. 10 Signs You May Have Kidney Disease. [online] Available at: <https://www.kidney.org/news/ekidney/august14/10_Signs_You_May_Have_Kidney_Disease> [Accessed July 2021].

[13] Information, H., Disease, K., (CKD), C., CKD, P., Disease, P.and Health, N., 2021. Preventing Chronic Kidney Disease | NIDDK. [online] National Institute of Diabetes and Digestive and Kidney Diseases.Available at: <https://www.niddk.nih.gov/health-information/kidney-disease/chronic-kidney-disease-ckd/prevention> [Accessed July 2021].

[14] work ?, H., 2021. How does the pancreas work?.[online] Ncbi.nlm.nih.gov.Available at: <https://www.ncbi.nlm.nih.gov/books/NBK279306/> [Accessed July 2021].

[15] Diabetes. 2021. The pancreas is an organ located behind the lower part of the stomach, in front of the spine and plays an important part in diabetes.. [online] Available at: <https://www.diabetes.co.uk/body/pancreas-and-diabetes.html> [Accessed July 2021].

[16] nhs.uk. 2021. Diabetes. [online] Available at: <https://www.nhs.uk/conditions/diabetes/> [Accessed July 2021].

[17] diabetes, A.and diabetes, T., 2021. Type 2 diabetes. [online] Idf.org.Available at: <https://www.idf.org/aboutdiabetes/type-2-diabetes.html> [Accessed July 2021].

[18] Diabetes.org.uk. 2021.[online] Available at: <https://www.diabetes.org.uk/resources-s3/2017-11/diabetes_in_the_uk_2010.pdf> [Accessed July 2021].

[19] nhs.uk. 2021. Type 2 diabetes. [online] Available at: <https://www.nhs.uk/conditions/type-2-diabetes/> [Accessed July 2021].

[20] UK, D., exercise, D., you, S.and possible, Y., 2021. Diabetes and exercise. [online] Diabetes UK.Available at: <https://www.diabetes.org.uk/guide-to-diabetes/managing-your-diabetes/exercise> [Accessed July 2021].

[21] Diabetes. 2021. Polyuria is a condition where the body urinates more than usual and passes excessive or abnormally large amounts of urine each time you urinate.. [online] Available at: <https://www.diabetes.co.uk/symptoms/polyuria.html> [Accessed July 2021].

[22] Diabetes. 2021. People with diabetes have an increased risk of dehydration as high blood glucose levels lead to decreased hydration in the body.. [online] Available at: <https://www.diabetes.co.uk/dehydration-and-diabetes.html> [Accessed July 2021].

[23] Diabetes. 2021. Polyphagia is the medical term used to describe excessive hunger or increased appetite and is one of the 3 main symptoms of diabetes.. [online] Available at: <https://www.diabetes.co.uk/symptoms/polyphagia.html> [Accessed July 2021].

[24] Diabetes. 2021. Many people with diabetes will describe themselves as feeling tired, lethargic or fatigued at times.. [online] Available at: <https://www.diabetes.co.uk/tiredness-and-diabetes.html> [Accessed 23 June 2021].

[25] Information, H., Overview, D., Problems, P., Disease, D., Disease, D.and Health, N., 2021. Diabetic Eye Disease | NIDDK. [online] National Institute of Diabetes and Digestive and Kidney Diseases.Available at: <https://www.niddk.nih.gov/health-information/diabetes/overview/preventing-problems/diabetic-eye-disease> [Accessed July 2021].

[26] Diabetes. 2021. Poor Blood Circulation. [online] Available at: <https://www.diabetes.co.uk/diabetes-complications/poor-blood-circulation.html> [Accessed July 2021].

[27] UK, D., damage), P., home, L.and possible, Y., 2021. Peripheral neuropathy(nerve damage). [online] Diabetes UK.Available at: <https://www.diabetes.org.uk/guide-to-diabetes/complications/nerves_neuropathy> [Accessed July 2021].

[28] Nhsinform.scot. 2021. Hypoglycaemia(low blood sugar) symptoms and treatment. [online] Available at: <https://www.nhsinform.scot/illnesses-and-conditions/blood-and-lymph/hypoglycaemia-low-blood-sugar> [Accessed July 2021].

[29] UK, D., disease, D., confidence, M.and possible, Y., 2021. Diabetes and gum disease. [online] Diabetes UK.Available at: <https://www.diabetes.org.uk/guide-to-diabetes/complications/gum-disease> [Accessed July 2021].

[30] Information, H., Disease, K., Disease, H., Disease, H.and Health, N., 2021. Heart Disease & Kidney Disease | NIDDK. [online] National Institute of Diabetes and Digestive and Kidney Diseases.Available at: <https://www.niddk.nih.gov/health-information/kidney-disease/heart-disease> [Accessed July  2021].

[31] Nhlbi.nih.gov. 2021. Heart Failure | NHLBI, NIH. [online] Available at: <https://www.nhlbi.nih.gov/health-topics/heart-failure> [Accessed July  2021].

[32] Spectrum.diabetesjournals.org. 2021.[online] Available at: <https://spectrum.diabetesjournals.org/content/diaspect/21/3/160.full.pdf> [Accessed  July 2021].

[33] National Kidney Foundation. 2021. The Heart and Kidney Connection. [online] Available at: <https://www.kidney.org/atoz/content/heart-and-kidney-connection> [Accessed July 2021].

[34] Information, H., Disease, K., Work, Y., Work, Y.and Health, N., 2021. Your Kidneys & How They Work | NIDDK. [online] National Institute of Diabetes and Digestive and Kidney Diseases.Available at: <https://www.niddk.nih.gov/health-information/kidney-disease/kidneys-how-they-work> [Accessed July 2021].

[35] Kidney.org. 2021.[online] Available at: <https://www.kidney.org/sites/default/files/Heart_Failure_and_CKD_2018.pdf> [Accessed July 2021].

[36] Information, H., Overview, D., Problems, P., Disease, D., Disease, D.and Health, N., 2021. Diabetic Kidney Disease | NIDDK. [online] National Institute of Diabetes and Digestive and Kidney Diseases.Available at: <https://www.niddk.nih.gov/health-information/diabetes/overview/preventing-problems/diabetic-kidney-disease> [Accessed July 2021].

[37] National Kidney Foundation. 2021. Diabetes - A Major Risk Factor for Kidney Disease. [online] Available at: <https://www.kidney.org/atoz/content/diabetes> [Accessed July 2021].

[38] National Kidney Foundation. 2021. Sugar and Your Kidneys. [online] Available at: <https://www.kidney.org/content/sugar-and-your-kidneys> [Accessed July 2021].

[39] Information, H., Disease, K., Disease, H., Disease, H.and Health, N., 2021. High Blood Pressure & Kidney Disease | NIDDK. [online] National Institute of Diabetes and Digestive and Kidney Diseases.Available at: <https://www.niddk.nih.gov/health-information/kidney-disease/high-blood-pressure> [Accessed July 2021].

[40] Diabetes. 2021 Edema(oedema) is a build up of fluid in the body(water retention) which causes swelling.Available at: <https://www.diabetes.co.uk/diabetes-complications/edema-and-diabetes.html> [Accessed July 2021]

[41] Vascularsociety.org.uk. 2021. The Circulatory System - Vascular Society. [online] Available at: <https://www.vascularsociety.org.uk/patients/vascular_health/the_circulatory_system.aspx> [Accessed July 2021].

[42] Livescience.com. 2021. The Circulatory System: An Amazing Circuit That Keeps Our Bodies Going | Live Science. [online] Available at: <https://www.livescience.com/22486-circulatory-system.html> [Accessed July2021].

[43] National Kidney Foundation. 2021. How Your Kidneys Work. [online] Available at: <https://www.kidney.org/kidneydisease/howkidneyswrk> [Accessed July 2021].

[44] Bhf.org.uk. 2021. How your heart works. [online] Available at: <https://www.bhf.org.uk/informationsupport/how-a-healthy-heart-works> [Accessed July 2021].

[45] work ?, H., 2021. How does the blood circulatory system work?.[online] Ncbi.nlm.nih.gov.Available at: <https://www.ncbi.nlm.nih.gov/books/NBK279250/> [Accessed July 2021].

[46] nhs.uk. 2021. Cardiovascular disease. [online] Available at: <https://www.nhs.uk/conditions/cardiovascular-disease/> [Accessed 25 June 2021].

[47] www.heart.org. 2021. Types of Heart Failure. [online] Available at: <https://www.heart.org/en/health-topics/heart-failure/what-is-heart-failure/types-of-heart-failure> [Accessed July 2021].

[48] Circulation. 2021. Shortness of Breath. [online] Available at: <https://www.ahajournals.org/doi/full/10.1161/01.CIR.0000075956.36340.78> [Accessed July 2021].

[49] Cardiomyopathy.org. 2021. Heart failure. [online] Available at: <https://www.cardiomyopathy.org/about-cardiomyopathy/heart-failure> [Accessed July 2021].

[50] Heart - Failure.net. 2021. Are Poor Appetite and Nausea Symptoms of Heart Failure?.[online] Available at: <https://heart-failure.net/symptoms/appetite-loss-nausea> [Accessed July 2021].

[51] Black, R., 2021. Brain Fog After Heart Failure Improves with Walking and Brain Games. [online] EndocrineWeb.Available at: <https://www.endocrineweb.com/news/heart-disease/61749-brain-fog-after-heart-failure-improves-walking-brain-games> [Accessed July 2021].

[52] Uofmhealth.org. 2021. Heart Failure: Compensation by the Heart and Body | Michigan Medicine. [online] Available at: <https://www.uofmhealth.org/health-library/aa86963#:~:text=So%20your%20body%20doesn't,body%2C%20and%20increasing%20heart%20rate.> [Accessed July  2021].

[53] Willett, W., Koplan, J., Nugent, R., Dusenbury, C., Puska, P.and Gaziano, T., 2021. Prevention of Chronic Disease by Means of Diet and Lifestyle Changes. [online] Ncbi.nlm.nih.gov.Available at: <https://www.ncbi.nlm.nih.gov/books/NBK11795/> [Accessed July 2021].

[54] Hare DL., et al.Depression and Cardiovascular Disease: A Clinical Review.European Heart Journal. 2014; 35(21): 1365 - 1372.

[55] National Kidney Foundation. 2021. Obesity and Kidney Disease. [online] Available at: <https://www.kidney.org/blog/staying-healthy/obesity-and-kidney-disease> [Accessed July 2021].

[56] Information, H., Disease, K., Disorder, M., Disease, M.and Health, N., 2021. Mineral & Bone Disorder in Chronic Kidney Disease | NIDDK. [online] National Institute of Diabetes and Digestive and Kidney Diseases.Available at: <https://www.niddk.nih.gov/health-information/kidney-disease/mineral-bone-disorder> [Accessed July 2021].

[57] Information, H., Disease, K., Adults, N., Adults, N.and Health, N., 2021. Nephrotic Syndrome in Adults | NIDDK. [online] National Institute of Diabetes and Digestive and Kidney Diseases.Available at: <https://www.niddk.nih.gov/health-information/kidney-disease/nephrotic-syndrome-adults> [Accessed July  2021].

[58] NHS.uk. 2021. Chronic kidney disease - Treatment. [online] Available at: <https://www.nhs.uk/conditions/kidney-disease/treatment/> [Accessed 28 June 2021].

[59] Kazancioğlu, R. (2013).Risk factors for chronic kidney disease: an update.Kidney International Supplements, 3(4), 368 - 371. <https://doi.org/10.1038/kisup.2013.79>

[60] Information, H., Disease, K., (CKD), C., CKD, C., Disease, C.and Health, N., 2021. Causes of Chronic Kidney Disease | NIDDK. [online] National Institute of Diabetes and Digestive and Kidney Diseases.Available at: <https://www.niddk.nih.gov/health-information/kidney-disease/chronic-kidney-disease-ckd/causes> [Accessed July 2021].

[61] Shlipak, M., 2021. The case for early identification and intervention of chronic kidney disease: conclusions from a Kidney Disease: Improving Global Outcomes(KDIGO) Controversies Conference. [online] Available at: <https://www.kidney-international.org/article/S0085-2538(20)31210-2/fulltext> [Accessed July 2021].

[62] Diabetes. 2021. Insulin resistance occurs when insulin levels are sufficiently high over a prolonged period of time causing the body ? s own sensitivity to the hormone to be reduced.. [online] Available at: <https://www.diabetes.co.uk/insulin-resistance.html> [Accessed July 2021].

[63] Centers for Disease Control and Prevention. 2021. Type 2 Diabetes. [online] Available at: <https://www.cdc.gov/diabetes/basics/type2.html> [Accessed July 2021].

[64] NHS.uk. 2021. Type 2 diabetes - Symptoms. [online] Available at: <https://www.nhs.uk/conditions/type-2-diabetes/symptoms/> [Accessed 29 June 2021].

[65] Diabetes. 2021. Kidney disease generally occurs when the nephrons(tiny blood capillaries inside the kidneys) become damaged, causing them to lose their filtering capacity.. [online] Available at: <https://www.diabetes.co.uk/body/kidneys.html> [Accessed July 2021].

[66] Diabetes. 2021. Polydipsia is the term given to excessive thirst and is one of the initial symptoms of diabetes.. [online] Available at: <https://www.diabetes.co.uk/symptoms/polydipsia.html> [Accessed July 2021].

[67] Diabetes. 2021. Unexplained weight loss is the term used to describe a decrease in body weight that occurs unintentionally and can be a warning sign of diabetes.. [online] Available at: <https://www.diabetes.co.uk/symptoms/unexplained-weight-loss.html> [Accessed July 2021].

[68] Diabetes. 2021. Wounds or sores that take more than a few weeks to heal might be infected and require medical treatment, and often indicate an underlying disease such as diabetes.. [online] Available at: <https://www.diabetes.co.uk/symptoms/slow-healing-of-wounds.html> [Accessed July 2021].

[69] Diabetes. 2021. Diabetic Neuropathy. [online] Available at: https://www.diabetes.co.uk/diabetes-complications/diabetes-neuropathy.html [Accessed July 2021].

[70] nhs.uk. 2021. Low blood sugar(hypoglycaemia). [online] Available at: <https://www.nhs.uk/conditions/low-blood-sugar-hypoglycaemia/> [Accessed July 2021].

[71] Information, H., Overview, D., Problems, P., Diabetes, &., Diabetes, &.and Health, N., 2021. Diabetes, Gum Disease, & Other Dental Problems | NIDDK. [online] National Institute of Diabetes and Digestive and Kidney Diseases.Available at: <https://www.niddk.nih.gov/health-information/diabetes/overview/preventing-problems/gum-disease-dental-problems> [Accessed July 2021].

[72] Information, H., Overview, D., Diabetes, P., Diabetes, P.and Health, N., 2021. Preventing Type 2 Diabetes | NIDDK. [online] National Institute of Diabetes and Digestive and Kidney Diseases.Available at: <https://www.niddk.nih.gov/health-information/diabetes/overview/preventing-type-2-diabetes> [Accessed July 2021].

[73] UK, D., diabetes, T., diagnosed ?, N.and possible, Y., 2021. Type 2 diabetes. [online] Diabetes UK.Available at: <https://www.diabetes.org.uk/type-2-diabetes> [Accessed July 2021].

[74] Report.nih.gov. 2021.[online] Available at: <https://report.nih.gov/biennialreport0607/pdf/NIH_BR_Ch2_chronic.pdf> [Accessed July 2021].

[75] UK, D., attacks, P.and possible, Y., 2021. People with diabetes almost 50 % more likely to have heart attacks. [online] Diabetes UK.Available at: <https://www.diabetes.org.uk/about_us/news_landing_page/people-with-diabetes-almost-50-more-likely-to-have-heart-attacks> [Accessed July 2021].

[76] Nhlbi.nih.gov. 2021. Heart Failure | NHLBI, NIH. [online] Available at: <https://www.nhlbi.nih.gov/health-topics/heart-failure> [Accessed 1 July 2021].

[77] www.heart.org. 2021. Atherosclerosis. [online] Available at: <https://www.heart.org/en/health-topics/cholesterol/about-cholesterol/atherosclerosis> [Accessed July 2021].

[78] UK, D., diabetes, F.and possible, Y., 2021. Fats and diabetes. [online] Diabetes UK.Available at: <https://www.diabetes.org.uk/guide-to-diabetes/enjoy-food/eating-with-diabetes/food-groups/fats-and-diabetes> [Accessed July 2021].

[79] Diabetes. 2021. Blood vessels are vital for the body and play a key role in diabetes helping to transport glucose and insulin.. [online] Available at: <https://www.diabetes.co.uk/body/blood-vessels.html> [Accessed July 2021].

[80] www.heart.org. 2021. Types of Heart Failure. [online] Available at: <https://www.heart.org/en/health-topics/heart-failure/what-is-heart-failure/types-of-heart-failure> [Accessed July 2021].

[81] Information, H., Overview, D., Problems, P., Diabetes, &., Diabetes, &.and Health, N., 2021. Diabetes, Heart Disease and Stroke | NIDDK. [online] National Institute of Diabetes and Digestive and Kidney Diseases.Available at: <https://www.niddk.nih.gov/health-information/diabetes/overview/preventing-problems/heart-disease-stroke> [Accessed July 2021].`
}