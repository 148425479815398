export enum Text {
    K1 = `Welcome to the kidneys, Newbie! So... what do you think?`,
    K2 = `What do the kidneys do?`,
    K3 = `The role of the kidney department is to remove waste products from the blood, and to maintain the amount of water in the body – but they can tell you more about it than I can.`,

    K5a1 = `Our main concern here is chronic kidney disease – it can lead to serious problems for the body.`,
    K5a2a = `What's chronic kidney disease?`,
    K5a2b = `What causes chronic kidney disease?`,
    K5a3a = `Chronic kidney disease happens when the kidneys stop working properly because the tiny blood vessels which filter the blood become damaged, often from high blood pressure or diabetes.`,
    K5a3b = `Some people inherit a higher risk of developing chronic kidney disease from their genes, but obesity and type 2 diabetes can make you more at risk, too.`,

    K5b1 = `Hey Newbie! I'm a symptom manager, keeping an eye out for four different problems: 1, blood toxins; 2, bone diseases; 3, kidney filtering damage; and 4, electrolytes imbalances.`,
    K5b2a = `Tell me more about toxins in the blood.`,
    K5b2b = `What symptoms do these bone diseases cause?`,
    K5b2c = `What signs should I be aware of that the kidney filtering system is damaged?`,
    K5b2d = `How would I know if my electrolyte levels are unbalanced?`,
    K5b3a = `If the kidneys are not filtering out toxins in the blood, you'd see symptoms like tiredness, difficulty concentrating, trouble sleeping and loss of appetite.`,
    K5b3b = `You'd see symptoms like itchy or dry skin. See, the kidneys help balance minerals in the blood to keep bones strong. If they can’t keep the right levels of minerals, it can lead to bone diseases which cause itching and dry skin.`,
    K5b3c = `When the kidney filtering system is damaged, it can lead to an increased urge to urinate, and sometimes red blood cells can leak out of the damaged filters into the urine.`,
    K5b3d = `Damaged kidneys can't regulate electrolytes in the body properly. With too much sodium, you'll see swelling in the limbs, and low calcium levels can cause cramps.`,

    K5c1 = `Hi Newbie, you want to see how disasters are prevented?`,
    K5c2a = `Yes, how do you cure chronic kidney disease?`,
    K5c2b = `What should I do if I get chronic kidney disease?`,
    K5c3a = `Sadly, there's no cure for chronic kidney disease. But factors like high blood pressure, smoking and obesity put people at risk – so avoiding these is a good start. You can also go to [area=url:https://www.organs-talk.com/][color=#00FFFF][u]the OrgansTalk website[/u][/color][/area] to learn more.`,
    K5c3b = `Getting diagnosed early is important, as treatments for the underlying cause of the disease can stop it from getting worse.`,

    K6a1 = `Hmm... I wonder where those tunnels lead?`,

    K7a1 = `So, seen all you need to see here?`,
    K7a2a = `Let's go back to the elevator.`,
    K7a2b = `I'm not finished talking to these guys yet!`,
}

export enum Eng1ChoiceState{
    K5a2a,
    K5a2b,
}
export enum Eng2ChoiceState{
    K5b2a,
    K5b2b,
    K5b2c,
    K5b2d,
}

export enum Eng3ChoiceState{
    K5c2a,
    K5c2b,
}
export enum FinalChoiceState {
    K7a2a,
    K7a2b
}