import BaseScene from "../scenes/BaseScene"
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from "../globals";
import { Depth } from "../models/Depth";

export default class ProgressBar {
  private scene: BaseScene;
  private image!: Phaser.GameObjects.Image;

  constructor(scene: BaseScene) {
    this.scene = scene;
  }

  setProgress(progress: integer) {
    this.image?.destroy();
    this.image = this.scene.add
      .image(DEFAULT_WIDTH * 0.97, DEFAULT_HEIGHT / 2, "progress_bar_" + progress)
      .setDepth(Depth.UI);
  }

}