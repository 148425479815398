import { Scene } from "phaser";
import Actor, { AnimationKey } from "./Actor";
import { EngineerType } from "../models/EngineerType";
import { DialogSymbol } from "../models/DialogSymbol";

export default class Engineer extends Actor {
  constructor(scene: Scene, x: number, y: number, type: EngineerType, n: number) {
    const isPancreasKidneys2 = (type == EngineerType.PancreasKidneys && n == 2);
    const canBeHappy = (type == EngineerType.Heart && n == 2
      || type == EngineerType.HeartPancreas
      || type == EngineerType.KidneysHeart
      || type == EngineerType.PancreasKidneys)
      
    super(scene, x, y, "engineer_" + type + "_" + n.toString(), {
      [AnimationKey.Idle]: isPancreasKidneys2 ? 51 : 100,
      [AnimationKey.Talking]: 100,
      [AnimationKey.Happy]: canBeHappy ? 100 : 0
    });
  }
}