import Foreman from "../../characters/Foreman";
import Newbie from "../../characters/Newbie";
import { DEFAULT_HEIGHT, DEFAULT_WIDTH, RESIZE_FACTOR } from "../../globals";
import { Depth } from "../../models/Depth";
import { Floor } from "../../models/Floor";
import { GameScene } from "../../models/GameScene";
import ElevatorState from "../../states/ElevatorState";
import BaseScene from "../BaseScene";

export default class ElevatorScene extends BaseScene {
  private foreman!: Foreman;
  private newbie!: Newbie;
  private backgroundVideo!: Phaser.GameObjects.Video;
  private backgroundImage!: Phaser.GameObjects.Image;
  private elevatorPanel!: Phaser.GameObjects.Image;
  private buttons: Phaser.GameObjects.Image[] = [];
  private intereactiveRectangles: Phaser.GameObjects.Rectangle[] = [];
  private floors: Floor[] = [Floor.BodyHub, Floor.Heart, Floor.Kidneys, Floor.Pancreas];
  private currentFloor: Floor = Floor.BodyHub;
  private ambientSound!: Phaser.Sound.BaseSound;
  private elevatorSound!: Phaser.Sound.BaseSound;
  private targetFloor!: Floor;
  private isPanelOpen = false;
  private elevatorStart!: Phaser.Sound.BaseSound;

  constructor() {
    super(GameScene.Elevator);
  }

  preload(): void {
    this.load.maxParallelDownloads = 1;
    this.loadVideo("elevator_down", "background/elevator_down.mp4");
    this.loadVideo("elevator_up", "background/elevator_up.mp4");
  }

  private shouldShowTutorial(): boolean {
    return !this.store.sceneIsVisited(GameScene.Heart)
      && !this.store.sceneIsVisited(GameScene.Kidneys)
      && !this.store.sceneIsVisited(GameScene.Pancreas)
  }

  initConversations(): void {
    this.stateMachine = new ElevatorState(this.newbie, this.foreman);
    this.setupStateMachine();

    this.foreman.on("pointerup", () => {
      this.skipButton.setVisible(true);
      this.stateMachine.nextState()
    });

    this.foreman.stateMachine = this.stateMachine;
    this.newbie.stateMachine = this.stateMachine;
    const lastScene = this.store.getLastScene();

    switch (lastScene) {
      case GameScene.Pancreas:
        this.stateMachine.setState('SPEAK_FROM_PANCREAS');
        break;
      case GameScene.Heart:
        this.stateMachine.setState('SPEAK_FROM_HEART');
        break;
      case GameScene.Kidneys:
        this.stateMachine.setState('SPEAK_FROM_KIDNEYS');
        break;
      case GameScene.CentralHub:
        setTimeout(() => {
          this.stateMachine.setState('SPEAK_ELEVATOR_FIRST');
        }, 1000);
        break;
    }
    this.foreman.getSpeechBubble()?.on("pointerup", () => this.stateMachine.nextState());
    this.newbie.getSpeechBubble()?.on("pointerup", () => this.stateMachine.nextState());

    this.stateMachine.onConversationStartHandler = () => this.setMenuOpen(false);
    this.stateMachine.onConversationEndHandler = () => this.skipButton.setVisible(false);
    this.stateMachine.onFinishHandler = () => this.stopElevator();

    setTimeout(() => {
      if (!this.isPanelOpen) {
        this.stateMachine.setState('SPEAK_UNSURE');
      }
    }, 10000);
  }

  create(data: { currentFloor: Floor }): void {
    this.fadeIn();

    this.currentFloor = data.currentFloor

    this.backgroundVideo = this.add.video(0, 0, "elevator_down")
      .play(true)
      .setLoop(true)
      .setScale(RESIZE_FACTOR)
      .setOrigin(0, 0)
      .setDepth(Depth.Background - 1);

    this.backgroundImage = this.add.image(0, 0, "elevator")
      .setOrigin(0, 0)
      .setDepth(Depth.Background);

    this.ambientSound = this.sound.add("elevator", { loop: true });
    this.ambientSound.play();
    this.elevatorSound = this.sound.add("elevator_move", { loop: true });

    const floorY = DEFAULT_HEIGHT * 0.83;

    this.foreman = new Foreman(this, DEFAULT_WIDTH * 0.46, floorY);
    this.foreman.setScale(0.8);

    this.newbie = new Newbie(this, DEFAULT_WIDTH * 0.54, floorY);
    this.newbie.setScale(0.8);

    this.configureUI();

    this.initConversations();
    this.actors = [this.foreman, this.newbie];

    this.add
      .rectangle(DEFAULT_WIDTH * 0.56, DEFAULT_HEIGHT * 0.43, DEFAULT_WIDTH * 0.04, DEFAULT_HEIGHT * 0.16)
      .setOrigin(0, 0)
      .setInteractive()
      .on("pointerup", () => this.onPanelClick());

    this.elevatorPanel = this.add.image(DEFAULT_WIDTH * 0.5, DEFAULT_HEIGHT * 0.5, "elevator_panel")
      .setDepth(Depth.Panel)
      .setVisible(false);

    let buttonHeight = 0;
    for (let floor of this.floors) {
      if (floor == Floor.UnknownBottom) {
        continue;
      }
      
      const buttonName = this.getButtonName(floor);
      const button = this.add
        .image(
          DEFAULT_WIDTH * 0.32,
          DEFAULT_HEIGHT * 0.124 + (buttonHeight + DEFAULT_HEIGHT * 0.029) * floor,
          buttonName
        )
        .setOrigin(0, 0)
        .setVisible(false)
        .setDepth(Depth.Panel + 1);
      this.buttons.push(button);

      const rectangle = this.add
        .rectangle(
          button.x,
          button.y,
          DEFAULT_WIDTH * 0.364,
          button.displayHeight
        )
        .setOrigin(0, 0)
        .setVisible(false)
        .setDepth(Depth.Panel + 2)
        .setInteractive()
        .on("pointerup", () => this.onFloorButtonClick(floor));
      this.intereactiveRectangles.push(rectangle);

      buttonHeight = this.buttons[0].displayHeight;
    }

    if (this.shouldShowTutorial()) {
      this.tutorial.showExamineElevatorPanelTutorial();
    }

    this.events.on("shutdown", () => this.onShutDown());
  }

  onShutDown(): void {
    this.ambientSound.stop();
    this.elevatorSound.stop();
  }

  getButtonName(floor: Floor): string {
    var name: string;
    switch (floor) {
      case Floor.BodyHub:
        name = "body_button";
        break;
      case Floor.Heart:
        name = "heart_button";
        break;
      case Floor.Kidneys:
        name = "kidneys_button";
        break;
      case Floor.Pancreas:
        name = "pancreas_button";
        break;
      default:
        return "";
    }

    const scene = this.getSceneFromFloor(floor);
    if (this.currentFloor === floor) {
      name += "_disabled";
    } else if (this.store.sceneIsVisited(scene)) {
      name += "_visited";
    } else {
      name += "_default";
    }

    return name;
  }

  private getSceneFromFloor(floor: Floor): GameScene {
    switch (floor) {
      case Floor.Heart:
        return GameScene.Heart;
      case Floor.Kidneys:
        return GameScene.Kidneys;
      case Floor.Pancreas:
        return GameScene.Pancreas;
      default:
        return GameScene.CentralHub;
    }
  }

  onPanelClick(): void {
    this.isPanelOpen = true;

    if (this.shouldShowTutorial()) {
      this.tutorial.showVisitOrganTutorial();
    }

    this.sound.play("button");
    this.foreman.removeSpeechBubble();
    this.elevatorPanel.setVisible(true).setDepth(Depth.Panel);
    this.blockingPanelButton = this.add
      .rectangle(0, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT)
      .setOrigin(0, 0)
      .setInteractive()
      .setDepth(Depth.Panel - 1)
      .on("pointerup", () => {
        if (this.shouldShowTutorial()) {
          return;
        }

        this.isPanelOpen = false;
        this.elevatorPanel.setVisible(false);
        this.buttons.forEach(button => {
          button.setVisible(false);
        });
        this.intereactiveRectangles.forEach(rectangle => {
          rectangle.setVisible(false);
        });

        setTimeout(() => {
          this.stateMachine.setState('SPEAK_UNSURE');
        }, 1000);

        this.blockingPanelButton?.destroy();
        this.tutorial.hide();
      });

    this.buttons.forEach(button => {
      button.setVisible(true);
    });
    this.intereactiveRectangles.forEach(rectangle => {
      rectangle.setVisible(true);
    });
  }

  onFloorButtonClick(floor: Floor): void {
    this.blockingPanelButton?.destroy();
    this.tutorial.hide();

    let elevatorPling = this.sound.add("elevator_pling");
    elevatorPling.play();
    this.moveElevator(floor);

    this.elevatorPanel.setVisible(false);
    this.buttons.forEach(button => {
      button.setVisible(false);
    });
    this.intereactiveRectangles.forEach(rectangle => {
      rectangle.setVisible(false);
    });
  }

  moveElevator(floor: Floor): void {
    this.stateMachine.recreate();
    
    this.elevatorStart = this.sound.add("elevator_start");
    this.elevatorStart.on("complete", () => this.elevatorSound.play());
    this.elevatorStart.play();

    this.targetFloor = floor;
    if (this.currentFloor == floor) {
      this.goToFloor(floor);
    } else {
      if (this.currentFloor > floor) {
        this.backgroundVideo.changeSource("elevator_up");
      } else {
        this.backgroundVideo.changeSource("elevator_down");
      }
      this.backgroundImage.setVisible(false);
      if (floor !== Floor.BodyHub) {
        this.skipButton.setVisible(true);
        this.triggerDialog(floor);
      } else {
        setTimeout(() => this.goToFloor(floor), 3000);
      }
    }
  }

  triggerDialog(floor: Floor): void {
    let state = "SPEAK";
    switch (floor) {
      case Floor.Heart:
        state = 'SPEAK_HEART';
        break;
      case Floor.Pancreas:
        state = 'SPEAK_PANCREAS';
        break;
      case Floor.Kidneys:
        state = 'SPEAK_KIDNEYS';
        break;
      default:
        break;
    }

    this.stateMachine.setState(state);
  }

  stopElevator(): void {
    this.elevatorSound.stop();
    this.elevatorStart.stop();
    this.backgroundImage.setVisible(true);

    const elevatorStop = this.sound.add("elevator_stop");
    elevatorStop.play();
    this.goToFloor(this.targetFloor)
  }

  goToFloor(floor: Floor): void {
    switch (floor) {
      case Floor.BodyHub:
        this.startScene(GameScene.CentralHub);
        break;
      case Floor.Heart:
        this.startScene(GameScene.Heart);
        break;
      case Floor.Pancreas:
        this.startScene(GameScene.Pancreas);
        break;
      case Floor.Kidneys:
        this.startScene(GameScene.Kidneys);
        break;
      default:
        break;
    }
  }

}
