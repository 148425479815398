import BaseScene from "./BaseScene";
import { GameScene } from "../models/GameScene";
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from "../globals";

export default class EndScene extends BaseScene {
    private text!: Phaser.GameObjects.Text;

    constructor() {
        super(GameScene.End);
    }

    create(): void {
        this.fadeIn();

        this.createReferencesButton();
        
        this.add.rectangle(0, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, 0x000000);
        this.text = this.add.text(DEFAULT_WIDTH / 2, DEFAULT_HEIGHT / 2, 'THE END', {
            color: '#ffffff',
            fontSize: '100px'
        }).setOrigin(0.5, 0.5);

        setTimeout(() => {
            this.tweens.add({
                targets: this.text,
                duration: 500,
                alpha: 0
            });
            setTimeout(() => {
                this.showReferences(() => this.onReferencesClose());
            }, 500);
        }, 3000);
    }

    private onReferencesClose(): void {
        this.startScene(GameScene.Title);
        this.store.reset();
    }
}
