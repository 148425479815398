import { assign, createMachine, interpret } from 'xstate';
import {
    ForemanChoiceState,
    HKEngaChoiceState,
    HKEngbChoiceState,
    FinalChoiceState,
    Text
} from "../texts/heart-kidneys"
import Actor from "../characters/Actor";
import Newbie from "../characters/Newbie";
import Foreman from "../characters/Foreman";
import Engineer from "../characters/Engineer";
import { StateContext } from './StateContext';
import { StateEvent } from './StateEvent';
import BaseState from "./BaseState";


export default class KidneysHeartState extends BaseState {
    newbie: Newbie;
    foreman: Foreman;
    eng1: Engineer;

    constructor(newbie: Newbie, foreman: Foreman, eng1: Engineer) {
        super();
        this.newbie = newbie;
        this.foreman = foreman;
        this.eng1 = eng1;

        this.stateMachine = createMachine<StateContext, StateEvent>({
            id: "speech",
            initial: "HK0", //get from state later
            context: {
                actor: undefined
            },
            states: {
                HK0: {
                    on: {
                        SPEAK: {
                            target: "HK1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                        SPEAK_ENG1: {
                            target: "HK6a1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                })
                            ]
                        },
                        SPEAK_FINISH: {
                            target: "HK7b1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                        SPEAK_HAPPY: {
                            target: "HKCOMM",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                })
                            ]
                        }
                    }
                },
                HKCOMM: {
                    on: {
                        SPEAK: {
                            target: "HKCOMM0",
                            actions: [
                                (context) => {
                                    this.eng1.removeSpeechBubble()
                                    this.eng1.playHappyAnimation();

                                    this.onConversationEndHandler?.();
                                },

                            ],
                        }
                    }
                },
                HKCOMM0: {
                    on: {
                        SPEAK: {
                            target: "HKCOMM",
                            actions: [
                                (context) => {
                                    this.eng1.removeSpeechBubble()
                                    this.eng1.playHappyAnimation();

                                },
                            ],
                        }
                    }
                },
                HK1: {
                    on: {
                        SPEAK: {
                            target: "HK2",
                            actions: [assign({
                                actor: (context, event) => this.newbie
                            }), (context, event, actionMeta) => {
                                this.foreman.removeSpeechBubble();
                                this.sayFromChoices(ForemanChoiceState, Text, 'HK2', actionMeta, this.newbie);
                            }]
                        }
                    }
                },
                HK2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "HK3a",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }

                        },
                        {
                            target: "HK3b",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }

                        }]
                    }
                }, HK3a: {
                    on: {
                        SPEAK: [{
                            target: "HK4b",
                            actions: [
                                (context) => this.foreman.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                            ],
                        }]
                    }

                },
                HK3b: {
                    on: {
                        SPEAK: [{
                            target: "HK4b",
                            actions: [
                                (context) => this.foreman.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                            ],
                        }]
                    }

                },
                HK4b: {
                    on: {
                        SPEAK: {
                            target: "HK5b",
                            actions: [
                                (context) => this.newbie.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        }
                    }
                },
                HK5b: {
                    on: {
                        SPEAK: {
                            target: "HK0",
                            actions: [
                                (context) => {
                                    this.foreman.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                },
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        }
                    }
                },

                HK6a1: {
                    on: {
                        SPEAK: [{
                            target: "HK6a2",
                            actions: [assign({
                                actor: (context, event) => this.newbie
                            }), (context, event, actionMeta) => {
                                this.newbie.removeSpeechBubble();
                                this.eng1.removeSpeechBubble();
                                this.sayFromChoices(HKEngaChoiceState, Text, 'HK6a2', actionMeta, this.newbie, this.eng1);
                            }]
                        }]
                    }
                },
                HK6a2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "HK6a3a",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }

                        },
                        {
                            target: "HK6a3b1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();


                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }

                        }]
                    }
                },

                HK6a3a: {
                    on: {
                        SPEAK: {
                            target: "HK6a3b2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event, actionMeta) => {
                                    this.newbie.removeSpeechBubble();
                                    this.eng1.removeSpeechBubble();
                                    this.sayFromChoices(HKEngbChoiceState, Text, 'HK6a3b2', actionMeta, this.newbie, this.eng1);
                                }],
                        }
                    }
                }, HK6a3b1: {
                    on: {
                        SPEAK: {
                            target: "HK6a3b2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event, actionMeta) => {
                                    this.newbie.removeSpeechBubble();
                                    this.eng1.removeSpeechBubble();
                                    this.sayFromChoices(HKEngbChoiceState, Text, 'HK6a3b2', actionMeta, this.newbie, this.eng1);
                                }],
                        }
                    }
                },
                HK6a3b2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "HK6a3b3a",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }

                        },
                        {
                            target: "HK6a3b3b",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();


                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }

                        }]
                    }
                },

                HK6a3b3a: {
                    on: {
                        SPEAK: {
                            target: "HK0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event) => {
                                    this.eng1.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                }],
                        }
                    }
                }, HK6a3b3b: {
                    on: {
                        SPEAK: {
                            target: "HK0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event) => {
                                    this.eng1.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                }],
                        }
                    }
                },
                HK7b1: {
                    on: {
                        SPEAK: {
                            target: "HK7b2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event, actionMeta) => {
                                    this.foreman.removeSpeechBubble();
                                    this.sayFromChoices(FinalChoiceState, Text, 'HK7b2', actionMeta, this.newbie);
                                }
                            ]
                        }
                    }
                },
                HK7b2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "HK0",
                            actions: [
                                (context, event) => {
                                    this.onFinishHandler?.();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }

                        },
                        {
                            target: "HK0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }

                        }]
                    }
                },
            },
        });

        this.set();
    }

    set(): void {
        this.service = interpret(this.stateMachine).onTransition(
            (state, event) => {
                const actor = state.context.actor as Actor;
                const value = state.value as string;

                if (actor && Object.keys(Text).includes(value)) {
                    const text: string = (<any>Text)[value];
                    actor.say([text]);
                }
            }
        ).start();
    }
}