export enum Text {
    H1 = `Well, here it is, Newbie – isn't it a beauty? This complex network of heart and blood vessels keeps this whole operation running.`,
    H2a = `How does it work?`,
    H2b = `What does it do?`,
    H3a = `The heart muscle pumps blood by contracting each side of the heart, and that builds pressure and squeezes blood out through valves. Those valves stop blood flowing backwards in the heart and some vessels.`,
    H3b = `It transports blood around the body to deliver nutrients to, and remove waste products from, the muscles and organs. It doesn't just carry blood through the lungs to take in oxygen, but it also carries blood through the kidneys to remove waste and excess water.`,
    H4 = `These guys can fill you in with more detail – talk to them to find out about what they do!`,

    H5a1 = `The heart and cardiovascular system are essential, so we make sure there are no serious problems, like cardiovascular disease.`,
    H5a2a = `What's cardiovascular disease?`,
    H5a2b = `What are the symptoms of cardiovascular disease?`,
    H5a2c = `What causes cardiovascular disease?`,
    H5a3a = `Cardiovascular disease is a general term for all conditions that affect the heart or blood vessels, like coronary heart disease, heart attack and angina.`,
    H5a3b = `We look out for signs of heart attack. Check out the screen there for the full list...`,
    H5a3c1 = `You can be at higher risk of cardiovascular disease if a close family member has it, but there's lots of evidence showing that it's is mostly preventable.`,
    H5a3c2 = `What can you do to prevent cardiovascular disease?`,
    H5a3c3 = `Cardiovascular disease can be prevented by reducing risk factors like smoking, unhealthy diet, obesity, lack of physical activity and harmful use of alcohol.`,

    H5b1 = `Yes? What is it? I'm busy, Newbie. Heart failure's no joke! And it could happen any time... Make it fast, okay?`,
    H5b2a = `Heart failure? Sounds serious! What's heart failure?`,
    H5b2b = `Is heart failure similar to cardiovascular disease?`,
    H5b2c1 = `Are there specific symptoms of heart failure I should look out for?`,
    H5b3a = `Heart failure happens when the heart can't pump blood as effectively around the body. There are two types: heart failure with reduced ejection fraction (HFrEF) and heart failure with preserved ejection fraction (HFpEF).`,
    H5b3b = `Well, kind of. Heart failure is one of the heart diseases which are part of cardiovascular disease, but it's often the later stage of many of these diseases.`,
    H5b3c2 = `Plenty! Look, I don't have time... Check out the screen! (points)`,
    H5b4a1 = `What's heart failure with reduced ejection fraction (HFrEF)?`,
    H5b4b1 = `Can you tell me more about heart failure with preserved ejection fraction (HFpEF)?`,
    H5b4a2 = `HFrEF is when the heart can’t pump as much blood out of it and around the body.`,
    H5b4b2 = `HFpEF is when the heart is stiff so cannot fill with as much blood, so there is less available to pump out.`,
    H5c1 = `Oh hey, namaste!`,
    H5c2 = `So you take care of heart health?`,
    H5c3 = `Yeah, in a couple of different ways – we have some initiatives that prevent problems, and if we get into trouble, we've got some processes aimed at helping to manage heart disease.`,
    H5c4a = `Tell me more about prevention.`,
    H5c4b = `What can you do to help manage heart disease?`,
    H5c5a = `It's mostly about healthy living – that means restricting salt and sugar in the diet and regular exercise. You can also visit [area=url:https://www.organs-talk.com/][color=#00FFFF][u]the OrgansTalk website[/u][/color][/area] to find out more.`,
    H5c5b = `Well you can't expect to carry on like before; heart failure has an effect on physical and mental well-being, as well as on social and daily life activities. Adjustments may need to be made to adapt to living life with heart failure, like monitoring symptoms, keeping on track with treatment or medications, and seeking medical assistance when necessary.`,

    H6a1 = `Hmm... I wonder where those tunnels lead?`,

    H7a1 = `So, seen all you need to see here?`,
    H7a2a = `Let's go back to the elevator.`,
    H7a2b = `I'm not finished talking to these guys yet!`,
}
export enum IntroChoiceStates{
    H2a,
    H2b,
}
export enum Eng1ChoiceState{
    H5a2a,
    H5a2b,
    H5a2c,
}
export enum Eng2aChoiceState{
    H5b2a,
    H5b2b,
    H5b2c1,
}
export enum Eng2bChoiceState{
    H5b4a1,
    H5b4b1,
}
export enum Eng3ChoiceState{
    H5c4a,
    H5c4b,
}

export enum FinalChoiceState {
    H7a2a,
    H7a2b
}