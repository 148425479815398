import { DefaultState } from "./DefaultState";
import { ActionMeta, StateMachine } from 'xstate';
import { StateContext } from "./StateContext";
import { StateEvent } from "./StateEvent";
import Newbie from "../characters/Newbie";
import Actor from "../characters/Actor";

export default class BaseState implements DefaultState {
    stateMachine!: StateMachine<StateContext, any, StateEvent, any>;
    service: any;

    public onFinishHandler?: () => void;
    public onConversationStartHandler?: () => void;
    public onConversationEndHandler?: () => void;
    public hideSkipButton?: () => void;
    public onSkip?: () => void;
    public chosenStates: { [key: string]: Array<integer> } = {};
    public onSaveChosenStates?: (states: { [key: string]: Array<integer> }) => void;

    nextState(): void {
        this.setState("SPEAK");
    }

    setState(state: string): void {
        this.onConversationStartHandler?.();
        this.service.send({ type: state });
    }

    recreate(): void {
        this.service.stop();
        this.set();
        this.onSkip?.();
    }

    set(): void {

    }

    saveChoice(actionMeta: ActionMeta<StateContext, StateEvent>, newbie: Newbie): void {
        const lastState = actionMeta.state.history?.value as string;
        const lastChoice = newbie.lastChoice as integer;
        
        if (typeof this.chosenStates[lastState] === "undefined") {
            this.chosenStates[lastState] = [];
        }
        if (!this.chosenStates[lastState].includes(lastChoice)) {
            this.chosenStates[lastState].push(lastChoice);
        }

        this.onSaveChosenStates?.(this.chosenStates);
    }

    protected sayFromChoices<Choices, Text>(choiceStates: Choices, texts: Text, mainState: string, actionMeta: ActionMeta<StateContext, never>, speaker: Actor, listener?: Actor): void {
        const choices = Object.values(choiceStates)
            .filter(state => typeof state === 'string')
            .filter(state => state.toString().includes(mainState));
            
        if (choices.length > 0) {
            const quotes = choices.map(choice => (<any>texts)[choice]);
            const greyedOut = this.chosenStates[actionMeta.state.value as string];
            const recipientActor = (mainState == 'H5b4') ? undefined : listener;
            const showCloseButton = quotes.length > 1 && listener != undefined;
            speaker.say(quotes, greyedOut, recipientActor, showCloseButton);
        } else if (Object.keys(texts).includes(mainState)) {
            const text: string = (<any>texts)[mainState];
            speaker.say([text]);
        }
    }
}