import { assign, createMachine, interpret } from 'xstate';
import {
    Eng1aChoiceState,
    Eng2aChoiceState,
    Eng2bChoiceState,
    Eng1bChoiceState,
    FinalChoiceState,
    Text
} from "../texts/kidney-pancreas"
import Actor from "../characters/Actor";
import Newbie from "../characters/Newbie";
import Foreman from "../characters/Foreman";
import Engineer from "../characters/Engineer";
import { StateContext } from './StateContext';
import { StateEvent } from './StateEvent';
import BaseState from "./BaseState";


export default class PancreasKidneysState extends BaseState {
    newbie: Newbie;
    foreman: Foreman;
    eng1: Engineer;
    eng2: Engineer;

    constructor(newbie: Newbie, foreman: Foreman, eng1: Engineer, eng2: Engineer) {
        super();
        this.newbie = newbie;
        this.foreman = foreman;
        this.eng1 = eng1;
        this.eng2 = eng2;

        this.stateMachine = createMachine<StateContext, StateEvent>({
            id: "speech",
            initial: "KP0", //get from state later
            context: {
                actor: undefined
            },
            states: {
                KP0: {
                    on: {
                        SPEAK: {
                            target: "KP1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                        SPEAK_ENG1: {
                            target: "KP7b1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                })
                            ]
                        },
                        SPEAK_ENG2: {
                            target: "KP7a1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng2
                                })
                            ]
                        },
                        SPEAK_FINISH: {
                            target: "KP8c1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                        SPEAK_HAPPY: {
                            target: "KPCOMM",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                })
                            ]
                        }
                    }
                },
                KPCOMM: {
                    on: {
                        SPEAK: {
                            target: "KPCOMM0",
                            actions: [
                                (context) => {
                                    this.eng1.removeSpeechBubble();
                                    this.eng1.playHappyAnimation();

                                    this.onConversationEndHandler?.();
                                },

                            ],
                        }
                    }
                },
                KPCOMM0: {
                    on: {
                        SPEAK: {
                            target: "KPCOMM",
                            actions: [
                                (context) => {
                                    this.eng1.removeSpeechBubble();
                                    this.eng1.playHappyAnimation();
                                },
                            ],
                        }
                    }
                },
                KP1: {
                    on: {
                        SPEAK: {
                            target: "KP2",
                            actions: [
                                (context) => this.foreman.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                            ],
                        }
                    }
                },
                KP2: {
                    on: {
                        SPEAK: [{
                            target: "KP3",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }), (context, event, actionMeta) => {
                                    this.eng1.removeSpeechBubble();
                                    this.sayFromChoices(Eng1aChoiceState, Text, 'KP3', actionMeta, this.newbie, this.eng1);
                                }]
                        }]
                    }

                },
                KP3: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "KP4a",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }

                        },
                        {
                            target: "KP4b1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }

                        }, {
                            target: "KP4b2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 2;
                            }

                        }]
                    }

                },
                KP4a: {
                    on: {
                        SPEAK: {
                            target: "KP5b",
                            actions: [
                                (context) => this.eng1.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.newbie
                                })
                            ]
                        }
                    }
                },
                KP4b1: {
                    on: {
                        SPEAK: {
                            target: "KP5b",
                            actions: [
                                (context) => this.eng1.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.newbie
                                })
                            ]
                        }
                    }
                },
                KP4b2: {
                    on: {
                        SPEAK: {
                            target: "KP5b",
                            actions: [
                                (context) => this.newbie.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.eng1
                                })
                            ]
                        }
                    }
                },
                KP5b: {
                    on: {
                        SPEAK: {
                            target: "KP6b",
                            actions: [
                                (context) => this.eng1.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.newbie
                                })
                            ]
                        }
                    }
                },
                KP6b: {
                    on: {
                        SPEAK: {
                            target: "KP0",
                            actions: [
                                (context) => {
                                    this.newbie.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                },
                                assign({
                                    actor: (context, event) => this.newbie
                                })
                            ]
                        }
                    }
                },

                KP7a1: {
                    on: {
                        SPEAK: {
                            target: "KP7a2",
                            actions: [
                                (context, event, actionMeta) => {
                                    this.eng2.removeSpeechBubble();
                                    this.sayFromChoices(Eng2aChoiceState, Text, 'KP7a2', actionMeta, this.newbie, this.eng2);
                                },
                                assign({
                                    actor: (context, event) => this.newbie
                                })
                            ]
                        }
                    }
                },
                KP7a2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "KP7a3a",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng2
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }

                        },
                        {
                            target: "KP7a3b",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng2
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }

                        }]
                    }
                },
                KP7a3a: {
                    on: {
                        SPEAK: [{
                            target: "KP7a4",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event, actionMeta) => {
                                    this.eng2.removeSpeechBubble();
                                    this.sayFromChoices(Eng2bChoiceState, Text, 'KP7a4', actionMeta, this.newbie, this.eng2);
                                }],


                        }]
                    }
                },
                KP7a3b: {
                    on: {
                        SPEAK: [{
                            target: "KP7a4",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event, actionMeta) => {
                                    this.eng2.removeSpeechBubble();
                                    this.sayFromChoices(Eng2bChoiceState, Text, 'KP7a4', actionMeta, this.newbie, this.eng2);
                                }],


                        }]
                    }
                },
                KP7a4: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "KP7a5a",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng2
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }

                        }, {
                            target: "KP7a5b",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng2
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }

                        }]
                    }
                },
                KP7a5b: {
                    on: {
                        SPEAK: {
                            target: "KP0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng2
                                }),
                                (context, event) => {
                                    this.eng2.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                }],
                        }
                    }
                },
                KP7a5a: {
                    on: {
                        SPEAK: {
                            target: "KP0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng2
                                }),
                                (context, event) => {
                                    this.eng2.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                }],
                        }
                    }
                },
                KP7b1: {
                    on: {
                        SPEAK: {
                            target: "KP7b2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event, actionMeta) => {
                                    this.eng1.removeSpeechBubble();
                                    this.sayFromChoices(Eng1bChoiceState, Text, 'KP7b2', actionMeta, this.newbie, this.eng1);
                                }],
                        }
                    }
                },
                KP7b2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "KP7b3a1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }

                        }, {
                            target: "KP7b3b",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }

                        }, {
                            target: "KP7b3c1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 2;
                            }

                        }]
                    }
                },
                KP7b3a1: {
                    on: {
                        SPEAK: {
                            target: "KP7b3a2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.eng1.removeSpeechBubble();
                                }],
                        }
                    }
                },
                KP7b3a2: {
                    on: {
                        SPEAK: {
                            target: "KP0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.eng1.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                }],
                        }
                    }
                },
                KP7b3b: {
                    on: {
                        SPEAK: {
                            target: "KP0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.eng1.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                }],
                        }
                    }
                },
                KP7b3c1: {
                    on: {
                        SPEAK: {
                            target: "KP7b3c2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.eng1.removeSpeechBubble();
                                }],
                        }
                    }
                },
                KP7b3c2: {
                    on: {
                        SPEAK: {
                            target: "KP0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }),
                                (context, event) => {
                                    this.eng1.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                }],
                        }
                    }
                },
                KP8c1: {
                    on: {
                        SPEAK: {
                            target: "KP8c2",
                            actions: (context, event, actionMeta) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                                this.sayFromChoices(FinalChoiceState, Text, 'KP8c2', actionMeta, this.newbie);
                            },
                        }
                    }
                },
                KP8c2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "KP0",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();

                                this.onFinishHandler?.();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }

                        }, {
                            target: "KP0",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }

                        }]
                    }
                }

            },
        });

        this.set();
    }

    set(): void {
        this.service = interpret(this.stateMachine).onTransition(
            (state, event) => {
                const actor = state.context.actor as Actor;
                const value = state.value as string;

                if (actor && Object.keys(Text).includes(value)) {
                    const text: string = (<any>Text)[value];
                    actor.say([text]);
                }
            }
        ).start();
    }
}