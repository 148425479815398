import { DEFAULT_HEIGHT, DEFAULT_WIDTH, WEBSITE_URL } from "../globals";
import { GameScene } from "../models/GameScene";
import BaseScene from "./BaseScene";

export default class TitleScene extends BaseScene {
  constructor() {
    super(GameScene.Title);
  }

  create(): void {
    this.add.image(0, 0, "title_background")
      .setOrigin(0, 0);

    this.add.image(DEFAULT_WIDTH * 0.5, DEFAULT_HEIGHT * 0.76, "start_button")
      .setOrigin(0.5, 0.5)
      .setInteractive()
      .on("pointerup", () => {
        const lastScene = this.store.getCurrentScene();
        if (lastScene != null && lastScene !== GameScene.Title && lastScene !== GameScene.End) {
          this.startScene(lastScene);
        } else {
          this.startScene(GameScene.CentralHub);
        }
      });

    this.createSoundButton(false);

    this.add.rectangle(DEFAULT_WIDTH * 0.5, DEFAULT_HEIGHT * 0.9, DEFAULT_WIDTH * 0.3, DEFAULT_HEIGHT * 0.1)
      .setInteractive()
      .on("pointerup", () => window.open(WEBSITE_URL));
  }
}
