import { assign, createMachine, interpret } from 'xstate';
import {
    Eng1ChoiceState,
    Eng2aChoiceState,
    Eng2bChoiceState,
    Eng3ChoiceState, FinalChoiceState,
    IntroChoiceStates,
    Text
} from "../texts/heart"
import Actor from "../characters/Actor";
import Newbie from "../characters/Newbie";
import Foreman from "../characters/Foreman";
import Engineer from "../characters/Engineer";
import { StateContext } from './StateContext';
import { StateEvent } from './StateEvent';
import BaseState from "./BaseState";

export default class HeartState extends BaseState {
    newbie: Newbie;
    foreman: Foreman;
    eng1: Engineer;
    eng2: Engineer;
    eng3: Engineer;

    constructor(newbie: Newbie, foreman: Foreman, eng1: Engineer, eng2: Engineer, eng3: Engineer) {
        super();
        this.newbie = newbie;
        this.foreman = foreman;
        this.eng1 = eng1;
        this.eng2 = eng2;
        this.eng3 = eng3;

        this.stateMachine = createMachine<StateContext, StateEvent>({
            id: "speech",
            initial: "H0", //get from state later
            context: {
                actor: undefined
            },
            states: {
                H0: {
                    on: {
                        SPEAK: {
                            target: "H1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                        SPEAK_ENG1: {
                            target: "H5a1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                })
                            ]
                        },
                        SPEAK_ENG2: {
                            target: "H5b1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng2
                                })
                            ]
                        },
                        SPEAK_ENG3: {
                            target: "H5c1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng3
                                })
                            ]
                        },
                        SPEAK_FOREMAN: {
                            target: "H6a1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                        SPEAK_FINISH: {
                            target: "H7a1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                    }
                },
                H1: {
                    on: {
                        SPEAK: {
                            target: "H2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                }),
                                (context, event, actionMeta) => {
                                    assign({
                                        actor: (context, event) => this.newbie
                                    });

                                    this.foreman.removeSpeechBubble();
                                    this.sayFromChoices(IntroChoiceStates, Text, 'H2', actionMeta, this.newbie);
                                }],
                        }
                    }
                },
                H2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "H3a",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }
                        },
                        {
                            target: "H3b",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }
                        }]
                    }

                },
                H3a: {
                    on: {
                        SPEAK: {
                            target: "H4",
                            actions: [
                                (context) => this.foreman.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        }
                    }
                },
                H3b: {
                    on: {
                        SPEAK: {
                            target: "H4",
                            actions: [
                                (context) => this.foreman.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        }
                    }
                },
                H4: {
                    on: {
                        SPEAK: {
                            target: "H0",
                            actions: (context, event) => {
                                assign({
                                    actor: (context, event) => this.newbie
                                });
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                H5a1: {
                    on: {
                        SPEAK: {
                            target: "H5a2",
                            actions: [
                                (context, event, actionMeta) => {
                                    this.eng1.removeSpeechBubble();
                                    this.sayFromChoices(Eng1ChoiceState, Text, 'H5a2', actionMeta, this.newbie, this.eng1);
                                },
                            ]
                        }
                    }
                },

                H5a2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "H5a3a",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }), (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }
                        },
                        {
                            target: "H5a3b",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng1
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }
                        }, {
                            target: "H5a3c1",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng1
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 2;
                            }
                        }]
                    }
                },
                H5a3a: {
                    on: {
                        SPEAK: {
                            target: "H5a3c2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }), (context, event) => {
                                    this.eng1.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();
                                }],
                        }
                    }
                },
                H5a3b: {
                    on: {
                        SPEAK: {
                            target: "H0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }), (context, event) => {
                                    this.eng1.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();
                                }],
                        }
                    }
                },
                H5a3c1: {
                    on: {
                        SPEAK: {
                            target: "H0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }), (context, event) => {
                                    this.eng1.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();
                                }],
                        }
                    }
                },
                H5a3c2: {
                    on: {
                        SPEAK: {
                            target: "H5a3c3",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }), (context, event) => {
                                    this.eng1.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();
                                }],

                        }
                    }
                },
                H5a3c3: {
                    on: {
                        SPEAK: {
                            target: "H0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                }), (context, event) => {
                                    this.eng1.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                }],

                        },

                    }
                },
                H5b1: {
                    on: {
                        SPEAK: {
                            target: "H5b2",
                            actions: (context, event, actionMeta) => {
                                assign({
                                    actor: (context, event) => this.newbie
                                });

                                this.eng2.removeSpeechBubble();
                                this.sayFromChoices(Eng2aChoiceState, Text, 'H5b2', actionMeta, this.newbie, this.eng2);
                            },
                        }
                    }
                },
                H5b2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "H5b3a",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng2
                                });

                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }
                        },
                        {
                            target: "H5b3b",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng2
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }
                        },
                        {
                            target: "H5b3c2",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng2
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 2;
                            }
                        },
                        ]
                    }
                },
                H5b3a: {
                    on: {
                        SPEAK: {
                            target: "H5b4",
                            actions: (context, event, actionMeta) => {
                                assign({
                                    actor: (context, event) => this.newbie
                                });
                                this.eng2.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                                this.sayFromChoices(Eng2bChoiceState, Text, 'H5b4', actionMeta, this.newbie, this.eng2);
                            },
                        }
                    }
                },
                H5b3b: {
                    on: {
                        SPEAK: {
                            target: "H5b4",
                            actions: (context, event, actionMeta) => {
                                assign({
                                    actor: (context, event) => this.newbie
                                });
                                this.eng2.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                                this.sayFromChoices(Eng2bChoiceState, Text, 'H5b4', actionMeta, this.newbie, this.eng2);
                            },
                        }
                    }
                },
                H5b3c2: {
                    on: {
                        SPEAK: {
                            target: "H0",
                            actions: (context, event, actionMeta) => {
                                assign({
                                    actor: (context, event) => this.newbie
                                });
                                this.eng2.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                H5b4: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "H5b4a2",
                            actions: (context, event) => {
                                assign({
                                    actor: (context, event) => this.eng2
                                });
                                this.eng2.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }
                        }, {
                            target: "H5b4b2",
                            actions: (context, event) => {
                                assign({
                                    actor: (context, event) => this.eng2
                                });
                                this.eng2.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }
                        }]
                    }
                },
                H5b4a2: {
                    on: {
                        SPEAK: {
                            target: "H0",
                            actions: (context, event) => {
                                this.eng2.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                H5b4b2: {
                    on: {
                        SPEAK: {
                            target: "H0",
                            actions: (context, event) => {
                                this.eng2.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                H5c1: {
                    on: {
                        SPEAK: {
                            target: "H5c2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event) => {

                                    this.eng3.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();
                                }],
                        }
                    }
                },
                H5c2: {
                    on: {
                        SPEAK: [{
                            target: "H5c3",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng3
                                }),
                                (context, event) => {
                                    this.eng3.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();
                                }]

                        }]
                    }
                },
                H5c3: {
                    on: {
                        SPEAK: [{
                            target: "H5c4",
                            actions: [assign({
                                actor: (context, event) => this.newbie
                            }), (context, event, actionMeta) => {
                                this.newbie.removeSpeechBubble();
                                this.eng3.removeSpeechBubble();
                                this.sayFromChoices(Eng3ChoiceState, Text, 'H5c4', actionMeta, this.newbie, this.eng3);
                            }]
                        }]
                    }
                },
                H5c4: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "H5c5a",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng3
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }

                        },
                        {
                            target: "H5c5b",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng3
                                }),
                                (context, event) => {
                                    this.newbie.removeSpeechBubble();
                                }],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }

                        }]
                    }
                },
                H5c5a: {
                    on: {
                        SPEAK: {
                            target: "H0",
                            actions: (context, event) => {
                                assign({
                                    actor: (context, event) => this.foreman
                                });
                                this.eng3.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                H5c5b: {
                    on: {
                        SPEAK: {
                            target: "H0",
                            actions: (context, event) => {
                                assign({
                                    actor: (context, event) => this.foreman
                                });
                                this.eng3.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                H6a1: {
                    on: {
                        SPEAK: {
                            target: "H0",
                            actions: (context, event) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.hideSkipButton?.();
                            },
                        }
                    }
                },
                H7a1: {
                    on: {
                        SPEAK: {
                            target: "H7a2",
                            actions: (context, event, actionMeta) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                                this.sayFromChoices(FinalChoiceState, Text, 'H7a2', actionMeta, this.newbie);
                            },
                        }
                    }
                },
                H7a2: {
                    //REMV
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "H0",
                            actions: [
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();

                                    this.onFinishHandler?.();
                                }
                            ],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }
                        },
                        {
                            target: "H0",
                            actions: (context, event) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }
                        }
                        ]
                    }
                }
            },
        });

        this.set();
    }

    set(): void {
        this.service = interpret(this.stateMachine).onTransition(
            (state, event) => {
                const actor = state.context.actor as Actor;
                const value = state.value as string;

                if (actor && Object.keys(Text).includes(value)) {
                    const text: string = (<any>Text)[value];
                    actor.say([text]);
                }
            }
        ).start();
    }
}