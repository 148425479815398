import Foreman from "../../characters/Foreman";
import { DEFAULT_HEIGHT, DEFAULT_WIDTH, RESIZE_FACTOR } from "../../globals";
import { GameScene } from "../../models/GameScene";
import Engineer from "../../characters/Engineer";
import { EngineerType } from "../../models/EngineerType";
import Newbie from "../../characters/Newbie";
import PancreasKidneysState from "../../states/PancreasKidneysState";
import BaseScene from "../BaseScene";
import { Floor } from "../../models/Floor";
import { Depth } from "../../models/Depth";

export default class PancreasKidneysLinkScene extends BaseScene {
  private foreman!: Foreman;
  private newbie!: Newbie;
  private ambientSound!: Phaser.Sound.BaseSound;
  private neutralSound!: Phaser.Sound.BaseSound;
  private engineer1!: Engineer;
  private engineer2!: Engineer;
  private restoringBanner!: Phaser.GameObjects.Image;
  private actorCount = 2;

  constructor() {
    super(GameScene.PancreasKidneysLink);
  }

  preload(): void {
    this.load.maxParallelDownloads = 1;
    this.loadVideo("linkroom_pancreas_kidneys_restored", "background/linkrooms/pancreas_kidneys_fixed.mp4");
    this.loadVideo("linkroom_pancreas_kidneys", "background/linkrooms/pancreas_kidneys.mp4");
  }

  initConversations(): void {
    this.stateMachine = new PancreasKidneysState(this.newbie, this.foreman, this.engineer1, this.engineer2);
    this.setupStateMachine();
    
    this.stateMachine.onFinishHandler = () => this.goToElevator();
    this.stateMachine.onConversationStartHandler = () => this.setMenuOpen(false);
    this.stateMachine.onConversationEndHandler = () => {
      this.skipButton.setVisible(false);

      if (this.actorCount === this.interactedActorsCount() && !this.isLinkFixed()) {
        this.setLinkFixed();
        this.setManAtWorkSign();

        const onPanelHide = () => {
          this.showTemporaryBanner(this.restoringBanner, () => {
            this.stateMachine.setState('SPEAK_FINISH');
          });
        };
        this.showPanel("pancreas_kidneys_noticed_panel", onPanelHide, onPanelHide);
      }

      this.updateSymbols();
    }
    this.stateMachine.onSkip = () => this.updateSymbols();

    this.foreman.stateMachine = this.stateMachine;
    this.newbie.stateMachine = this.stateMachine;
    this.engineer1.stateMachine = this.stateMachine;
    this.engineer2.stateMachine = this.stateMachine;

    this.foreman.getSpeechBubble()?.on("pointerup", () => this.stateMachine.nextState());
    this.newbie.getSpeechBubble()?.on("pointerup", () => this.stateMachine.nextState());
    this.engineer1.getSpeechBubble()?.on("pointerup", () => this.stateMachine.nextState());
    this.engineer2.getSpeechBubble()?.on("pointerup", () => this.stateMachine.nextState());

    this.updateSymbols();
  }

  create(): void {
    this.fadeIn();

    const isLinkFixed = this.isLinkFixed();
    const isLinkFixedNoticed = this.isLinkFixedNoticed();

    let backgroundKey = 'linkroom_pancreas_kidneys';
    if (isLinkFixed && isLinkFixedNoticed) {
      backgroundKey = 'linkroom_pancreas_kidneys_restored';
      this.setPhoneSign();
    }

    this.add.video(0, 0, backgroundKey)
      .play(true)
      .setPaused(false)
      .setScale(RESIZE_FACTOR)
      .setLoop(true)
      .setOrigin(0, 0);

    this.configureUI();

    this.ambientSound = this.sound.add("pancreas_kidneys", { loop: true });
    this.ambientSound.play();

    this.neutralSound = this.sound.add("neutral", { loop: true });
    this.neutralSound.play();

    const floorY = DEFAULT_HEIGHT * 0.924;

    this.foreman = new Foreman(this, DEFAULT_WIDTH * 0.47, floorY);
    this.foreman.setScale(0.8);

    this.newbie = new Newbie(this, DEFAULT_WIDTH * 0.58, floorY);
    this.newbie.setScale(0.8);

    this.engineer1 = new Engineer(this, DEFAULT_WIDTH * 0.26, floorY, EngineerType.PancreasKidneys, 1);
    this.engineer1.setIdleFlipX(true);
    this.engineer2 = new Engineer(this, DEFAULT_WIDTH * 0.73, floorY, EngineerType.PancreasKidneys, 2);
    this.engineer2.setIdleFlipX(true);

    this.actors = [this.newbie, this.foreman, this.engineer1, this.engineer2];

    const fromLeft = this.getLastScene() !== GameScene.Pancreas
    this.walkActors([this.foreman, this.newbie], fromLeft);

    this.initConversations();

    if (isLinkFixed) {
      if (isLinkFixedNoticed) {
        this.stateMachine.setState('SPEAK_HAPPY');
        this.engineer1.playHappyAnimation();
        this.engineer2.playHappyAnimation();
      } else {
        this.setManAtWorkSign();
      }
    } else {
      setTimeout(() => {
        if (!this.store.sceneIsVisited(GameScene.PancreasKidneysLink)) {
          this.skipButton.setVisible(true);
          this.stateMachine.nextState();
        }
      }, 1000);
    }

    this.engineer1
      .setInteractive()
      .on("pointerup", () => {
        this.skipButton.setVisible(true);
        this.stateMachine.setState('SPEAK_ENG1');
        this.skipDialogIfNeeded(this.engineer1);
        this.markActorAsInteracted(this.engineer1);
      });

    this.engineer2
      .setInteractive()
      .on("pointerup", () => {
        this.skipButton.setVisible(true);
        this.stateMachine.setState('SPEAK_ENG2');
        this.skipDialogIfNeeded(this.engineer2);
        this.markActorAsInteracted(this.engineer2);
      });

    this.foreman
      .setInteractive()
      .on("pointerup", () => {
        this.skipButton.setVisible(true);
        this.playForemanFinishSceneDialog();
      });

    this.add
      .rectangle(0.0, DEFAULT_HEIGHT * 0.47, DEFAULT_WIDTH * 0.12, DEFAULT_HEIGHT * 0.53)
      .setOrigin(0, 0)
      .setInteractive()
      .on("pointerup", () => {
        const destinationX = DEFAULT_WIDTH * 0.1;
        this.newbie.moveTo(destinationX);
        this.foreman.moveTo(destinationX);
        this.startScene(GameScene.Kidneys);
      });

    this.add
      .rectangle(DEFAULT_WIDTH * 0.88, DEFAULT_HEIGHT * 0.47, DEFAULT_WIDTH * 0.12, DEFAULT_HEIGHT * 0.53)
      .setOrigin(0, 0)
      .setInteractive()
      .on("pointerup", () => {
        const destinationX = DEFAULT_WIDTH * 0.9;
        this.newbie.moveTo(destinationX);
        this.foreman.moveTo(destinationX);
        this.startScene(GameScene.Pancreas);
      });

    this.restoringBanner = this.add
      .image(DEFAULT_WIDTH / 2, DEFAULT_HEIGHT / 2, "restoring_banner")
      .setDepth(Depth.Panel)
      .setAlpha(0);

    this.newbie.onCloseDialog(() => this.skipDialog());
    this.events.on("shutdown", () => this.onShutDown());
  }

  updateSymbols(): void {
    this.updateEngineersSymbols([this.engineer1, this.engineer2], true);
  }

  goToElevator(): void {
    this.startScene(GameScene.Elevator, { currentFloor: Floor.UnknownBottom });
  }

  onShutDown(): void {
    this.ambientSound.stop();
    this.neutralSound.stop();
  }
}
