import * as Phaser from "phaser";
import { DEFAULT_HEIGHT, DEFAULT_WIDTH, WEBSITE_URL } from "../globals";
import { GameScene } from "../models/GameScene";
import BaseScene from "./BaseScene";

export default class LoadingScene extends BaseScene {
  private fillImage!: Phaser.GameObjects.Image;

  constructor() {
    super(GameScene.Loading);
  }

  preload(): void {
    this.add.image(0, 0, "title_background")
      .setOrigin(0, 0);
    this.add.image(DEFAULT_WIDTH * 0.5, DEFAULT_HEIGHT * 0.76, "loading_bar_frame");
    this.fillImage = this.add.image(DEFAULT_WIDTH * 0.5, DEFAULT_HEIGHT * 0.76, "loading_bar_fill");
    this.fillImage.setCrop(0, 0, 0, this.fillImage.height);

    this.add.rectangle(DEFAULT_WIDTH * 0.5, DEFAULT_HEIGHT * 0.9, DEFAULT_WIDTH * 0.3, DEFAULT_HEIGHT * 0.1)
      .setInteractive()
      .on("pointerup", () => window.open(WEBSITE_URL));

    this.load.on(Phaser.Loader.Events.PROGRESS, (percentage: number) => this.updateBar(percentage));
    this.load.on(Phaser.Loader.Events.COMPLETE, () => this.complete());

    this.load.maxParallelDownloads = 32;
    this.loadImages();
    this.loadAudio();
  }

  loadImages(): void {
    this.loadMultiatlas("foreman", "characters/foreman", "foreman");
    this.loadMultiatlas("newbie", "characters/newbie", "newbie");

    this.loadMultiatlas("engineer_heart_1", "characters/heart/1", "engineer_01");
    this.loadMultiatlas("engineer_heart_2", "characters/heart/2", "engineer_02");
    this.loadMultiatlas("engineer_heart_3", "characters/heart/3", "engineer_03");
    this.loadMultiatlas("engineer_heart_pancreas_1", "characters/heart_pancreas/1", "engineer_01");
    this.loadMultiatlas("engineer_kidneys_1", "characters/kidneys/1", "engineer_01");
    this.loadMultiatlas("engineer_kidneys_2", "characters/kidneys/2", "engineer_02");
    this.loadMultiatlas("engineer_kidneys_3", "characters/kidneys/3", "engineer_03");
    this.loadMultiatlas("engineer_kidneys_heart_1", "characters/kidneys_heart/1", "engineer_01");
    this.loadMultiatlas("engineer_pancreas_1", "characters/pancreas/1", "engineer_01");
    this.loadMultiatlas("engineer_pancreas_2", "characters/pancreas/2", "engineer_02");
    this.loadMultiatlas("engineer_pancreas_3", "characters/pancreas/3", "engineer_03");
    this.loadMultiatlas("engineer_pancreas_kidneys_1", "characters/pancreas_kidneys/1", "engineer_01");
    this.loadMultiatlas("engineer_pancreas_kidneys_2", "characters/pancreas_kidneys/2", "engineer_02");

    // Elevator
    this.loadImage("elevator", "background/elevator.png");
    this.loadImage("elevator_panel", "ui/elevator/panel.png");

    this.loadImage("body_button_disabled", "ui/elevator/disabled/body.png");
    this.loadImage("heart_button_disabled", "ui/elevator/disabled/heart.png");
    this.loadImage("kidneys_button_disabled", "ui/elevator/disabled/kidneys.png");
    this.loadImage("pancreas_button_disabled", "ui/elevator/disabled/pancreas.png");

    this.loadImage("body_button_default", "ui/elevator/default/body.png");
    this.loadImage("heart_button_default", "ui/elevator/default/heart.png");
    this.loadImage("kidneys_button_default", "ui/elevator/default/kidneys.png");
    this.loadImage("pancreas_button_default", "ui/elevator/default/pancreas.png");

    this.loadImage("body_button_visited", "ui/elevator/visited/body.png");
    this.loadImage("heart_button_visited", "ui/elevator/visited/heart.png");
    this.loadImage("kidneys_button_visited", "ui/elevator/visited/kidneys.png");
    this.loadImage("pancreas_button_visited", "ui/elevator/visited/pancreas.png");

    // Panels
    this.loadImage("faulty_connection_panel", "panels/central_hub/faulty_connection.png");
    this.loadImage("connection_restored_panel", "panels/central_hub/connection_restored.png");
    this.loadImage("restoring_banner", "panels/central_hub/restoring.png");
    this.loadImage("heart_pancreas_restored_banner", "panels/central_hub/restored/heart_pancreas.png");
    this.loadImage("kidneys_heart_restored_banner", "panels/central_hub/restored/kidneys_heart.png");
    this.loadImage("pancreas_kidneys_restored_banner", "panels/central_hub/restored/pancreas_kidneys.png");

    this.loadImage("heart_attack_panel", "panels/heart/attack.png");
    for (let i = 1; i <= 7; i++) {
      this.loadImage("heart_failure_panel_" + i.toString(), "panels/heart/failure_symptoms/" + i.toString() + ".png");
    }
    for (let i = 1; i <= 9; i++) {
      this.loadImage("diabetes_panel_" + i.toString(), "panels/diabetes/" + i.toString() + ".png");
    }

    this.loadImage("kidneys_heart_noticed_panel", "panels/noticed/kidneys_heart.png");
    this.loadImage("heart_pancreas_noticed_panel", "panels/noticed/heart_pancreas.png");
    this.loadImage("pancreas_kidneys_noticed_panel", "panels/noticed/pancreas_kidneys.png");

    // Progress bar
    for (let i = 0; i <= 3; i++) {
      this.loadImage("progress_bar_" + i.toString(), "ui/progress_bar/" + i.toString() + ".png");
    }

    // Men at work
    this.loadImage("menatwork_blue", "ui/men_at_work/menatwork_blue.png");
    this.loadImage("menatwork_green", "ui/men_at_work/menatwork_green.png");
    this.loadImage("menatwork_red", "ui/men_at_work/menatwork_red.png");

    // Phones
    this.loadImage("phonebox_blue", "ui/phones/phonebox_blue.png");
    this.loadImage("phonebox_green", "ui/phones/phonebox_green.png");
    this.loadImage("phonebox_red", "ui/phones/phonebox_red.png");

    // Buttons
    this.loadImage("sound_off", "ui/buttons/sound_off.png");
    this.loadImage("sound_on", "ui/buttons/sound_on.png");
    this.loadImage("fwd", "ui/buttons/fwd.png");
    this.loadImage("start_button", "ui/buttons/start.png");
    this.loadImage("close_button", "ui/buttons/close.png");
    this.loadImage("menu_button", "ui/buttons/menu.png");
    this.loadImage("restart_button", "ui/buttons/restart.png");
    this.loadImage("references_button", "ui/buttons/references.png");

    // Dialog
    this.loadImage("dialog_talk", "ui/dialog/talk.png");
    this.loadImage("dialog_done", "ui/dialog/done.png");
    this.loadImage("dialog_task", "ui/dialog/task.png");
    this.loadImage("dialog_fixed", "ui/dialog/fixed.png");
    this.loadImage("dialog_close", "ui/dialog/close.png");

    // Tutorial
    this.loadImage("close_panel_tutorial", "ui/tutorial/close_panel.png");
    this.loadImage("examine_object_tutorial", "ui/tutorial/examine_object.png");
    this.loadImage("tap_character_tutorial", "ui/tutorial/tap_character.png");
    this.loadImage("dialog_tutorial", "ui/tutorial/dialog.png");
    this.loadImage("pick_response_tutorial", "ui/tutorial/pick_response.png");
    this.loadImage("examine_tutorial", "ui/tutorial/examine.png");
    this.loadImage("tunnel_tutorial", "ui/tutorial/tunnel.png");
    this.loadImage("visit_organ_tutorial", "ui/tutorial/visit_organ.png");
    this.loadImage("enter_elevator_tutorial", "ui/tutorial/enter_elevator.png");
    this.loadImage("progress_tutorial", "ui/tutorial/progress.png");
    this.loadImage("tutorial_arrow", "ui/tutorial/arrow.png");

    // Popup
    this.loadImage("popup_background", "ui/popup/background.png");
    this.loadImage("popup_big_background", "ui/popup/big_background.png");
    this.loadImage("popup_close", "ui/popup/close.png");
    this.loadImage("popup_yes", "ui/popup/yes.png");
    this.loadImage("popup_no", "ui/popup/no.png");
  }

  loadAudio(): void {
    // Ambience
    this.load.audio("central_hub", "audio/ambience/central_hub.m4a");
    this.load.audio("pancreas", "audio/ambience/pancreas.m4a");
    this.load.audio("kidneys", "audio/ambience/kidneys.m4a");
    this.load.audio("heart", "audio/ambience/heart.m4a");
    this.load.audio("pancreas_kidneys", "audio/ambience/pancreas_kidneys.m4a");
    this.load.audio("kidneys_heart", "audio/ambience/kidneys_heart.m4a");
    this.load.audio("heart_pancreas", "audio/ambience/heart_pancreas.m4a");

    // Music
    this.load.audio("negative", "audio/music/negative.m4a");
    this.load.audio("neutral", "audio/music/neutral.m4a");
    this.load.audio("positive", "audio/music/positive.m4a");
    this.load.audio("elevator", "audio/music/elevator.m4a");

    // SFX
    this.load.audio("button", "audio/sfx/button.m4a");
    this.load.audio("bubble_text", "audio/sfx/bubble_text.m4a");
    this.load.audio("bubble_pop", "audio/sfx/bubble_pop.m4a");
    for (let i = 1; i <= 4; i++) {
      this.load.audio("hmm" + i.toString(), "audio/sfx/hmm" + i.toString() + ".m4a");
    }
    this.load.audio("elevator_start", "audio/sfx/elevator_start.m4a");
    this.load.audio("elevator_stop", "audio/sfx/elevator_stop.m4a");
    this.load.audio("elevator_move", "audio/sfx/elevator_move.m4a");
    this.load.audio("elevator_pling", "audio/sfx/elevator_pling.m4a");
    this.load.audio("inset_open", "audio/sfx/inset_open.m4a");
    this.load.audio("inset_close", "audio/sfx/inset_close.m4a");
  }

  updateBar(percentage: number): void {
    this.fillImage.setCrop(0, 0, this.fillImage.width * percentage, this.fillImage.height);
  }

  complete(): void {
    this.scene.start(GameScene.Title);
  }
}
