import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from "../globals";
import { Depth } from "../models/Depth";

export default class Popup {
  private background: Phaser.GameObjects.Image;
  private closeButton: Phaser.GameObjects.Image;
  private yesButton: Phaser.GameObjects.Image;
  private noButton: Phaser.GameObjects.Image;
  private text: Phaser.GameObjects.Text;

  constructor(scene: Phaser.Scene, onRestart: Function) {
    this.background = scene.add
      .image(DEFAULT_WIDTH / 2, DEFAULT_HEIGHT / 2, "popup_background")
      .setDepth(Depth.UI);

    this.closeButton = scene.add
      .image(0, 0, "popup_close")
      .setDepth(Depth.UI)
      .setInteractive()
      .on("pointerup", () => this.hide());

    this.closeButton.setPosition(this.background.x + this.background.displayWidth / 2 - this.closeButton.displayWidth * 1.5, this.background.y - this.background.displayHeight / 2 + this.closeButton.displayHeight * 1.5);

    this.yesButton = scene.add
      .image(this.background.x - this.background.displayWidth / 5, this.background.y + this.background.displayHeight / 4, "popup_yes")
      .setDepth(Depth.UI)
      .setInteractive()
      .on("pointerup", onRestart);

    this.noButton = scene.add
      .image(this.background.x + this.background.displayWidth / 5, this.background.y + this.background.displayHeight / 4, "popup_no")
      .setDepth(Depth.UI)
      .setInteractive()
      .on("pointerup", () => this.hide());

    this.text = scene.add
      .text(this.background.x, this.background.y - this.background.displayHeight / 8, "Are you sure you want to restart the experience?", {
        fontFamily: "Quicksand",
        fontSize: "34px",
        color: "#0a005b",
        align: "center",
        wordWrap: {
          width: this.background.displayWidth * 0.9
        }
      })
      .setOrigin(0.5, 0.5)
      .setDepth(Depth.UI);

    this.hide();
  }

  show(): void {
    this.setVisible(true);
  }

  hide(): void {
    this.setVisible(false);
  }

  private setVisible(visible: boolean): void {
    this.background.setVisible(visible);
    this.closeButton.setVisible(visible);
    this.yesButton.setVisible(visible);
    this.noButton.setVisible(visible);
    this.text.setVisible(visible);
  }
}