import { createMachine, assign, interpret } from 'xstate';
import { Text, ChoiceState, Newbie1ChoiceState, Newbie2ChoiceState, Newbie3ChoiceState } from "../texts/body-hub"
import Newbie from "../characters/Newbie";
import Foreman from "../characters/Foreman";
import { StateContext } from './StateContext';
import { StateEvent } from './StateEvent';
import Tutorial from '../objects/Tutorial';
import BaseState from "./BaseState";
import Actor from "../Characters/Actor";

export default class CentralHubState extends BaseState {
    newbie: Newbie;
    foreman: Foreman;
    tutorial: Tutorial;

    constructor(newbie: Newbie, foreman: Foreman, tutorial: Tutorial) {
        super();
        this.newbie = newbie;
        this.foreman = foreman;
        this.tutorial = tutorial;
        this.stateMachine = createMachine<StateContext, StateEvent>({
            id: "speech",
            initial: "l0", //get from state later
            context: {
                actor: undefined
            },
            states: {
                l0: {
                    on: {
                        SPEAK: {
                            target: "l1",
                            actions: [
                                (context) => this.tutorial.showDialogTutorial(),
                                assign({
                                    actor: (context, event) => this.newbie
                                })
                            ]
                        },
                        SPEAK_LINK1: {
                            target: "L1a1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                        SPEAK_LINK2: {
                            target: "L1b1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                        SPEAK_LINK3: {
                            target: "L1c1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                        SPEAK_ENDING: {
                            target: "E1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        }
                    }
                },
                l1: {
                    on: {
                        SPEAK: {
                            target: "t0",
                            actions: [
                                (context) => {
                                    this.newbie.removeSpeechBubble();
                                    this.tutorial.showTapCharacterTutorial();
                                },
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        }
                    }
                },
                t0: {
                    on: {
                        SPEAK: {
                            target: "l2",
                            actions: [
                                (context) => this.tutorial.hide(),
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        }
                    }
                },
                l2: {
                    on: {
                        SPEAK: {
                            target: "l3",
                            actions: [
                                (context) => this.foreman.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.newbie
                                })

                            ]
                        }
                    }
                },
                l3: {
                    on: {
                        SPEAK: {
                            target: "l4",
                            actions: [
                                (context) => this.newbie.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        }
                    }
                },
                l4: {
                    on: {
                        SPEAK: {
                            target: "l5",
                        }
                    }
                },
                l5: {
                    on: {
                        SPEAK: {
                            target: "l6",

                            actions: [
                                (context) => this.tutorial.showPickResponseTutorial(),
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event, actionMeta) => {
                                    this.foreman.removeSpeechBubble();
                                    this.sayFromChoices(ChoiceState, Text, 'l6', actionMeta, this.newbie);
                                }
                            ]
                        }
                    },

                },
                l6: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: {
                            target: "l7",
                            actions: [
                                (context, event, actionMeta) => {
                                    this.newbie.removeSpeechBubble();
                                    this.tutorial.hide();

                                },
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        }
                    }
                },
                l7: {
                    on: {
                        SPEAK: {
                            target: "l8",
                            actions: [
                                (context) => this.foreman.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.newbie
                                })
                            ]
                        }
                    }
                },
                l8: {
                    on: {
                        SPEAK: {
                            target: "l9",
                            actions: [
                                (context) => this.newbie.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        }
                    }
                },
                l9: {
                    on: { SPEAK: "l10" }
                },
                l10: {
                    on: {
                        SPEAK: {
                            target: "l0",
                            actions: (context, event) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                                this.tutorial.showExamineObjectTutorial();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                L1a1: {
                    on: {
                        SPEAK: {
                            target: "L1a2",
                            actions: (context, event, actionMeta) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                                this.sayFromChoices(Newbie1ChoiceState, Text, 'L1a2', actionMeta, this.newbie);
                            },
                        }
                    }
                },
                L1a2: {
                    on: {
                        SPEAK: [{
                            target: "l0",
                            actions: (context, event, actionMeta) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }
                        }, {
                            target: "l0",
                            actions: (context, event) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                                this.foreman.playHappyAnimation();
                                this.newbie.playHappyAnimation();

                                this.onConversationEndHandler?.();
                                this.onFinishHandler?.();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }
                        }]
                    }
                },
                L1b1: {
                    on: {
                        SPEAK: {
                            target: "L1b2",
                            actions: (context, event, actionMeta) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                                this.sayFromChoices(Newbie2ChoiceState, Text, 'L1b2', actionMeta, this.newbie);
                            },
                        }
                    }
                },
                L1b2: {
                    on: {
                        SPEAK: [{
                            target: "l0",
                            actions: (context, event, actionMeta) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }
                        }, {
                            target: "l0",
                            actions: (context, event) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                                this.foreman.playHappyAnimation();
                                this.newbie.playHappyAnimation();

                                this.onConversationEndHandler?.();
                                this.onFinishHandler?.();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }
                        }]
                    }
                },
                L1c1: {
                    on: {
                        SPEAK: {
                            target: "L1c2",
                            actions: (context, event, actionMeta) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                                this.sayFromChoices(Newbie3ChoiceState, Text, 'L1c2', actionMeta, this.newbie);
                            },
                        }
                    }
                },
                L1c2: {
                    on: {
                        SPEAK: [{
                            target: "l0",
                            actions: (context, event, actionMeta) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }
                        }, {
                            target: "l0",
                            actions: (context, event) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                                this.foreman.playHappyAnimation();
                                this.newbie.playHappyAnimation();

                                this.onConversationEndHandler?.();
                                this.onFinishHandler?.();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }
                        }]
                    }
                },
                E1: {
                    on: {
                        SPEAK: {
                            target: "E2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();
                                }
                            ]
                        }
                    }
                },
                E2: {
                    on: {
                        SPEAK: {
                            target: "E3",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();
                                }
                            ]
                        }
                    }
                }, E3: {
                    on: {
                        SPEAK: {
                            target: "E4",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();
                                }
                            ]
                        }
                    }
                },
                E4: {
                    on: {
                        SPEAK: {
                            target: "E5",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();
                                }
                            ]
                        }
                    }
                },
                E5: {
                    on: {
                        SPEAK: {
                            target: "l0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();
                                    this.foreman.playHappyAnimation();
                                    this.newbie.playHappyAnimation();

                                    this.onConversationEndHandler?.();
                                    this.onFinishHandler?.();
                                }
                            ]
                        }
                    }
                }
            },
            on: {
                RESET: 'l0' // explicit self-transition
            }
        });
        this.set()
    }

    set(): void {
        this.service = interpret(this.stateMachine).onTransition(
            (state, event) => {
                const actor = state.context.actor as Actor;
                const value = state.value as string;

                if (actor && Object.keys(Text).includes(value)) {
                    const text: string = (<any>Text)[value];
                    actor.say([text]);
                }
            }
        ).start();
    }

}