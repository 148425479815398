import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from "./globals";
import TitleScene from "./scenes/TitleScene";
import PancreasScene from "./scenes/rooms/PancreasScene";
import KidneysScene from "./scenes/rooms/KidneysScene";
import CentralHubScene from "./scenes/rooms/CentralHubScene";
import ElevatorScene from "./scenes/rooms/ElevatorScene";
import HeartScene from "./scenes/rooms/HeartScene";
import HeartPancreasLinkScene from "./scenes/linkrooms/HeartPancreasLinkScene";
import PancreasKidneysLinkScene from "./scenes/linkrooms/PancreasKidneysLinkScene";
import KidneysHeartLinkScene from "./scenes/linkrooms/KidneysHeartLinkScene";
import BootScene from "./scenes/BootScene";
import LoadingScene from "./scenes/LoadingScene";
import EndScene from "./scenes/EndScene";

export const GameConfig: Phaser.Types.Core.GameConfig = {
  title: "Organs Talk",
  version: "0.0.1",
  width: DEFAULT_WIDTH,
  height: DEFAULT_HEIGHT,
  type: Phaser.AUTO,
  parent: "game",
  scene: [
    BootScene,
    LoadingScene,
    TitleScene,
    CentralHubScene,
    ElevatorScene,
    HeartScene,
    PancreasScene,
    KidneysScene,
    HeartPancreasLinkScene,
    PancreasKidneysLinkScene,
    KidneysHeartLinkScene,
    EndScene
  ],
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH
  },
  dom: {
    createContainer: true
  }
};
