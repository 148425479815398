import Foreman from "../../characters/Foreman";
import { DEFAULT_HEIGHT, DEFAULT_WIDTH, RESIZE_FACTOR } from "../../globals";
import { GameScene } from "../../models/GameScene";
import Engineer from "../../characters/Engineer";
import { EngineerType } from "../../models/EngineerType";
import Newbie from "../../characters/Newbie";
import KidneysHeartState from "../../states/KidneysHeartState";
import { Floor } from "../../models/Floor";
import BaseScene from "../BaseScene";
import { Depth } from "../../models/Depth";

export default class KidneysHeartLinkScene extends BaseScene {
  private foreman!: Foreman;
  private newbie!: Newbie;
  private ambientSound!: Phaser.Sound.BaseSound;
  private neutralSound!: Phaser.Sound.BaseSound;
  private engineer1!: Engineer;
  private restoringBanner!: Phaser.GameObjects.Image;
  private actorCount = 1;

  constructor() {
    super(GameScene.KidneysHeartLink);
  }

  preload(): void {
    this.load.maxParallelDownloads = 1;
    this.loadVideo("linkroom_kidneys_heart_restored", "background/linkrooms/kidneys_heart_fixed.mp4");
    this.loadVideo("linkroom_kidneys_heart", "background/linkrooms/kidneys_heart.mp4");
  }

  initConversations(): void {
    this.stateMachine = new KidneysHeartState(this.newbie, this.foreman, this.engineer1);
    this.setupStateMachine();
    
    this.stateMachine.onFinishHandler = () => this.goToElevator();
    this.stateMachine.onConversationStartHandler = () => this.setMenuOpen(false);
    this.stateMachine.onConversationEndHandler = () => {
      this.skipButton.setVisible(false);

      if (this.actorCount === this.interactedActorsCount() && !this.isLinkFixed()) {
        this.setLinkFixed();
        this.setManAtWorkSign();

        const onPanelHide = () => {
          this.showTemporaryBanner(this.restoringBanner, () => {
            this.stateMachine.setState('SPEAK_FINISH');
          });
        };
        this.showPanel("kidneys_heart_noticed_panel", onPanelHide, onPanelHide);
      }

      this.updateSymbols();
    }
    this.stateMachine.onSkip = () => this.updateSymbols();

    this.foreman.stateMachine = this.stateMachine;
    this.newbie.stateMachine = this.stateMachine;
    this.engineer1.stateMachine = this.stateMachine;

    this.foreman.getSpeechBubble()?.on("pointerup", () => this.stateMachine.nextState());
    this.newbie.getSpeechBubble()?.on("pointerup", () => this.stateMachine.nextState());
    this.engineer1.getSpeechBubble()?.on("pointerup", () => this.stateMachine.nextState());

    this.updateSymbols();
  }

  create(): void {
    this.fadeIn();
    
    const isLinkFixed = this.isLinkFixed();
    const isLinkFixedNoticed = this.isLinkFixedNoticed();

    let backgroundKey = 'linkroom_kidneys_heart';
    if (isLinkFixed && isLinkFixedNoticed) {
      backgroundKey = 'linkroom_kidneys_heart_restored';
      this.setPhoneSign();
    }

    this.add.video(0, 0, backgroundKey)
      .play(true)
      .setPaused(false)
      .setScale(RESIZE_FACTOR)
      .setLoop(true)
      .setOrigin(0, 0);

    this.configureUI();

    const floorY = DEFAULT_HEIGHT * 0.924;

    this.foreman = new Foreman(this, DEFAULT_WIDTH * 0.48, floorY);
    this.foreman.setScale(0.8);
    this.newbie = new Newbie(this, DEFAULT_WIDTH * 0.59, floorY);
    this.newbie.setScale(0.8);

    this.engineer1 = new Engineer(this, DEFAULT_WIDTH * 0.76, floorY, EngineerType.KidneysHeart, 1);

    this.actors = [this.newbie, this.foreman, this.engineer1];

    const fromLeft = this.getLastScene() !== GameScene.Kidneys
    this.walkActors([this.foreman, this.newbie], fromLeft);

    this.ambientSound = this.sound.add("kidneys_heart", { loop: true });
    this.ambientSound.play();
    this.neutralSound = this.sound.add("neutral", { loop: true });
    this.neutralSound.play();

    this.initConversations();
    
    if (isLinkFixed) {
      if (isLinkFixedNoticed) {
        this.stateMachine.setState('SPEAK_HAPPY');
        this.engineer1.playHappyAnimation();
      } else {
        this.setManAtWorkSign();
      }
    } else {
      setTimeout(() => {
        if (!this.store.sceneIsVisited(GameScene.KidneysHeartLink)) {
          this.skipButton.setVisible(true);
          this.stateMachine.nextState();
        }
      }, 1000);
    }

    this.engineer1
      .setInteractive()
      .on("pointerup", () => {
        this.skipButton.setVisible(true);
        this.stateMachine.setState('SPEAK_ENG1')
        this.skipDialogIfNeeded(this.engineer1);
        this.markActorAsInteracted(this.engineer1);
      });

    this.foreman
      .setInteractive()
      .on("pointerup", () => {
        this.skipButton.setVisible(true);
        this.playForemanFinishSceneDialog();
      });

    this.add
      .rectangle(0.0, DEFAULT_HEIGHT * 0.47, DEFAULT_WIDTH * 0.12, DEFAULT_HEIGHT * 0.53)
      .setOrigin(0, 0)
      .setInteractive()
      .on("pointerup", () => {
        const destinationX = DEFAULT_WIDTH * 0.1;
        this.newbie.moveTo(destinationX);
        this.foreman.moveTo(destinationX);
        this.startScene(GameScene.Heart);
      });

    this.add
      .rectangle(DEFAULT_WIDTH * 0.88, DEFAULT_HEIGHT * 0.47, DEFAULT_WIDTH * 0.12, DEFAULT_HEIGHT * 0.53)
      .setOrigin(0, 0)
      .setInteractive()
      .on("pointerup", () => {
        const destinationX = DEFAULT_WIDTH * 0.9;
        this.newbie.moveTo(destinationX);
        this.foreman.moveTo(destinationX);
        this.startScene(GameScene.Kidneys);
      });

    this.restoringBanner = this.add
      .image(DEFAULT_WIDTH / 2, DEFAULT_HEIGHT / 2, "restoring_banner")
      .setDepth(Depth.Panel)
      .setAlpha(0);

    this.newbie.onCloseDialog(() => this.skipDialog());
    this.events.on("shutdown", () => this.onShutDown());
  }

  updateSymbols(): void {
    this.updateEngineersSymbols([this.engineer1], true);
  }

  goToElevator(): void {
    this.startScene(GameScene.Elevator, { currentFloor: Floor.UnknownBottom });
  }

  onShutDown(): void {
    this.ambientSound.stop();
    this.neutralSound.stop();
  }

}
