export enum Text {
    PH1 = `Hmm, that's weird, I've never been down here before. Every day a new adventure, huh, Newbie?`,
    PH2 = `Uhh... Sure? What IS this place?`,
    PH3 = `Hello Newbie! I look after the blood vessels connecting the heart and pancreas. `,
    PH4 = `What happens if the connection breaks down?`,
    PH5 = `Because both departments rely on each other, a disease in one increases the chances of disease in the other. Communication breakdowns can lead to problems like heart failure.`,

    PH6a1 = `Did someone say heart failure?`,
    PH6a2a = `What does heart failure have to do with the blood vessels linking the heart and pancreas?`,
    PH6a2b = `Heart failure? What's heart failure?`,
    PH6a3a = `Well, people with diabetes are 50% more likely to develop heart failure than people without it.`,
    PH6a3b = `Heart failure is when either the heart muscle isn't pumping properly, the heart isn't filling with blood, or there are defects in the valves.`,

    PH7a1 = `Heart failure is serious... but right now I've got other issues too!`,
    PH7a2a = `What's the problem?`,
    PH7a2b = `How can I help?`,
    PH7a3a = `Look! High levels of fats have built up inside the body’s large blood vessels in hard lumps called fatty plaques. If it gets worse, this can cause clots – or even cardiovascular disease!`,
    PH7a3b = `97% of type 2 diabetes patients have abnormal levels of blood lipids – fats like cholesterol. So the best way to keep the system working is to prevent diabetes.`,
    PH7a4a = `Those pipes look pretty rusty, too!`,
    PH7a5a = `Yeah, people with diabetes usually have hyperglycaemia, or high blood sugar, which damages the walls of the blood vessels. Then blood flow is reduced oxygen, and nutrients aren't delivered to the muscles properly. And that can weaken the muscles in the heart.`,

    PH7b1 = `And that can lead to HFrEF or HFpEF heart failure!`,
    PH7b2a = `What's HFrEF heart failure?`,
    PH7b2b = `Tell me more about HFpEF heart failure!`,
    PH7b3a = `That's heart failure with reduced left ventricular function. That's when the lower left chamber swells up and can't contract enough to pump the right amount of blood to the rest of the body.`,
    PH7b3b = `Heart failure with preserved left ventricular function happens when the heart is pumping normally, but the bottom chambers are thicker and stiffer than normal. This prevents the ventricles from relaxing properly, and they fill up with blood. That means less blood is pumped out to the rest of the body when the heart contracts.`,

    PH7c1 = `Let's get back to the body hub to restore communications!`,
    PH7c2a = `Sounds good to me – let's get back to the elevator.`,
    PH7c2b = `Wait! I've still got a few more questions!`,

    PHCOMM = `Thank you! Communication makes my job a whole lot easier!`,

}
export enum HeartEng2aState{
    PH6a2a,
    PH6a2b,
}
export enum PHEngState{
    PH7a2a,
    PH7a2b,
}
export enum HeartEng2bState{
    PH7b2a,
    PH7b2b,
}

export enum FinalChoiceState {
    PH7c2a,
    PH7c2b,

}