import { createMachine, interpret, assign, StateMachine } from 'xstate';
import { Text } from "../texts/elevator"
import Actor from "../characters/Actor";
import Newbie from "../characters/Newbie";
import Foreman from "../characters/Foreman";
import { StateContext } from './StateContext';
import { StateEvent } from './StateEvent';
import BaseState from "./BaseState";

export default class ElevatorState extends BaseState {
    newbie: Newbie;
    foreman: Foreman;

    constructor(newbie: Newbie, foreman: Foreman) {
        super();
        this.newbie = newbie;
        this.foreman = foreman;
        this.stateMachine = createMachine<StateContext, StateEvent>({
            id: "speech",
            initial: "Ea0", //get from state later
            context: {
                actor: this.foreman
            },
            states: {
                Ea0: {
                    on: {
                        SPEAK_HEART: {
                            target: "Ea1",
                            actions: [
                                (context, event) => {
                                    assign({
                                        actor: (context, event) => this.foreman
                                    })
                                }],
                        },
                        SPEAK_FROM_HEART: {
                            target: "Ea4",
                            actions: [(context, event) => {
                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            }],
                        },
                        SPEAK_KIDNEYS: {
                            target: "Eb1",
                            actions: [(context, event) => {
                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            }],
                        },
                        SPEAK_FROM_KIDNEYS: {
                            target: "Eb4",
                            actions: [(context, event) => {
                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            }],
                        },
                        SPEAK_PANCREAS: {
                            target: "Ec1",
                            actions: [(context, event) => {
                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            }],
                        },
                        SPEAK_FROM_PANCREAS: {
                            target: "Ec4",
                            actions: [(context, event) => {
                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            }],
                        },
                        SPEAK: {
                            target: "Ea0",
                            actions: [(context, event) => {
                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            }],
                        },
                        SPEAK_ELEVATOR_FIRST: {
                            target: "Ea0a",
                            actions: [(context, event) => {
                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            }],
                        },
                        SPEAK_UNSURE: {
                            target: "Ea0b",
                            actions: [(context, event) => {
                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            }],
                        }
                    }
                },
                Ea0a: {
                    on: {
                        SPEAK: {
                            target: "Ea0",
                            actions: [
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                }]
                        },
                        SPEAK_UNSURE: {
                            target: "Ea0b",
                            actions: [(context, event) => {
                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            }],
                        }
                    }
                },
                Ea0b: {
                    on: {
                        SPEAK: {
                            target: "Ea0",
                            actions: [
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                }]
                        }
                    }
                },
                Ea1: {
                    on: {
                        SPEAK: {
                            target: "Ea2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();
                                }]
                        }
                    }
                },
                Ea2: {
                    on: {
                        SPEAK: {
                            target: "Ea3",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();

                                }]
                        }
                    }
                },
                Ea3: {
                    on: {
                        SPEAK: {
                            target: "Ea0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                    this.onFinishHandler?.();
                                }
                            ],
                        }
                    }
                },
                Ea4: {
                    on: {
                        SPEAK: {
                            target: "Ea0",
                            actions: (context, event) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                Eb1: {
                    on: {
                        SPEAK: {
                            target: "Eb2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();

                                }],
                        }
                    }
                },
                Eb2: {
                    on: {
                        SPEAK: {
                            target: "Eb3",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();

                                }],
                        }
                    }
                },
                Eb3: {
                    on: {
                        SPEAK: {
                            target: "Ea0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                    this.onFinishHandler?.();
                                }],
                        }
                    }
                },
                Eb4: {
                    on: {
                        SPEAK: {
                            target: "Ea0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                }],
                        }
                    }
                },
                Ec1: {
                    on: {
                        SPEAK: {
                            target: "Ec2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();
                                }],
                        }
                    }
                },
                Ec2: {
                    on: {
                        SPEAK: {
                            target: "Ec3",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();
                                }],
                        }
                    }
                },
                Ec3: {
                    on: {
                        SPEAK: {
                            target: "Ea0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                    this.onFinishHandler?.();
                                }],
                        }
                    }
                },
                Ec4: {
                    on: {
                        SPEAK: {
                            target: "Ea0",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                }),
                                (context, event) => {
                                    this.foreman.removeSpeechBubble();
                                    this.newbie.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                }],
                        }
                    }
                },
            },
        });
        this.set();
    }

    set(): void {
        this.service = interpret(this.stateMachine).onTransition(
            (state, event) => {
                const actor = state.context.actor as Actor;
                const value = state.value as string;
                
                if (actor && Object.keys(Text).includes(value)) {
                    const text: string = (<any>Text)[value];
                    actor.say([text]);
                }
            }
        ).start();
    }
}