import { Scene } from "phaser";
import { Depth } from "../models/Depth";
import Actor, { AnimationKey } from "./Actor";

export default class Newbie extends Actor {
  constructor(scene: Scene, x: number, y: number) {
    super(scene, x, y, "newbie", {
      [AnimationKey.Idle]: 100,
      [AnimationKey.Walking]: 25,
      [AnimationKey.Talking]: 100,
      [AnimationKey.Thinking]: 100,
      [AnimationKey.Happy]: 50
    });
    
    this.setDepth(Depth.Newbie);
  }
}