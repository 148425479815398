import Actor from "../characters/Actor";
import { GameScene } from "../models/GameScene";
import { TutorialElement } from "../models/TutorialElement";

export default class LocalStorageRegistry {
  private storage: Storage;

  constructor() {
    this.storage = localStorage;
  }

  setSceneVisited(scene: GameScene): void {
    this.storage.setItem("scene-visited-" + scene, "true");
  }

  sceneIsVisited(scene: GameScene): boolean {
    return this.storage.getItem("scene-visited-" + scene) === "true";
  }

  setIsMuted(isMuted: boolean): void {
    this.storage.setItem("is-muted", String(isMuted));
  }

  isMuted(): boolean {
    return this.storage.getItem("is-muted") === "true";
  }

  setLinkFixed(scene: GameScene): void {
    const linksFixed = this.getLinksFixed();
    if (!linksFixed.includes(scene)) {
      linksFixed.push(scene);
    }
    this.storage.setItem("links-fixed", JSON.stringify(linksFixed));
  }

  setLinkFixedNoticed(scene: GameScene): void {
    this.storage.setItem("links-fixed-" + scene, "true");
  }

  isLinkFixedNoticed(scene: GameScene): boolean {
    return this.storage.getItem("links-fixed-" + scene) === "true";
  }

  getLinksFixed(): Array<GameScene> {
    const linksFixed = this.storage.getItem("links-fixed");
    if (linksFixed === null) {
      return [];
    }
    return JSON.parse(linksFixed);
  }

  getLastScene(): GameScene | null {
    return this.storage.getItem("last-scene") as GameScene;
  }

  setLastScene(scene: GameScene): void {
    this.storage.setItem("last-scene", scene);
  }

  getCurrentScene(): GameScene | null {
    return this.storage.getItem("current-scene") as GameScene;
  }

  setCurrentScene(scene: GameScene): void {
    this.storage.setItem("current-scene", scene);
  }

  private getActors(type: string, scene: GameScene): Array<string> {
    const interacted = this.storage.getItem(type + scene);
    if (interacted === null) {
      return [];
    }
    return JSON.parse(interacted);
  }

  private addActor(type: string, saved: Array<string>, scene: GameScene, actor: Actor): void {
    const actorName = actor.name;
    if (saved.indexOf(actorName) === -1) {
      saved.push(actorName);
      this.storage.setItem(type + scene, JSON.stringify(saved));
    }
  }

  getInteractedActors(scene: GameScene): Array<string> {
    return this.getActors("actors-interacted-", scene);
  }

  addInteractedActor(scene: GameScene, actor: Actor): void {
    const interacted = this.getInteractedActors(scene);
    this.addActor("actors-interacted-", interacted, scene, actor);
  }

  getCompletedActors(scene: GameScene): Array<string> {
    return this.getActors("actors-completed-", scene);
  }

  addCompletedActor(scene: GameScene, actor: Actor): void {
    const completed = this.getCompletedActors(scene);
    this.addActor("actors-completed-", completed, scene, actor);
  }

  isTutorialFinished(element: TutorialElement): boolean {
    return this.storage.getItem("tutorial-finished-" + element) === "true";
  }

  setTutorialFinished(element: TutorialElement): void {
    this.storage.setItem("tutorial-finished-" + element, "true");
  }

  saveChosenStates(states: { [key: string]: Array<integer> }): void {
    this.storage.setItem("chosen-states", JSON.stringify(states));
  }

  getChosenStates(): { [key: string]: Array<integer> } {
    const chosenStates = this.storage.getItem("chosen-states");
    if (chosenStates === null) {
      return {};
    }
    return JSON.parse(chosenStates);
  }

  reset(): void {
    this.storage.clear();
  }
}