import { Scene } from "phaser";
import { Depth } from "../models/Depth";
import Actor, { AnimationKey } from "./Actor";

export default class Foreman extends Actor {
  constructor(scene: Scene, x: number, y: number) {
    super(scene, x, y, "foreman", {
      [AnimationKey.Idle]: 141,
      [AnimationKey.Walking]: 25,
      [AnimationKey.Talking]: 141,
      [AnimationKey.Thinking]: 141,
      [AnimationKey.Happy]: 141
    });
    
    this.setDepth(Depth.Foreman);
  }

}