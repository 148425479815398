import { createMachine, interpret, assign, StateMachine } from 'xstate';
import BaseState from "./BaseState";
import { Text, Eng1ChoiceState, Eng2ChoiceState, Eng3ChoiceState, FinalChoiceState } from "../texts/kidneys"
import Actor from "../characters/Actor";
import Newbie from "../characters/Newbie";
import Foreman from "../characters/Foreman";
import Engineer from "../characters/Engineer";
import { StateContext } from './StateContext';
import { StateEvent } from './StateEvent';


export default class KidneysState extends BaseState {
    newbie: Newbie;
    foreman: Foreman;
    eng1: Engineer;
    eng2: Engineer;
    eng3: Engineer;
    text!: Text;

    constructor(newbie: Newbie, foreman: Foreman, eng1: Engineer, eng2: Engineer, eng3: Engineer) {
        super();
        this.newbie = newbie;
        this.foreman = foreman;
        this.eng1 = eng1;
        this.eng2 = eng2;
        this.eng3 = eng3;

        this.stateMachine = createMachine<StateContext, StateEvent>({
            id: "speech",
            initial: "K0", //get from state later
            context: {
                actor: undefined
            },
            states: {
                K0: {
                    on: {
                        SPEAK: {
                            target: "K1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                        SPEAK_ENG1: {
                            target: "K5a1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng1
                                })
                            ]
                        },
                        SPEAK_ENG2: {
                            target: "K5b1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng2
                                })
                            ]
                        },
                        SPEAK_ENG3: {
                            target: "K5c1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng3
                                })
                            ]
                        },
                        SPEAK_FOREMAN: {
                            target: "K6a1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                        SPEAK_FINISH: {
                            target: "K7a1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                    }
                },
                K1: {
                    on: {
                        SPEAK: {
                            target: "K2",
                            actions: [
                                (context) => this.foreman.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.newbie
                                })
                            ]
                        }
                    }
                },
                K2: {
                    on: {
                        SPEAK: [{
                            target: "K3",
                            actions: [
                                (context) => this.newbie.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ],
                        },

                        ]
                    }

                },
                K3: {
                    on: {
                        SPEAK: {
                            target: "K0",
                            actions: [
                                (context) => {
                                    this.foreman.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                },
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        }
                    }
                },
                K5a1: {
                    on: {
                        SPEAK: {
                            target: "K5a2",
                            actions: (context, event, actionMeta) => {
                                assign({
                                    actor: (context, event) => this.newbie
                                });
                                this.eng1.removeSpeechBubble();
                                this.sayFromChoices(Eng1ChoiceState, Text, 'K5a2', actionMeta, this.newbie, this.eng1);
                            },
                        }
                    }
                },
                K5a2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "K5a3a",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng1
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }
                        },
                        {
                            target: "K5a3b",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng1
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }
                        }]
                    }
                },
                K5a3a: {
                    on: {
                        SPEAK: {
                            target: "K0",
                            actions: (context, event) => {
                                this.eng1.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                K5a3b: {
                    on: {
                        SPEAK: {
                            target: "K0",
                            actions: (context, event) => {
                                this.eng1.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                K5b1: {
                    on: {
                        SPEAK: {
                            target: "K5b2",
                            actions: (context, event, actionMeta) => {
                                assign({
                                    actor: (context, event) => this.eng2
                                });
                                this.eng2.removeSpeechBubble();
                                this.sayFromChoices(Eng2ChoiceState, Text, 'K5b2', actionMeta, this.newbie, this.eng2);
                            },

                        }
                    }
                },
                K5b2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "K5b3a",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng2
                                });

                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }
                        },
                        {
                            target: "K5b3b",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng2
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }
                        },
                        {
                            target: "K5b3c",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng2
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 2;
                            }
                        },
                        {
                            target: "K5b3d",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng2
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 3;
                            }
                        }]
                    }
                },
                K5b3a: {
                    on: {
                        SPEAK: {
                            target: "K0",
                            actions: (context, event) => {
                                this.eng2.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                K5b3b: {
                    on: {
                        SPEAK: {
                            target: "K0",
                            actions: (context, event) => {
                                this.eng2.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                K5b3c: {
                    on: {
                        SPEAK: {
                            target: "K0",
                            actions: (context, event) => {
                                this.eng2.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                K5b3d: {
                    on: {
                        SPEAK: {
                            target: "K0",
                            actions: (context, event) => {
                                this.eng2.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                K5c1: {
                    on: {
                        SPEAK: {
                            target: "K5c2",
                            actions: (context, event, actionMeta) => {
                                assign({
                                    actor: (context, event) => this.eng3
                                });
                                this.eng3.removeSpeechBubble();
                                this.sayFromChoices(Eng3ChoiceState, Text, 'K5c2', actionMeta, this.newbie, this.eng3);
                            },
                        }
                    }
                },
                K5c2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "K5c3a",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng3
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }
                        },
                        {
                            target: "K5c3b",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng3
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }
                        }]
                    }
                },
                K5c3a: {
                    on: {
                        SPEAK: {
                            target: "K0",
                            actions: (context, event) => {
                                this.eng3.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                K5c3b: {
                    on: {
                        SPEAK: {
                            target: "K0",
                            actions: (context, event) => {
                                this.eng3.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },

                K6a1: {
                    on: {
                        SPEAK: {
                            target: "K0",
                            actions: (context, event) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.hideSkipButton?.();
                            },
                        }
                    }
                },
                K7a1: {
                    on: {
                        SPEAK: {
                            target: "K7a2",
                            actions: (context, event, actionMeta) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();
                                this.sayFromChoices(FinalChoiceState, Text, 'K7a2', actionMeta, this.newbie);
                            },
                        }
                    }
                },
                K7a2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "K0",
                            actions: (context, event) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onFinishHandler?.();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }

                        },
                        {
                            target: "K0",
                            actions: (context, event) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }

                        }]
                    }
                }

            },
        });
        this.set();
    }

    set(): void {
        this.service = interpret(this.stateMachine).onTransition(
            (state, event) => {
                const actor = state.context.actor as Actor;
                const value = state.value as string;

                if (actor && Object.keys(Text).includes(value)) {
                    const text: string = (<any>Text)[value];
                    actor.say([text]);
                }
            }
        ).start();
    }
}