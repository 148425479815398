export enum GameScene {
  Boot = "BootScene",
  Loading = "LoadingScene",
  Title = "TitleScene",
  CentralHub = "CentralHubScene",
  Elevator = "ElevatorScene",
  Heart = "HeartScene",
  Kidneys = "KidneysScene",
  Pancreas = "PancreasScene",
  HeartPancreasLink = "HeartPancreasLinkScene",
  PancreasKidneysLink = "PancreasKidneysLinkScene",
  KidneysHeartLink = "KidneysHeartLinkScene",
  End = "EndScene"
}