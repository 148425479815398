export enum Text {
    l1 = `Okay, first day. It’s fine. Everything’s fine. Fine, fine, fine. It’s going to be a great day.`,
    l2 = `Ha! First day, eh? Let’s get you started, Newbie!`,
    l3 = `Uhh, hi... I… I just got here, I’m new.`,
    l4 = `Well, welcome to the family, Newbie. Just stick with me, and you’ll do just fine. After all, look at this – it mostly runs itself!`,
    l5 = `Then again, I really should take you through the different departments. Who knows where you’ll be assigned to work?`,
    l6a = `Departments? What departments?`,
    l6b = `Assigned? What do you mean?`,
    l7 = `Yeah, once you’ve completed your training, you’ll be assigned to a department – could be the heart, kidneys or pancreas.`,
    l8 = `Which one's the most important?`,
    l9 = `They ALL are! But it's the communication between the three departments that's really important, as they can all affect each other. Check out the computer to see how it works.`,
    l10 = `Ahh, these cameras don’t do it justice. You need to experience it! The hum of industry! The buzz of teamwork! There's nothing like it, I’m telling you. Let’s take the elevator down to meet them.`,

    L1a1 = `That was some slick work down there, Newbie! We've restored communication between the heart and the pancreas! But it looks like there's more to do...`,
    L1a2a = `Let's see what else we can do!`,
    L1a2b = `No, that's enough for me.`,

    L1b1 = `Nice going! We got the kidney and pancreas departments talking again! Ready for another one?`,
    L1b2a = `Yeah, let's explore some more!`,
    L1b2b = `No, I think that's enough excitement for now.`,

    L1c1 = `Great work! It's been a while since the heart and kidneys have worked this well together. What do you say? Want to try another one?`,
    L1c2a = `Count me in!`,
    L1c2b = `No, I've seen all I want to see.`,

    E1 = `So, what do you think, Newbie? Pretty slick operation, huh?`,
    E2 = `Yeah, I had no idea how important the connection between organ departments was – and how bad things can get when communication breaks down!`,
    E3 = `But the great part is that now we've fixed got the departments communicating, improvements in one area – like lifestyle or appropriate care – can help improve health in other areas!`,
    E4 = `So what department am I assigned to?`,
    E5 = `I think we're going to create a new department, just for you. Welcome to communications! You're going to make sure these organs keep talking, just as they should be!`,

}

export enum ChoiceState {
    l6a,
    l6b
}

export enum Newbie1ChoiceState{
    L1a2a,
    L1a2b,
}
export enum Newbie2ChoiceState{
    L1b2a,
    L1b2b,
}
export enum Newbie3ChoiceState {
    L1c2a,
    L1c2b,
}