import { Scene } from "phaser";
import SpeechBubble, { Tip } from "../objects/SpeechBubble";
import { DEFAULT_WIDTH ,RENDER_SCALE,RESIZE_FACTOR} from "../globals";
import { DefaultState } from "../states/DefaultState";
import { DialogSymbol } from "../models/DialogSymbol";
import { Depth } from "../models/Depth";
import BaseScene from "../scenes/BaseScene";

export enum AnimationKey {
  Idle = "idle",
  Walking = "walking",
  Talking = "talking",
  Thinking = "thinking",
  Happy = "happy"
}

export default class Actor extends Phaser.GameObjects.Sprite {
  private bubble?: SpeechBubble;
  private dialogSymbol?: Phaser.GameObjects.Image;
  public lastChoice?: integer;
  public stateMachine?: DefaultState;
  private targetX?: number;
  private speed = 8;
  private onTargetReach?: Function;
  private idleFlipX = false;
  private onCloseDialogFunction?: () => void;

  constructor(scene: Scene, x: number, y: number, name: string, animations: Partial<Record<AnimationKey, number>>) {
    super(scene, x, y, name, AnimationKey.Idle + "_000.png");

    Object.entries(animations).forEach(([key, count]) => {
      if (count <= 1) {
        return;
      }

      const frames = this.anims.generateFrameNames(name, {
        start: 0,
        end: count - 1, zeroPad: 3,
        prefix: key + "_",
        suffix: ".png"
      });

      this.anims.create({key: key, frames: frames, frameRate: 30, repeat: -1});
    });

    this.name = name;
    scene.add.existing(this).setInteractive();
    this.setOrigin(0.5, 1)
    this.setScale(1);
    this.playAnimation(AnimationKey.Idle);
  }

  setIdleFlipX(flipX: boolean): void {
    this.idleFlipX = flipX;
    this.setFlipX(flipX);
  }

  setScale(x: number, y?: number|undefined): this {
    return super.setScale(x * RESIZE_FACTOR);
  }

  playHappyAnimation() {
    this.playAnimation(AnimationKey.Happy);
  }

  preUpdate(time: number, delta: number): void {
    super.preUpdate(time, delta);

    if (this.targetX) {
      const speed = this.speed * this.scale * RENDER_SCALE;
      const distance = this.targetX - this.x;

      if (Math.abs(distance) <= speed) {
        this.x = this.targetX;
        this.stopMoving();
        this.onTargetReach?.();
      } else {
        const isGoingLeft = distance < 0;
        if (isGoingLeft) {
          this.x -= speed;
        } else {
          this.x += speed;
        }
      }
    }
  }

  say(quotes: string[], greyedOut: number[] = [], recipientActor?: Actor, showCloseButton: boolean = false): void {
    this.stopMoving();
    this.hideDialogSymbol();

    this.bubble?.destroy();
    this.scene?.sound.play("bubble_text");
    let tailX = this.x;

    let onClose: Function | undefined;
    if (showCloseButton) {
      onClose = () => {
        this.onCloseDialogFunction?.();
      }
    }

    const bubbleWidth = DEFAULT_WIDTH * 0.4;
    const closeButtonWidth = onClose ? DEFAULT_WIDTH * 0.05 : 0;
    let tip = Tip.None;
    if (this.x < bubbleWidth || (this.x > DEFAULT_WIDTH / 2 && this.x < DEFAULT_WIDTH - bubbleWidth - DEFAULT_WIDTH * 0.05 - closeButtonWidth)) {
      tip = Tip.Left;
      tailX += this.displayWidth / 10;
    } else {
      tip = Tip.Right;
      tailX -= this.displayWidth / 10;
    }

    if (quotes.length == 1) {
      this.playAnimation(AnimationKey.Talking);
    } else {
      switch (tip) {
        case Tip.Left:
          tip = Tip.ThinkingLeft;
          break;
        case Tip.Right:
          tip = Tip.ThinkingRight;
          break;
        default:
          break;
      }
      
      const random = Math.floor(Math.random() * 4) + 1
      this.scene.sound.play("hmm" + random.toString());
      this.playAnimation(AnimationKey.Thinking);
    }

    const tailY = this.y - this.displayHeight;
    this.bubble = new SpeechBubble(this.scene, tailX, tailY, bubbleWidth, tip, this.name, quotes, greyedOut, onClose);
    
    this.bubble.on("pointerup", (n) => {
      if (!greyedOut.includes(n) && quotes.length <= greyedOut.length + 1 && recipientActor) {
        const baseScene = this.scene as BaseScene;
        baseScene.markActorAsCompleted(recipientActor);
      }
      this.lastChoice = n;
      this.stateMachine?.nextState();
    });
  }

  onCloseDialog(fn: () => void): void {
    this.onCloseDialogFunction = fn
  }

  playAnimation(animation: AnimationKey): void {
    this.anims?.play(animation);
  }

  getSpeechBubble(): SpeechBubble | undefined {
    return this.bubble;
  }
  
  removeSpeechBubble(): void {
    this.stopMoving();
    this.bubble?.destroy();
    this.scene.sound.play("bubble_pop");
    this.playAnimation(AnimationKey.Idle);
  }

  showDialogSymbol(symbol: DialogSymbol): void {
    this.dialogSymbol?.destroy();
    this.dialogSymbol = this.scene.add
      .image(0.5, 0, symbol)
      .setDepth(Depth.Actors + 1);

    const side = (this.x < DEFAULT_WIDTH / 2) ? 1 : -1;

    this.dialogSymbol.setPosition(
      this.x + side * this.dialogSymbol.displayWidth,
      this.y - this.displayHeight * 0.9
    );
  }

  hideDialogSymbol(): void {
    this.dialogSymbol?.destroy();
    this.dialogSymbol = undefined;
  }

  moveTo(x: number, onTargetReach?: Function): void {
    this.removeSpeechBubble();
    this.playAnimation(AnimationKey.Walking);
    this.targetX = x;
    this.onTargetReach = onTargetReach;

    const flipX = this.targetX > this.x;
    this.setFlipX(flipX);
  }

  stopMoving(): void {
    this.playAnimation(AnimationKey.Idle);
    this.setFlipX(this.idleFlipX);
    this.targetX = undefined;
  }
}