export enum Depth {
  Background = 0,
  BackgroundElements = 300,
  Actors = 400,
  Foreman = 500,
  Newbie = 501,
  Tutorial = 1000,
  SpeechBubble = 1500,
  Panel = 2000,
  PanelTutorial = 2500,
  UI = 3000
}