export enum Text {
    HK1 = `Ahhh, so this is the heart-kidney link. I've heard about this place!`,
    HK2a = `How do they work together?`,
    HK2b = `What would happen if they stopped cooperating?`,
    HK3a = `The heart pumps blood to the organs, including the kidneys – which clean the blood and filter out waste products. So without the heart, the kidneys wouldn’t receive the blood to filter. And without the kidneys, blood would contain too much waste and water.`,
    HK3b = `Without the help of the kidneys, the heart would be working too hard or would not work at all. The body depends on both organs being healthy and functioning properly.`,
    HK4b = `So each department relies on the other one?`,
    HK5b = `Exactly! A disease in one department makes it more likely for other departments to be affected – a good connection between departments is super important!`,

    HK6a1 = `Hey, just look at this thing! We've got some serious pressure problems here!`,
    HK6a2a = `What happens if the blood flow is reduced?`,
    HK6a2b = `What's causing the problem?`,
    HK6a3a = `If the blood flow's reduced, the kidneys won't be able to function properly, and we'll be in real trouble!`,
    HK6a3b1 = `Both the heart and the kidneys need to be working properly for the body to be in good health – it could be either of them!`,
    HK6a3b2a = `So what happens if the problem is with the heart?`,
    HK6a3b2b = `What kind of problems could result from kidney trouble?`,
    HK6a3b3a = `If there's heart disease, the heart can fill up with blood, increasing pressure in the blood vessel here. That can reduce blood supply to the kidneys, which could lead to kidney disease.`,
    HK6a3b3b = `If kidney function's been affected, the hormone system regulating blood pressure has to work harder to increase blood supply to the kidneys. This makes the heart pump harder, and that can lead to heart disease.`,

    HK7b1 = `Let's get back to the body hub to restore communications!`,
    HK7b2a = `Sounds good to me – let's get back to the elevator.`,
    HK7b2b = `Wait! I've still got a few more questions!`,

    HKCOMM = `Thank you! Communication makes my job a whole lot easier!`,

}
export enum ForemanChoiceState{
    HK2a,
    HK2b,
}
export enum HKEngaChoiceState{
    HK6a2a,
    HK6a2b,
}
export enum HKEngbChoiceState{
    HK6a3b2a,
    HK6a3b2b,
}
export enum FinalChoiceState {
    HK7b2a,
    HK7b2b,


}