import Foreman from "../../characters/Foreman";
import Newbie from "../../characters/Newbie";
import { DEFAULT_HEIGHT, DEFAULT_WIDTH, RESIZE_FACTOR } from "../../globals";
import { Floor } from "../../models/Floor";
import { GameScene } from "../../models/GameScene";
import Engineer from "../../characters/Engineer";
import { EngineerType } from "../../models/EngineerType";
import KidneysState from "../../states/KidneysState";
import BaseScene from "../BaseScene";
import { TutorialElement } from "../../models/TutorialElement";

export default class KidneysScene extends BaseScene {
  private foreman!: Foreman;
  private newbie!: Newbie;
  private engineer1!: Engineer;
  private engineer2!: Engineer;
  private engineer3!: Engineer;
  private ambientSound!: Phaser.Sound.BaseSound;
  private neutralSound!: Phaser.Sound.BaseSound;
  private actorCount = 3;

  constructor() {
    super(GameScene.Kidneys);
  }

  preload(): void {
    this.load.maxParallelDownloads = 1;
    this.loadVideo("kidneys", "background/kidneys.mp4");
  }

  initConversations(): void {
    //first intro state machine
    this.stateMachine = new KidneysState(this.newbie, this.foreman, this.engineer1, this.engineer2, this.engineer3);
    this.setupStateMachine();
    
    this.stateMachine.onFinishHandler = () => this.goToElevator();
    this.stateMachine.onConversationStartHandler = () => this.setMenuOpen(false);
    this.stateMachine.onConversationEndHandler = () => {
      this.skipButton.setVisible(false);
      this.showTutorial();
      this.updateSymbols();
      
      const completedActorsCount = this.store.getCompletedActors(this.getCurrentGameScene()).length;
      if (this.actorCount === completedActorsCount && (!this.store.sceneIsVisited(GameScene.KidneysHeartLink) || !this.store.sceneIsVisited(GameScene.PancreasKidneysLink))) {
        this.skipButton.setVisible(true);
        this.stateMachine.setState('SPEAK_FOREMAN');
      }
    }

    this.stateMachine.hideSkipButton = () => this.skipButton.setVisible(false);
    this.stateMachine.onSkip = () => {
      this.showTutorial();
      this.updateSymbols();
    }

    this.foreman.stateMachine = this.stateMachine;
    this.newbie.stateMachine = this.stateMachine;
    this.engineer1.stateMachine = this.stateMachine;
    this.engineer2.stateMachine = this.stateMachine;
    this.engineer3.stateMachine = this.stateMachine;

    this.foreman.getSpeechBubble()?.on("pointerup", () => this.stateMachine.nextState());
    this.newbie.getSpeechBubble()?.on("pointerup", () => this.stateMachine.nextState());
    this.engineer1.getSpeechBubble()?.on("pointerup", () => this.stateMachine.nextState());
    this.engineer2.getSpeechBubble()?.on("pointerup", () => this.stateMachine.nextState());
    this.engineer3.getSpeechBubble()?.on("pointerup", () => this.stateMachine.nextState());
    
    this.newbie.on("pointerup", () => {
      this.skipButton.setVisible(true);
      this.stateMachine.nextState()
    });

    this.updateSymbols();
  }

  create(): void {
    this.fadeIn();

    this.add.video(0, 0, "kidneys")
      .play(true)
      .setPaused(false)
      .setScale(RESIZE_FACTOR)
      .setLoop(true)
      .setOrigin(0, 0);

    this.configureUI();

    if (this.store.isLinkFixedNoticed(GameScene.KidneysHeartLink) && this.store.isLinkFixedNoticed(GameScene.PancreasKidneysLink)) {
      this.setPhoneSign(DEFAULT_WIDTH * 0.4);
    }

    this.ambientSound = this.sound.add("kidneys", { loop: true });
    this.ambientSound.play();

    this.neutralSound = this.sound.add("neutral", { loop: true });
    this.neutralSound.play();

    const floorY = DEFAULT_HEIGHT * 0.96;
    this.foreman = new Foreman(this, DEFAULT_WIDTH * 0.30, floorY);
    this.foreman.setScale(0.8);
    this.newbie = new Newbie(this, DEFAULT_WIDTH * 0.52, floorY);
    this.newbie.setScale(0.8);

    this.engineer1 = new Engineer(this, DEFAULT_WIDTH * 0.82, DEFAULT_HEIGHT * 0.4, EngineerType.Kidneys, 1);
    this.engineer2 = new Engineer(this, DEFAULT_WIDTH * 0.245, DEFAULT_HEIGHT * 0.4, EngineerType.Kidneys, 2);
    this.engineer3 = new Engineer(this, DEFAULT_WIDTH * 0.73, floorY, EngineerType.Kidneys, 3);

    this.actors = [this.engineer1, this.engineer2, this.engineer3, this.newbie, this.foreman];

    const fromLeft = this.getLastScene() !== GameScene.PancreasKidneysLink
    this.walkActors([this.foreman, this.newbie], fromLeft);

    this.initConversations();
    
    setTimeout(() => {
      if (!this.store.sceneIsVisited(GameScene.Kidneys)) {
        this.skipButton.setVisible(true);
        this.stateMachine.nextState();
      }
    }, 1000);

    this.foreman
      .setInteractive()
      .on("pointerup", () => {
        this.skipButton.setVisible(true);
        this.playForemanFinishSceneDialog();
      });

    this.engineer1
      .setInteractive()
      .on("pointerup", () => {
        this.setTutorialFinished(TutorialElement.Characters);
        this.skipButton.setVisible(true);
        this.stateMachine.setState('SPEAK_ENG1');
        this.skipDialogIfNeeded(this.engineer1);
        this.markActorAsInteracted(this.engineer1);
      });

    this.engineer2
      .setInteractive()
      .on("pointerup", () => {
        this.setTutorialFinished(TutorialElement.Characters);
        this.skipButton.setVisible(true);
        this.stateMachine.setState('SPEAK_ENG2');
        this.skipDialogIfNeeded(this.engineer2);
        this.markActorAsInteracted(this.engineer2);
      });

    this.engineer3
      .setInteractive()
      .on("pointerup", () => {
        this.setTutorialFinished(TutorialElement.Characters);
        this.skipButton.setVisible(true);
        this.stateMachine.setState('SPEAK_ENG3');
        this.skipDialogIfNeeded(this.engineer3);
        this.markActorAsInteracted(this.engineer3);
      });

    this.add
      .rectangle(0.0, DEFAULT_HEIGHT * 0.47, DEFAULT_WIDTH * 0.12, DEFAULT_HEIGHT * 0.53)
      .setOrigin(0, 0)
      .setInteractive()
      .on("pointerup", () => {
        this.setTutorialFinished(TutorialElement.Tunnels);

        const destinationX = DEFAULT_WIDTH * 0.1;
        this.newbie.moveTo(destinationX);
        this.foreman.moveTo(destinationX);
        this.startScene(GameScene.KidneysHeartLink);
      });

    this.add
      .rectangle(DEFAULT_WIDTH * 0.88, DEFAULT_HEIGHT * 0.47, DEFAULT_WIDTH * 0.12, DEFAULT_HEIGHT * 0.53)
      .setOrigin(0, 0)
      .setInteractive()
      .on("pointerup", () => {
        this.setTutorialFinished(TutorialElement.Tunnels);

        const destinationX = DEFAULT_WIDTH * 0.9;
        this.newbie.moveTo(destinationX);
        this.foreman.moveTo(destinationX);
        this.startScene(GameScene.PancreasKidneysLink);
      });

    this.showTutorial();

    this.newbie.onCloseDialog(() => this.skipDialog());
    this.events.on("shutdown", () => this.onShutDown());
  }

  updateSymbols(): void {
    this.updateEngineersSymbols([this.engineer1, this.engineer2, this.engineer3], false);
  }

  goToElevator(): void {
    this.startScene(GameScene.Elevator, { currentFloor: Floor.Kidneys })
  }

  onShutDown(): void {
    this.ambientSound.stop();
    this.neutralSound.stop();
  }

}
