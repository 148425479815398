import * as Phaser from "phaser";
import { ASSET_SIZE } from "../globals";
import { GameScene } from "../models/GameScene";

export default class BootScene extends Phaser.Scene {
  constructor() {
    super(GameScene.Boot);
  }

  preload(): void {
    this.load.image("title_background", ASSET_SIZE + "/background/title.png");
    this.load.image("loading_bar_frame", ASSET_SIZE + "/ui/loading_bar/frame.png");
    this.load.image("loading_bar_fill", ASSET_SIZE + "/ui/loading_bar/fill.png");
  }

  create(): void {
    this.scene.start(GameScene.Loading);
  }
}
