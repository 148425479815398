export enum Text {
    KP1 = `Wow! The kidney-pancreas connection! It's been a while since I've ventured down here!`,
    KP2 = `I'm glad you're here, Newbie! We could have a diabetes disaster on our hands any moment!`,
    KP3a = `Diabetes? What's that?`,
    KP3b = `How does diabetes affect the kidneys?`,
    KP3c = `If we get diabetes, does that mean we'll get kidney disease?`,
    KP4a = `Diabetes is the leading cause of kidney disease with about 1 in 3 adults with diabetes developing the disease.`,
    KP4b1 = `Type 2 diabetes damages the blood vessels in the kidneys, so they can't filter out water and waste products from the blood as effectively. This damage leads to kidney disease – and ultimately kidney failure.`,
    KP4b2 = `People with diabetes are more likely to develop kidney disease if they smoke, eat foods high in salt, are inactive or overweight, or if they have heart disease or a family history of kidney failure.`,
    KP5b = `Sounds serious! But what about the pancreas?`,
    KP6b = `Good point! It's important to remember that the organs are inextricably linked – so a disease in one department increases the chance of the other system being affected – and making the disease worse.`,


    KP7a1 = `Just look at the state of these pipes! This is not good!`,
    KP7a2a = `Uhhh, should those pipes be leaking?`,
    KP7a2b = `What do you do here?`,
    KP7a3a = `No! High blood sugar levels can damage the blood vessels and filters in the kidneys, which can cause them to leak and not work as well.`,
    KP7a3b = `Excess sugar in the bloodstream can also cause the kidneys to filter too much blood. This causes abnormal amounts of waste material from the blood which leaves body through urine.`,
    KP7a4a = `What does this mean for the kidneys?`,
    KP7a4b = `Can this be a sign of other diseases?`,
    KP7a5a = `Over time, this extra work puts more pressure on the kidneys.`,
    KP7a5b = `Damage from excess sugar in the blood is often an early sign of diabetic kidney disease.`,


    KP7b1 = `Diabetes is no joke, Newbie! It can have a huge effect on the blood vessels and the nerves, and can even impact blood pressure!`,
    KP7b2a = `Tell me more about how diabetes affects the blood vessels.`,
    KP7b2b = `What effect can diabetes have on the nerves?`,
    KP7b2c = `Blood pressure? How does diabetes affect blood pressure?`,
    KP7b3a1 = `With diabetes, the small blood vessels in the body are injured, and the kidneys cannot clean the blood properly.`,
    KP7b3a2 = `Because of this, the body retains more water and salt than it should – which can cause weight gain and ankle swelling.`,
    KP7b3b = `Diabetes can also damage nerves in the body, which can make it more difficult to empty the bladder – and the pressure from a full bladder can injure the kidneys.`,
    KP7b3c1 = `People with diabetes often develop kidney disease, which increases the risk for high blood pressure.`,
    KP7b3c2 = `High blood pressure can constrict and narrow the blood vessels, which eventually damages and weakens them throughout the body, including in the kidneys.`,

    KP8c1 = `Let's get back to the body hub to restore communications!`,
    KP8c2a = `Sounds good to me – let's get back to the elevator.`,
    KP8c2b = `Wait! I've still got a few more questions!`,

    KPCOMM = `Thank you! Communication makes my job a whole lot easier!`,
}
export enum Eng1aChoiceState{
    KP3a,
    KP3b,
    KP3c,
}
export enum Eng2aChoiceState{
    KP7a2a,
    KP7a2b,
}
export enum Eng2bChoiceState{
    KP7a4a,
    KP7a4b,
}
export enum Eng1bChoiceState{
    KP7b2a,
    KP7b2b,
    KP7b2c,
}
export enum FinalChoiceState {
    KP8c2a,
    KP8c2b,

}