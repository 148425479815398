import { Scene } from "phaser";
import { RENDER_SCALE } from "../globals";
import { Depth } from "../models/Depth";
import BBCodeText from 'phaser3-rex-plugins/plugins/bbcodetext'

export enum Tip {
  None,
  Right,
  Left,
  ThinkingRight,
  ThinkingLeft
}

export default class Bubble {
  private scene: Scene;
  private closeButton?: Phaser.GameObjects.Image;
  private images: Phaser.GameObjects.Image[];
  private texts: BBCodeText[];
  private bubbleHeights: number[];
  private sceneInteractiveRectangle: Phaser.GameObjects.Rectangle;
  private interactiveRectangles: Phaser.GameObjects.Rectangle[];
  private textureKey: string;
  private showTailOnTop = false;
  private onFunctions: { [event: string | symbol]: Function } = {};

  constructor(scene: Scene, tailX: number, tailY: number, width: number, tip: Tip, name: string, quotes: string[], grayedOut: number[], onClose?: Function) {
    this.scene = scene;
    this.images = [];
    this.texts = [];
    this.bubbleHeights = [];
    this.interactiveRectangles = [];
    this.textureKey = "bubble-" + name;

    const gameSize = scene?.sys.game.canvas;

    const radius = 80 * RENDER_SCALE;
    let height = 0;
    let summedHeight = 0;

    for (var i = 0; i < quotes.length; i++) {
      let beggining = 0;
      let end = 0;
      if (quotes[i].indexOf('|') !== -1) {
        beggining = quotes[i].indexOf('|');
        quotes[i].replace('|', '');
        end = quotes[i].indexOf('|');
        quotes[i].replace('|', '');
      }

      const IsURLKey = function (key: string) {
        return (key.substring(0, 4) === 'url:');
      }

      const GetURL = function (key: string) {
        return key.substring(4, key.length);
      }

      let text = new BBCodeText(scene, 0, 0, quotes[i], {
        fontFamily: "Quicksand",
        fontSize: (60 * RENDER_SCALE) + "px",
        color: "#ffffff",
        align: "center",
        wrap: {
          mode: 'word',
          width: width - 60 * RENDER_SCALE
        }
      });

      scene.add.existing(text);
      if (quotes[i].includes('url:')) {
        text.setInteractive()
          .on('areaup', (key: string) => {
            if (IsURLKey(key)) {
              window.open(GetURL(key), '_blank');
            }
          })
          .on('pointerup', () => {
            this.onFunctions['pointerup']();
          });
      }
      text.setDepth(Depth.SpeechBubble + 2);
      this.texts.push(text);

      let bounds = text.getBounds();
      let bubbleHeight = Math.max(bounds.height + 100 * RENDER_SCALE, radius * 3);
      summedHeight += bubbleHeight + (10 * RENDER_SCALE);
      this.bubbleHeights.push(bubbleHeight);
    }

    const tailHeight = 260 * RENDER_SCALE;
    let y = tailY - summedHeight - tailHeight / 30;

    if (y < 0) {
      y = tailY + tailHeight;
      this.showTailOnTop = true;
    }

    const offsetX = this.showTailOnTop ? 140 * RENDER_SCALE : 0;
    let x = 0;
    switch (tip) {
      case Tip.Left:
      case Tip.ThinkingLeft:
        x = Math.min(gameSize.width, tailX - radius + offsetX);
        break;
      default:
        x = Math.max(0, tailX - width + radius - offsetX);
        break;
    }

    for (var i = 0; i < this.texts.length; i++) {
      const isGrayedOut = grayedOut.includes(i);

      let currentTextureKey = this.textureKey + i.toString()
      if (scene.textures.exists(currentTextureKey)) {
        scene.textures.remove(currentTextureKey);
      }

      let texture = scene.textures.createCanvas(currentTextureKey, gameSize.width, gameSize.height);
      let context = texture.context;

      let bounds = this.texts[i].getBounds();
      let bubbleHeight = this.bubbleHeights[i];
      this.texts[i].setPosition(x + width / 2 - bounds.width / 2, (y + height + bubbleHeight / 2) - bounds.height / 2);

      context.beginPath();
      context.strokeStyle = isGrayedOut ? "#0e3956" : "#071e5b";
      context.lineWidth = 10 * RENDER_SCALE;

      let currentTip = Tip.None;
      if (i == quotes.length - 1) {
        currentTip = tip;
      }
      this.defineBubble(context, x, y + height, width, bubbleHeight, currentTip, radius);
      context.closePath();

      context.shadowColor = "black";
      context.shadowBlur = 20 * RENDER_SCALE;
      context.stroke();

      context.shadowColor = "rgba(0,0,0,0)";
      context.globalCompositeOperation = "destination-in";
      context.fill();

      let gradient = context.createLinearGradient(x, y + height, x, y + height + bubbleHeight);
      if (isGrayedOut) {
        gradient.addColorStop(0, "#294296");
        gradient.addColorStop(0.37, "#203c66");
        gradient.addColorStop(1, "#0e3956");
      } else {
        gradient.addColorStop(0, "#1734f0");
        gradient.addColorStop(0.37, "#252cb4");
        gradient.addColorStop(1, "#008bb4");
      }

      context.globalCompositeOperation = "destination-over";
      context.fillStyle = gradient;
      context.fill();

      context.globalCompositeOperation = "source-over";

      texture.refresh();

      let image = scene.add.image(0, 0, currentTextureKey)
        .setOrigin(0, 0)
        .setDepth(Depth.SpeechBubble);
      this.images.push(image);

      let interactiveRectangle = scene.add.rectangle(x, y + height, width, bubbleHeight)
        .setOrigin(0, 0)
        .setDepth(Depth.SpeechBubble)
        .setInteractive();
      this.interactiveRectangles.push(interactiveRectangle);

      height += bubbleHeight + 10 * RENDER_SCALE;
    }

    this.sceneInteractiveRectangle = scene.add.rectangle(0, 0, gameSize.width, gameSize.height)
      .setOrigin(0, 0)
      .setDepth(Depth.SpeechBubble - 1)
      .setInteractive();

    if (onClose) {
      this.closeButton = scene.add
        .image(0, 0, "dialog_close")
        .setDepth(Depth.SpeechBubble + 1)
        .setInteractive()
        .on("pointerup", () => {
          onClose?.();
        });

      const closeButtonY = y - this.closeButton.displayHeight / 3;
      if (closeButtonY <= this.closeButton.displayHeight * 0.6) {
        this.closeButton.setPosition(x + width + this.closeButton.displayWidth * 0.6, this.closeButton.displayHeight * 0.6);
      } else {
        this.closeButton.setPosition(x + width + this.closeButton.displayWidth / 3, closeButtonY);
      }
    }
  }

  on(event: string | symbol, fn: (n: number) => void, context?: any): void {
    this.onFunctions[event] = () => { fn(0); }
    
    this.sceneInteractiveRectangle.on(event, () => {
      if (this.interactiveRectangles.length == 1) {
        fn(0);
      }
    }, context);

    for (var i = 0; i < this.interactiveRectangles.length; i++) {
      let number = i;
      this.interactiveRectangles[i].on(event, () => {
        fn(number);
      }, context);
    }
  }

  defineBubble(context: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, tip: Tip, radius: number): void {
    const r = x + width;
    const b = y + height;

    context.moveTo(r - radius, b);

    if (this.showTailOnTop) {
      context.lineTo(x + radius, b);
      context.quadraticCurveTo(x, b, x, b - radius);
      context.lineTo(x, y + radius);
      context.quadraticCurveTo(x, y, x + radius, y);

      let arrowX = 0;
      const arrowY = y - 80 * RENDER_SCALE;
      const curveY = (arrowY - y) / 6;

      switch (tip) {
        case Tip.Right:
          context.lineTo(r - radius - 110 * RENDER_SCALE, y);
          arrowX = r - radius + 10 * RENDER_SCALE;
          context.quadraticCurveTo(r - radius - 90 * RENDER_SCALE, y + curveY * 3, arrowX, arrowY);
          context.quadraticCurveTo(r - radius - 20 * RENDER_SCALE, y + curveY, r - radius, y);
          break;
        case Tip.Left:
          context.lineTo(x + radius, y);
          arrowX = x + radius - 10 * RENDER_SCALE;
          context.quadraticCurveTo(x + radius + 20 * RENDER_SCALE, y + curveY, arrowX, arrowY);
          context.quadraticCurveTo(x + radius + 90 * RENDER_SCALE, y + curveY * 3, x + radius + 110 * RENDER_SCALE, y);
          break;
        default:
          break;
      }

      context.lineTo(r - radius, y);
      context.quadraticCurveTo(r, y, r, y + radius);
      context.lineTo(r, y + height - radius);
      context.quadraticCurveTo(r, b, r - radius, b);
    } else {
      let pointX = 0;
      let pointY = b + 50 * RENDER_SCALE;
      let size = 40 * RENDER_SCALE;

      let arrowX = 0;
      const arrowY = b + 80 * RENDER_SCALE;
      const curveY = (arrowY - b) / 6;
      const doublePI = Math.PI * 2;

      switch (tip) {
        case Tip.Right:
          context.lineTo(r - radius - 100 * RENDER_SCALE, b);
          arrowX = r - radius - 90 * RENDER_SCALE;
          context.quadraticCurveTo(r - radius - 120 * RENDER_SCALE, b + curveY, arrowX, arrowY);
          context.quadraticCurveTo(r - radius - 190 * RENDER_SCALE, b + curveY * 3, r - radius - 210 * RENDER_SCALE, b);
          break;
        case Tip.Left:
          context.lineTo(x + radius + 210 * RENDER_SCALE, b);
          arrowX = x + radius + 90 * RENDER_SCALE;
          context.quadraticCurveTo(x + radius + 190 * RENDER_SCALE, b + curveY * 3, arrowX, arrowY);
          context.quadraticCurveTo(x + radius + 120 * RENDER_SCALE, b + curveY, x + radius + 100 * RENDER_SCALE, b);
          break;
        case Tip.ThinkingRight:
          pointX = r - radius - 130 * RENDER_SCALE;

          for (var i = 0; i < 3; i++) {
            context.moveTo(pointX, pointY);
            context.arc(pointX - size, pointY, size, 0, doublePI);

            pointX += size * 0.8;
            pointY += size + context.lineWidth;
            size /= 2;
          }

          context.moveTo(r - radius, b);
          break;
        case Tip.ThinkingLeft:
          pointX = x + radius + 210 * RENDER_SCALE;

          for (var i = 0; i < 3; i++) {
            context.moveTo(pointX, pointY);
            context.arc(pointX - size, pointY, size, 0, doublePI);

            pointX -= (size * 2 + context.lineWidth) * 0.8;
            pointY += size + context.lineWidth;
            size /= 2;
          }

          context.moveTo(r - radius, b);
          break;
        default:
          break;
      }

      context.lineTo(x + radius, b);
      context.quadraticCurveTo(x, b, x, b - radius);
      context.lineTo(x, y + radius);
      context.quadraticCurveTo(x, y, x + radius, y);
      context.lineTo(r - radius, y);
      context.quadraticCurveTo(r, y, r, y + radius);
      context.lineTo(r, y + height - radius);
      context.quadraticCurveTo(r, b, r - radius, b);
    }
  }

  destroy(): void {
    this.closeButton?.destroy(true);
    this.sceneInteractiveRectangle.destroy(true);
    for (var i = 0; i < this.texts.length; i++) {
      let currentTextureKey = this.textureKey + i.toString()
      if (this.scene.textures.exists(currentTextureKey)) {
        this.scene.textures.remove(currentTextureKey);
        this.images[i].destroy(true);
        this.texts[i].destroy(true);
        this.interactiveRectangles[i].destroy(true);
      }
    }

    this.images = [];
    this.texts = [];
    this.interactiveRectangles = [];
  }
}