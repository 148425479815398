import Foreman from "../../characters/Foreman";
import { DEFAULT_HEIGHT, DEFAULT_WIDTH, RESIZE_FACTOR } from "../../globals";
import { Floor } from "../../models/Floor";
import HeartState from "../../states/HeartState";
import Newbie from "../../characters/Newbie";
import { GameScene } from "../../models/GameScene";
import Engineer from "../../characters/Engineer";
import { EngineerType } from "../../models/EngineerType";
import BaseScene from "../BaseScene";
import { TutorialElement } from "../../models/TutorialElement";

export default class HeartScene extends BaseScene {
  private foreman!: Foreman
  private newbie!: Newbie
  private engineer1!: Engineer;
  private engineer2!: Engineer;
  private engineer3!: Engineer;
  private ambientSound!: Phaser.Sound.BaseSound;
  private neutralSound!: Phaser.Sound.BaseSound;
  private actorCount = 3;

  constructor() {
    super(GameScene.Heart);
  }

  preload(): void {
    this.load.maxParallelDownloads = 1;
    this.loadVideo("heart", "background/heart.mp4");
  }

  initConversations(): void {
    //first intro state machine
    this.stateMachine = new HeartState(this.newbie, this.foreman, this.engineer1, this.engineer2, this.engineer3);
    this.setupStateMachine();

    this.stateMachine.onFinishHandler = () => this.goToElevator();
    this.stateMachine.onConversationStartHandler = () => this.setMenuOpen(false);
    this.stateMachine.onConversationEndHandler = () => {
      this.skipButton.setVisible(false);
      this.showTutorial();
      this.updateSymbols();

      const completedActorsCount = this.store.getCompletedActors(this.getCurrentGameScene()).length;
      if (this.actorCount === completedActorsCount && (!this.store.sceneIsVisited(GameScene.KidneysHeartLink) || !this.store.sceneIsVisited(GameScene.HeartPancreasLink))) {
        this.skipButton.setVisible(true);
        this.stateMachine.setState('SPEAK_FOREMAN');
      }
    }

    this.stateMachine.hideSkipButton = () => this.skipButton.setVisible(false);
    this.stateMachine.onSkip = () => {
      this.showTutorial();
      this.updateSymbols();
    }
    
    this.engineer1.stateMachine = this.stateMachine;
    this.engineer2.stateMachine = this.stateMachine;
    this.engineer3.stateMachine = this.stateMachine;
    this.foreman.stateMachine = this.stateMachine;
    this.newbie.stateMachine = this.stateMachine;

    this.newbie.on("pointerup", () => {
      this.skipButton.setVisible(true);
      this.stateMachine.nextState()
    });

    this.updateSymbols();
  }

  create(): void {
    this.fadeIn();

    this.add.video(0, 0, "heart")
      .play(true)
      .setPaused(false)
      .setScale(RESIZE_FACTOR)
      .setLoop(true)
      .setOrigin(0, 0);

    if (this.store.isLinkFixedNoticed(GameScene.KidneysHeartLink) && this.store.isLinkFixedNoticed(GameScene.HeartPancreasLink)) {
      this.setPhoneSign(DEFAULT_WIDTH * 0.4);
    }
    this.configureUI();

    this.ambientSound = this.sound.add("heart", { loop: true });
    this.ambientSound.play();

    this.neutralSound = this.sound.add("neutral", { loop: true });
    this.neutralSound.play();

    const floorY = DEFAULT_HEIGHT * 0.96;
    this.foreman = new Foreman(this, DEFAULT_WIDTH * 0.73, floorY);
    this.foreman.setScale(0.8);
    this.newbie = new Newbie(this, DEFAULT_WIDTH * 0.52, floorY);
    this.newbie.setScale(0.8);

    this.engineer1 = new Engineer(this, DEFAULT_WIDTH * 0.268, DEFAULT_HEIGHT * 0.5, EngineerType.Heart, 1);
    this.engineer2 = new Engineer(this, DEFAULT_WIDTH * 0.275, floorY, EngineerType.Heart, 2);
    this.engineer3 = new Engineer(this, DEFAULT_WIDTH * 0.78, DEFAULT_HEIGHT * 0.46, EngineerType.Heart, 3);

    this.actors = [this.engineer1, this.engineer2, this.engineer3, this.newbie, this.foreman];

    const fromLeft = this.getLastScene() !== GameScene.KidneysHeartLink
    this.walkActors([this.foreman, this.newbie], fromLeft);

    this.initConversations();

    setTimeout(() => {
      if (!this.store.sceneIsVisited(GameScene.Heart)) {
        this.skipButton.setVisible(true);
        this.stateMachine.nextState();
      }
      
      this.add
        .rectangle(DEFAULT_WIDTH * 0.1, DEFAULT_HEIGHT * 0.48, DEFAULT_WIDTH * 0.14, DEFAULT_HEIGHT * 0.26)
        .setOrigin(0, 0)
        .setInteractive()
        .on("pointerup", () => {
          this.setTutorialFinished(TutorialElement.Panels);
          this.showPanel("heart_attack_panel", () => this.showTutorial(), () => this.showTutorial());
        });

      this.add
        .rectangle(DEFAULT_WIDTH * 0.7, DEFAULT_HEIGHT * 0.47, DEFAULT_WIDTH * 0.18, DEFAULT_HEIGHT * 0.28)
        .setOrigin(0, 0)
        .setInteractive()
        .on("pointerup", () => {
          this.setTutorialFinished(TutorialElement.Panels);
          this.showPanels("heart_failure_panel", 7, () => this.showTutorial());
        });
    }, 1000);

    this.foreman
      .setInteractive()
      .on("pointerup", () => {
        this.skipButton.setVisible(true);
        this.playForemanFinishSceneDialog();
      });

    this.engineer1
      .setInteractive()
      .on("pointerup", () => {
        this.setTutorialFinished(TutorialElement.Characters);
        this.skipButton.setVisible(true);
        this.stateMachine.setState('SPEAK_ENG1');
        this.skipDialogIfNeeded(this.engineer1);
        this.markActorAsInteracted(this.engineer1);
      });

    this.engineer2
      .setInteractive()
      .on("pointerup", () => {
        this.setTutorialFinished(TutorialElement.Characters);
        this.skipButton.setVisible(true);
        this.stateMachine.setState('SPEAK_ENG2');
        this.skipDialogIfNeeded(this.engineer2);
        this.markActorAsInteracted(this.engineer2);
      });

    this.engineer3
      .setInteractive()
      .on("pointerup", () => {
        this.setTutorialFinished(TutorialElement.Characters);
        this.skipButton.setVisible(true);
        this.stateMachine.setState('SPEAK_ENG3');
        this.skipDialogIfNeeded(this.engineer3, 3);
        this.markActorAsInteracted(this.engineer3);
      });

    this.add
      .rectangle(0.0, DEFAULT_HEIGHT * 0.47, DEFAULT_WIDTH * 0.12, DEFAULT_HEIGHT * 0.53)
      .setOrigin(0, 0)
      .setInteractive()
      .on("pointerup", () => {
        this.setTutorialFinished(TutorialElement.Tunnels);

        const destinationX = DEFAULT_WIDTH * 0.1;
        this.newbie.moveTo(destinationX);
        this.foreman.moveTo(destinationX);
        this.startScene(GameScene.HeartPancreasLink);
      });

    this.add
      .rectangle(DEFAULT_WIDTH * 0.88, DEFAULT_HEIGHT * 0.47, DEFAULT_WIDTH * 0.12, DEFAULT_HEIGHT * 0.53)
      .setOrigin(0, 0)
      .setInteractive()
      .on("pointerup", () => {
        this.setTutorialFinished(TutorialElement.Tunnels);

        const destinationX = DEFAULT_WIDTH * 0.9;
        this.newbie.moveTo(destinationX);
        this.foreman.moveTo(destinationX);
        this.startScene(GameScene.KidneysHeartLink);
      });

    this.showTutorial();

    this.newbie.onCloseDialog(() => this.skipDialog());
    this.events.on("shutdown", () => this.onShutDown());
  }

  updateSymbols(): void {
    this.updateEngineersSymbols([this.engineer1, this.engineer2, this.engineer3], false);
  }

  goToElevator(): void {
    this.startScene(GameScene.Elevator, { currentFloor: Floor.Heart })
  }

  onShutDown(): void {
    this.ambientSound.stop();
    this.neutralSound.stop();
  }

}
