import { createMachine, interpret, assign, StateMachine } from 'xstate';
import { Text, Eng2ChoiceState, Eng3ChoiceState, FinalChoiceState } from "../texts/pancreas"
import Actor from "../characters/Actor";
import Newbie from "../characters/Newbie";
import Foreman from "../characters/Foreman";
import Engineer from "../characters/Engineer";
import { StateContext } from './StateContext';
import { StateEvent } from './StateEvent';
import BaseState from "./BaseState";

export default class PancreasState extends BaseState {
    newbie: Newbie;
    foreman: Foreman;
    eng1: Engineer;
    eng2: Engineer;
    eng3: Engineer;

    constructor(newbie: Newbie, foreman: Foreman, eng1: Engineer, eng2: Engineer, eng3: Engineer) {
        super();
        this.newbie = newbie;
        this.foreman = foreman;
        this.eng1 = eng1;
        this.eng2 = eng2;
        this.eng3 = eng3;

        this.stateMachine = createMachine<StateContext, StateEvent>({
            id: "speech",
            initial: "P0", //get from state later
            context: {
                actor: undefined
            },
            states: {
                P0: {
                    on: {
                        SPEAK: {
                            target: "P1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                        SPEAK_ENG2: {
                            target: "P5a1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng2
                                })
                            ]
                        },
                        SPEAK_ENG3: {
                            target: "P5c1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.eng3
                                })
                            ]
                        },

                        SPEAK_FOREMAN: {
                            target: "P6a1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                        SPEAK_FINISH: {
                            target: "P7a1",
                            actions: [
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        },
                    }
                },
                P1: {
                    on: {
                        SPEAK: {
                            target: "P2",
                            actions: [
                                (context) => this.foreman.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.eng1
                                })
                            ]
                        }
                    }
                },
                P2: {
                    on: {
                        SPEAK: [{
                            target: "P3",
                            actions: [
                                (context) => this.eng1.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.newbie
                                })
                            ],
                        },

                        ]
                    }

                },
                P3: {
                    on: {
                        SPEAK: {
                            target: "P4",
                            actions: [
                                (context) => this.newbie.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.eng1
                                })
                            ]
                        }
                    }
                },
                P4: {
                    on: {
                        SPEAK: {
                            target: "P0",
                            actions: [
                                (context) => {
                                    this.eng1.removeSpeechBubble();

                                    this.onConversationEndHandler?.();
                                },
                                assign({
                                    actor: (context, event) => this.foreman
                                })
                            ]
                        }
                    }
                },
                P5a1: {
                    on: {
                        SPEAK: {
                            target: "P5a2",
                            actions: [
                                assign({
                                    actor: (context, event) => this.newbie
                                }),
                                (context, event) => this.eng2.removeSpeechBubble(),

                            ],
                        }
                    }
                },
                P5a2: {
                    on: {
                        SPEAK: [{
                            target: "P5a3",
                            actions: [
                                (context, event) => this.newbie.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.eng2
                                })
                            ]
                        }
                        ]
                    }
                },
                P5a3: {
                    on: {
                        SPEAK: {
                            target: "P5a4",
                            actions: (context, event, actionMeta) => {
                                assign({
                                    actor: (context, event) => this.newbie
                                });
                                this.eng1.removeSpeechBubble();
                                this.eng2.removeSpeechBubble();
                                this.sayFromChoices(Eng2ChoiceState, Text, 'P5a4', actionMeta, this.newbie, this.eng2);
                            },
                        }
                    }
                },
                P5a4: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "P5a5a",
                            actions: [
                                (context, event) => this.newbie.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.eng2
                                })
                            ],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }
                        },
                        {
                            target: "P5a5b",
                            actions: [
                                (context, event) => this.newbie.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.eng2
                                })
                            ],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }
                        },
                        {
                            target: "P5a5c",
                            actions: [
                                (context, event) => this.newbie.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.eng2
                                })
                            ],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 2;
                            }
                        }, {
                            target: "P5a5d1",
                            actions: [
                                (context, event) => this.newbie.removeSpeechBubble(),
                                assign({
                                    actor: (context, event) => this.eng2
                                })
                            ],
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 3;
                            }
                        }]
                    }
                },
                P5a5a: {
                    on: {
                        SPEAK: [{
                            target: "P0",
                            actions: (context, event) => {
                                this.eng2.removeSpeechBubble();

                                this.onConversationEndHandler?.();

                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            },

                        }]
                    }
                },
                P5a5b: {
                    on: {
                        SPEAK: [{
                            target: "P0",
                            actions: (context, event) => {
                                this.eng2.removeSpeechBubble();

                                this.onConversationEndHandler?.();

                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            },

                        }]
                    }
                },
                P5a5c: {
                    on: {
                        SPEAK: [{
                            target: "P0",
                            actions: (context, event) => {
                                this.eng2.removeSpeechBubble();

                                this.onConversationEndHandler?.();

                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            },

                        }]
                    }
                }, P5a5d1: {
                    on: {
                        SPEAK: [{
                            target: "P0",
                            actions: (context, event) => {
                                this.eng2.removeSpeechBubble();

                                this.onConversationEndHandler?.();

                                assign({
                                    actor: (context, event) => this.foreman
                                });
                            },

                        }]
                    }
                },
                P5c1: {
                    on: {
                        SPEAK: {
                            target: "P5c2",
                            actions: (context, event, actionMeta) => {
                                assign({
                                    actor: (context, event) => this.newbie
                                });
                                this.eng3.removeSpeechBubble();
                                this.sayFromChoices(Eng3ChoiceState, Text, 'P5c2', actionMeta, this.newbie, this.eng3);
                            },
                        }
                    }
                },
                P5c2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "P5c3a",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng3
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }
                        }, {
                            target: "P5c5b",
                            actions: (context, event) => {
                                this.newbie.removeSpeechBubble();
                                assign({
                                    actor: (context, event) => this.eng3
                                });
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }
                        }]
                    }
                },
                P5c3a: {
                    on: {
                        SPEAK: {
                            target: "P0",
                            actions: (context, event) => {
                                this.eng3.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },
                P5c5b: {
                    on: {
                        SPEAK: {
                            target: "P0",
                            actions: (context, event) => {
                                this.eng3.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                        }
                    }
                },

                P6a1: {
                    on: {
                        SPEAK: {
                            target: "P0",
                            actions: (context, event) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.hideSkipButton?.();
                            },
                        }
                    }
                },
                P7a1: {
                    on: {
                        SPEAK: {
                            target: "P7a2",
                            actions: (context, event, actionMeta) => {
                                assign({
                                    actor: (context, event) => this.newbie
                                });
                                this.foreman.removeSpeechBubble();
                                this.sayFromChoices(FinalChoiceState, Text, 'P7a2', actionMeta, this.newbie);
                            },
                        }
                    }
                },
                P7a2: {
                    exit: (context, event, actionMeta) => {
                        this.saveChoice(actionMeta, newbie);
                    },
                    on: {
                        SPEAK: [{
                            target: "P0",
                            actions: (context, event) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onFinishHandler?.();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 0;
                            }

                        },
                        {
                            target: "P0",
                            actions: (context, event) => {
                                this.foreman.removeSpeechBubble();
                                this.newbie.removeSpeechBubble();

                                this.onConversationEndHandler?.();
                            },
                            cond: (context: StateContext, event: StateEvent) => {
                                return this.newbie.lastChoice === 1;
                            }

                        }]
                    }
                },


            },
        });

        this.set();
    }

    set(): void {
        this.service = interpret(this.stateMachine).onTransition(
            (state, event) => {
                const actor = state.context.actor as Actor;
                const value = state.value as string;

                if (actor && Object.keys(Text).includes(value)) {
                    const text: string = (<any>Text)[value];
                    actor.say([text]);
                }
            }
        ).start();
    }
}