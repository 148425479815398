import Foreman from "../../characters/Foreman";
import Newbie from "../../characters/Newbie";
import { DEFAULT_HEIGHT, DEFAULT_WIDTH, RESIZE_FACTOR } from "../../globals";
import { Depth } from "../../models/Depth";
import { Floor } from "../../models/Floor";
import { GameScene } from "../../models/GameScene";
import CentralHubState from "../../states/CentralHubState";
import BaseScene from "../BaseScene";

export default class CentralHubScene extends BaseScene {
  private newbie!: Newbie;
  private foreman!: Foreman;
  private ambientSound!: Phaser.Sound.BaseSound;
  private neutralSound!: Phaser.Sound.BaseSound;
  private didFinishDialogTutorial: boolean = false;
  private canOpenPanel: boolean = false;
  private canGoToElevator: boolean = false;
  private restoredBanner?: Phaser.GameObjects.Image;

  constructor() {
    super(GameScene.CentralHub);
  }

  preload(): void {
    this.load.maxParallelDownloads = 1;
    this.loadVideo("central_hub_restored", "background/central_hub_fixed.mp4");
    this.loadVideo("central_hub", "background/central_hub.mp4");
  }

  create(): void {
    this.fadeIn();

    const didVisitElevator = this.store.sceneIsVisited(GameScene.Elevator);
    this.didFinishDialogTutorial = didVisitElevator;
    this.canOpenPanel = didVisitElevator;
    this.canGoToElevator = didVisitElevator;

    const linksFixed = this.store.getLinksFixed();
    let backgroundKey = "central_hub";
    if (linksFixed.length === 3) {
      backgroundKey = "central_hub_restored"
    }

    this.add.video(0, 0, backgroundKey)
      .play(true)
      .setScale(RESIZE_FACTOR)
      .setPaused(false)
      .setLoop(true)
      .setOrigin(0, 0);

    this.ambientSound = this.sound.add("central_hub", { loop: true });
    this.ambientSound.play();

    this.neutralSound = this.sound.add("neutral", { loop: true });
    this.neutralSound.play();

    const floorY = DEFAULT_HEIGHT * 0.98;
    this.foreman = new Foreman(this, DEFAULT_WIDTH * 0.6, floorY);
    this.newbie = new Newbie(this, DEFAULT_WIDTH * 0.2, floorY);
    this.actors = [this.foreman, this.newbie];
    this.tutorial.shouldShowTutorial = !didVisitElevator;

    this.configureUI();

    this.stateMachine = new CentralHubState(this.newbie, this.foreman, this.tutorial);
    this.setupStateMachine();

    let panelKey = 'faulty_connection_panel'
    if (!didVisitElevator) {
      this.newbie.moveTo(DEFAULT_WIDTH * 0.4, () => {
        this.stateMachine.nextState();
      });
    } else if (linksFixed.length === 3) {
      panelKey = 'connection_restored_panel';
      setTimeout(() => {
        this.skipButton.setVisible(true);
        this.stateMachine.setState('SPEAK_ENDING');
      }, 1000);
      console.log('CONGRATS! THE END');
    } else {
      linksFixed.forEach((link) => {
        if (!this.store.isLinkFixedNoticed(link)) {
          this.restoreConnection(link);
          this.store.setLinkFixedNoticed(link);
          return;
        }
      });
    }

    const onHidePanel = () => {
      this.tutorial.hide()
      this.canGoToElevator = true;
      if (!didVisitElevator) {
        this.tutorial.showEnterElevatorAndProgressTutorial();
      }
    }

    this.add
      .rectangle(DEFAULT_WIDTH * 0.62, DEFAULT_HEIGHT * 0.16, DEFAULT_WIDTH * 0.26, DEFAULT_HEIGHT * 0.5)
      .setOrigin(0, 0)
      .setInteractive()
      .on("pointerup", () => {
        if (this.canOpenPanel) {
          this.showPanel(panelKey, onHidePanel, onHidePanel);
          if (!this.store.sceneIsVisited(GameScene.Elevator)) {
            this.tutorial.showClosePanelTutorial();
          }
        }
      });
      
    setTimeout(() => {
      this.foreman.on("pointerup", () => {
        if (!this.didFinishDialogTutorial) {
          this.skipButton.setVisible(true);
          this.stateMachine.nextState();
        }
      })
    }, 1000);

    this.foreman.stateMachine = this.stateMachine;
    this.newbie.stateMachine = this.stateMachine;

    this.stateMachine.onConversationStartHandler = () => this.setMenuOpen(false);
    this.stateMachine.onConversationEndHandler = () => {
      this.skipButton.setVisible(false);
      this.canOpenPanel = true;
      this.didFinishDialogTutorial = true;
    };
    this.stateMachine.onFinishHandler = () => {
      setTimeout(() => this.startScene(GameScene.End), 5000);
    }
    this.stateMachine.onSkip = () => {
      this.tutorial.hide();
      this.canOpenPanel = true;
      this.canGoToElevator = true;

      if (linksFixed.length === 3) {
        this.foreman.playHappyAnimation();
        this.newbie.playHappyAnimation();

        this.stateMachine.onConversationEndHandler?.();
        this.stateMachine.onFinishHandler?.();
      }
    }

    this.add
      .rectangle(DEFAULT_WIDTH * 0.04, DEFAULT_HEIGHT * 0.32, DEFAULT_WIDTH * 0.19, DEFAULT_HEIGHT * 0.58)
      .setOrigin(0, 0)
      .setInteractive()
      .on("pointerup", () => {
        if (this.canGoToElevator) {
          const destinationX = DEFAULT_WIDTH * 0.15;
          this.newbie.moveTo(destinationX);
          this.foreman.moveTo(destinationX);
          this.startScene(GameScene.Elevator, { currentFloor: Floor.BodyHub })
        }
      });

    if (this.getLastScene() === GameScene.Elevator) {
      this.walkActors([this.foreman, this.newbie], true);
    }

    this.events.on("shutdown", () => this.onShutDown());
  }

  restoreConnection(link: GameScene): void {
    this.canOpenPanel = false;
    let bannerName = "";
    let linkKey = "SPEAK_LINK";
    switch (link) {
      case GameScene.HeartPancreasLink:
        linkKey += "1";
        bannerName = "heart_pancreas_restored_banner";
        break;
      case GameScene.PancreasKidneysLink:
        linkKey += "2";
        bannerName = "pancreas_kidneys_restored_banner";
        break;
      case GameScene.KidneysHeartLink:
        linkKey += "3";
        bannerName = "kidneys_heart_restored_banner";
        break;
    }

    setTimeout(() => {
      this.restoredBanner = this.add
        .image(DEFAULT_WIDTH / 2, DEFAULT_HEIGHT / 2, bannerName)
        .setDepth(Depth.Panel)
        .setAlpha(0);

      this.showTemporaryBanner(this.restoredBanner, () => {
        setTimeout(() => {
          this.skipButton.setVisible(true);
          this.stateMachine.setState(linkKey);
          this.canOpenPanel = true;
        }, 1000);
      });
    }, 1000);
  }

  onShutDown(): void {
    this.ambientSound.stop();
    this.neutralSound.stop();
  }

}
