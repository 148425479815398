import "phaser";
import { GameConfig } from "./config";

export class Game extends Phaser.Game {
  constructor(config: Phaser.Types.Core.GameConfig) {
    super(config);
  }
}

const game = new Game(GameConfig);

window.addEventListener("load", () => {
  game;
});
