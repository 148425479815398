import { ASSET_SIZE, DEFAULT_HEIGHT, DEFAULT_WIDTH, RENDER_SCALE } from "../globals";
import { GameScene } from "../models/GameScene";
import LocalStorageRegistry from "../objects/LocalStorageRegistry";
import Actor from "../characters/Actor";
import BaseState from "../states/BaseState";
import { Depth } from "../models/Depth";
import Popup from "../objects/Popup";
import Tutorial from "../objects/Tutorial";
import { TutorialElement } from "../models/TutorialElement";
import ProgressBar from "../objects/ProgressBar";
import { DialogSymbol } from "../models/DialogSymbol";
import ReferencesBox from "../objects/ReferencesBox";

export default class BaseScene extends Phaser.Scene {
  public store!: LocalStorageRegistry;
  private panelImage?: Phaser.GameObjects.Image;

  private closePanelButton?: Phaser.GameObjects.Rectangle;
  private menuButton!: Phaser.GameObjects.Image;
  private soundButton!: Phaser.GameObjects.Image;
  private restartButton!: Phaser.GameObjects.Image;
  private referencesButton!: Phaser.GameObjects.Image;
  public skipButton!: Phaser.GameObjects.Image;
  private isMenuOpen: boolean;
  protected didTalkToForeman: boolean;
  private restartPopup!: Popup;
  private referencesBox!: ReferencesBox;

  protected stateMachine!: BaseState;
  protected actors!: Array<Actor>;
  protected manAtWork!: Phaser.GameObjects.Image;
  protected phone!: Phaser.GameObjects.Image;
  protected blockingPanelButton!: Phaser.GameObjects.Rectangle;
  protected tutorial: Tutorial;
  protected progressBar: ProgressBar;

  constructor(config: string | Phaser.Types.Scenes.SettingsConfig) {
    super(config);
    this.isMenuOpen = false;
    this.didTalkToForeman = false;
    this.actors = [];
    this.store = new LocalStorageRegistry();

    this.tutorial = new Tutorial(this);
    this.progressBar = new ProgressBar(this);
  }

  loadMultiatlas(key: string, path: string, name: string): void {
    this.load.multiatlas(key, ASSET_SIZE + "/" + path + "/" + name + ".json", ASSET_SIZE + "/" + path)
  }

  loadImage(key: string, url: string): void {
    this.load.image(key, ASSET_SIZE + "/" + url);
  }

  loadVideo(key: string, url: string): void {
    this.load.video({ key: key, url: ASSET_SIZE + "/" + url, noAudio: true });
  }

  getCurrentGameScene(): GameScene {
    return this.constructor.name as GameScene;
  }

  getLastScene(): GameScene | null {
    return this.store.getLastScene();
  }

  fadeIn(): void {
    this.cameras.main.fadeIn(2000, 0, 0, 0);
  }

  startScene(scene: GameScene, data?: object): void {
    this.input.enabled = false;
    this.cameras.main.fadeOut(2000, 0, 0, 0);
    this.cameras.main.once(Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE, () => {
      const currentScene = this.getCurrentGameScene();
      const excludedScenes = [GameScene.Boot, GameScene.Title, GameScene.Loading];

      if (!excludedScenes.includes(currentScene)) {
        setTimeout(() => {
          this.store.setSceneVisited(currentScene);
        }, 2000);

        if (!excludedScenes.includes(scene)) {
          this.store.setLastScene(currentScene);
          this.store.setCurrentScene(scene);
        }
      }

      this.scene.start(scene, data);
    });
  }

  showPanels(key: string, count: number, onClose?: Function): void {
    this.showNextPanel(key + "_", 1, count, onClose);
  }

  private showNextPanel(key: string, n: number, max: number, onClose?: Function): void {
    if (n > max) {
      onClose?.();
      return;
    }

    const panelKey = key + n.toString();
    this.showPanel(panelKey, () => {
      this.showNextPanel(key, n + 1, max, onClose);
    }, onClose)
  }

  showPanel(key: string, onTouch?: Function, onClose?: Function): void {
    this.hidePanel();

    this.sound.play("inset_open");
    this.panelImage = this.add
      .image(DEFAULT_WIDTH * 0.5, DEFAULT_HEIGHT * 0.5, key)
      .setScale(0.7)
      .setDepth(Depth.Panel)

    this.blockingPanelButton = this.add
      .rectangle(0, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT)
      .setOrigin(0, 0)
      .setDepth(Depth.Panel + 1)
      .setInteractive()
      .on("pointerup", () => {
        this.hidePanel();
        onTouch?.();
      });

    const closeButtonSize = 160 * RENDER_SCALE;
    this.closePanelButton = this.add
      .rectangle(DEFAULT_WIDTH * 0.68, DEFAULT_HEIGHT * 0.02, closeButtonSize, closeButtonSize)
      .setOrigin(0, 0)
      .setDepth(Depth.Panel + 2)
      .setInteractive()
      .on("pointerup", () => {
        this.hidePanel();
        onClose?.();
      });
  }

  hidePanel(): void {
    this.panelImage?.destroy();
    this.closePanelButton?.destroy();
    this.blockingPanelButton?.destroy();
    this.sound.play("inset_close");
  }

  markActorAsInteracted(actor: Actor): void {
    this.store.addInteractedActor(this.getCurrentGameScene(), actor);
  }

  markActorAsCompleted(actor: Actor): void {
    this.store.addCompletedActor(this.getCurrentGameScene(), actor);
  }

  updateEngineersSymbols(engineers: Actor[], isLink: boolean): void {
    if (isLink) {
      const symbol = this.isLinkFixed() ? DialogSymbol.Fixed : DialogSymbol.Task;
      engineers.forEach((engineer) => {
        engineer.showDialogSymbol(symbol);
      });
    } else {
      const completedActors = this.store.getCompletedActors(this.getCurrentGameScene());
      engineers.forEach((engineer) => {
        const symbol = completedActors.includes(engineer.name) ? DialogSymbol.Done : DialogSymbol.Talk;
        engineer.showDialogSymbol(symbol);
      });
    }
  }

  setIsMuted(isMuted: boolean): void {
    this.game.sound.volume = isMuted ? 0 : 1;
    this.store.setIsMuted(isMuted);
  }

  configureUI(): void {
    this.createMenuButton();
    this.createSoundButton();
    this.createRestartButton();
    this.createReferencesButton();
    this.createSkipButton();
    this.updateProgressBar();
  }

  private updateProgressBar(): void {
    const progress = this.store.getLinksFixed().length
    this.progressBar.setProgress(progress);
  }

  private createMenuButton(): void {
    this.menuButton = this.add.image(DEFAULT_WIDTH * 0.05, DEFAULT_HEIGHT * 0.1, "menu_button")
      .setDepth(Depth.UI)
      .setInteractive()
      .on("pointerup", () => this.setMenuOpen(!this.isMenuOpen));
  }

  public setMenuOpen(isMenuOpen: boolean): void {
    this.isMenuOpen = isMenuOpen;

    const menuTextureKey = this.isMenuOpen ? "close_button" : "menu_button";
    this.menuButton.setTexture(menuTextureKey);
    const menuScale = this.isMenuOpen ? 0.7 : 1.0;
    this.menuButton.setScale(menuScale)
    this.soundButton.setVisible(this.isMenuOpen);
    this.restartButton.setVisible(this.isMenuOpen);
    this.referencesButton.setVisible(this.isMenuOpen);
  }

  createSoundButton(isInMenu: boolean = true): void {
    const isMuted = this.store.isMuted();
    this.setIsMuted(isMuted);
    const textureKey = isMuted ? "sound_off" : "sound_on";

    const y = isInMenu ? DEFAULT_HEIGHT * 0.22 : DEFAULT_HEIGHT * 0.1;
    this.soundButton = this.add.image(DEFAULT_WIDTH * 0.05, y, textureKey)
      .setVisible(!isInMenu)
      .setDepth(Depth.UI)
      .setInteractive()
      .on("pointerup", () => this.toggleIsMuted());
  }

  private toggleIsMuted() {
    const isMuted = !this.store.isMuted();
    this.setIsMuted(isMuted);
    const textureKey = isMuted ? "sound_off" : "sound_on";
    this.soundButton.setTexture(textureKey);
  }

  protected createReferencesButton(): void {
    this.referencesButton = this.add.image(DEFAULT_WIDTH * 0.05, DEFAULT_HEIGHT * 0.36, "references_button")
      .setVisible(false)
      .setDepth(Depth.UI)
      .setInteractive()
      .on("pointerup", () => this.showReferences());

    this.referencesBox = new ReferencesBox(this);
  }

  protected showReferences(onClose?: Function) {
    this.referencesBox.show(onClose);
  }

  private createRestartButton(): void {
    this.restartButton = this.add.image(DEFAULT_WIDTH * 0.05, DEFAULT_HEIGHT * 0.5, "restart_button")
      .setVisible(false)
      .setDepth(Depth.UI)
      .setInteractive()
      .on("pointerup", () => this.restartPopup.show());

    this.restartPopup = new Popup(this, () => this.restartGame());
  }

  private restartGame() {
    const isMuted = this.store.isMuted();
    this.store.reset();
    this.setIsMuted(isMuted);
    this.scene.start(GameScene.CentralHub);
  }

  private createSkipButton(): void {
    this.skipButton = this.add.image(DEFAULT_WIDTH * 0.95, DEFAULT_HEIGHT * 0.1, "fwd")
      .setVisible(false)
      .setDepth(Depth.UI)
      .setInteractive()
      .on("pointerup", () => this.skipDialog());
  }

  skipDialog(): void {
    this.skipButton.setVisible(false);

    this.actors.forEach(actor => actor.removeSpeechBubble());
    this.stateMachine.recreate();

    if (this.getCurrentGameScene() === GameScene.Elevator) {
      this.stateMachine.onFinishHandler?.();
    }
  }

  setManAtWorkSign(): void {
    let signColor = 'blue';
    const currentScene = this.getCurrentGameScene()
    switch (currentScene) {
      case GameScene.HeartPancreasLink:
        signColor = 'red';
        break;
      case GameScene.KidneysHeartLink:
        signColor = 'green';
        break;
    }

    this.manAtWork = this.add.image(DEFAULT_WIDTH * 0.64, DEFAULT_HEIGHT * 0.81, "menatwork_" + signColor)
      .setDepth(Depth.BackgroundElements)
      .setVisible(true)
  }

  setPhoneSign(x?: number): void {
    let signColor = 'blue';
    switch (this.getCurrentGameScene()) {
      case GameScene.HeartPancreasLink:
      case GameScene.Heart:
        signColor = 'red';
        break;
      case GameScene.KidneysHeartLink:
      case GameScene.Kidneys:
        signColor = 'green';
        break;

    }
    if (typeof x === 'undefined') {
      x = DEFAULT_WIDTH * 0.63;
    }
    this.phone = this.add.image(x, DEFAULT_HEIGHT * 0.79, "phonebox_" + signColor)
      .setDepth(Depth.BackgroundElements)
      .setVisible(true)
  }

  walkActors(actors: Actor[], fromLeft: boolean): void {
    const distanceX = DEFAULT_WIDTH * 0.1 * (fromLeft ? -1 : 1);
    actors.forEach(actor => {
      const actorX = actor.x;
      actor.setPosition(actorX + distanceX, actor.y);
      actor.moveTo(actorX);
    });
  }

  showTutorial(): void {
    const currentScene = this.getCurrentGameScene();
    if (!this.store.isTutorialFinished(TutorialElement.Characters)) {
      this.tutorial.showCharactersTutorial(currentScene);
    } else if (!this.store.isTutorialFinished(TutorialElement.Panels) && currentScene !== GameScene.Kidneys) {
      this.tutorial.showPanelsTutorial(currentScene);
    } else if (!this.store.isTutorialFinished(TutorialElement.Tunnels)) {
      this.tutorial.showTunnelsTutorial();
    }
  }

  setTutorialFinished(element: TutorialElement): void {
    this.store.setTutorialFinished(element);
    this.tutorial.hide();
  }

  playForemanFinishSceneDialog(): void {
    this.stateMachine.setState('SPEAK_FINISH');
    if (this.didTalkToForeman) {
      this.stateMachine.nextState();
    }
    this.didTalkToForeman = true;
  }

  skipDialogIfNeeded(actor: Actor, textsToSkip: integer = 1): void {
    const scene = this.getCurrentGameScene();
    const interactedActors = this.store.getInteractedActors(scene);
    if (interactedActors.includes(actor.name)) {
      for (var i = 0; i < textsToSkip; i++) {
        this.stateMachine.nextState();
      }
    }
  }

  showTemporaryBanner(banner: Phaser.GameObjects.Image, onComplete: Function): void {
    this.tweens.add({
      targets: banner,
      alpha: 1,
      duration: 1000,
      onComplete: () => {
        setTimeout(() => {
          this.tweens.add({
            targets: banner,
            alpha: 0,
            duration: 1000,
            onComplete: onComplete
          });
        }, 1000);
      }
    });
  }

  setLinkFixed(): void {
    const currentScene = this.getCurrentGameScene();
    this.store.setLinkFixed(currentScene);
    this.updateProgressBar();
  }

  isLinkFixed(): boolean {
    const currentScene = this.getCurrentGameScene();
    return this.store.getLinksFixed().includes(currentScene);
  }

  isLinkFixedNoticed(): boolean {
    const currentScene = this.getCurrentGameScene();
    return this.store.isLinkFixedNoticed(currentScene);
  }

  interactedActorsCount(): integer {
    const currentScene = this.getCurrentGameScene();
    return this.store.getInteractedActors(currentScene).length;
  }

  setupStateMachine(): void {
    this.stateMachine.chosenStates = this.store.getChosenStates();
    this.stateMachine.onSaveChosenStates = (states) => {
      this.store.saveChosenStates(states);
    }
  }
}
