export enum Text {
    P1 = `So this is...`,

    P2 = `... The pancreas! Yeah! Make yourself at home, get comfortable. I'll run you through everything you need to know here.`,
    P3 = `Uhhh, thanks... So what do you guys do down here?`,
    P4 = `We oversee enzyme production to help the body break down and digest food, and hormone production to control blood sugar levels. It's a fast-paced environment!`,

    P5a1 = `Oh hi! First day? How can I help?`,
    P5a2 = `What do you do here?`,
    P5a3 = `I'm in charge of the type 2 diabetes alert!`,
    P5a4a = `Type 2 diabetes? What's diabetes?`,
    P5a4b = `What happens if we get type 2 diabetes?`,
    P5a4c = `Who's at risk for type 2 diabetes?`,
    P5a4d = `What are the warning signs of type 2 diabetes?`,
    P5a5a = `Diabetes is caused when the body stops being able to control blood sugar levels properly. Type 2 diabetes is the most common type of diabetes, accounting for around 90% of all cases.`,
    P5a5b = `With type 2 diabetes, the body stops responding to the insulin it produces or stops producing enough insulin – and that leads to constantly high blood sugar levels.`,
    P5a5c = `Type 2 diabetes usually develops in older adults, but is now being seen more children, adolescents and younger adults.`,
    P5a5d1 = `They're all up there on the display – take a look for yourself...`,

    P5c1 = `Welcome to diabetes prevention, Newbie! What do you want to know?`,
    P5c2a = `What can I do to prevent type 2 diabetes?`,
    P5c2b = `What if I get type 2 diabetes? Is there a cure?`,
    P5c3a = `Actions like healthy diet, regular physical activity, not smoking and maintaining a healthy body weight can help prevent or handle diabetes. Check out the website [area=url:https://www.organs-talk.com/][color=#00FFFF][u]the OrgansTalk website[/u][/color][/area] for more.`,
    P5c5b = `Unfortunately, there's no cure for type 2 diabetes, but the risk of complications can be reduced by controlling blood sugar levels with medicines and lifestyle changes.`,

    P6a1 = `Hmm... I wonder where those tunnels lead?`,

    P7a1 = `So, seen all you need to see here?`,
    P7a2a = `Let's go back to the elevator.`,
    P7a2b = `I'm not finished talking to these guys yet!`,

}
export enum Eng2ChoiceState{
    P5a4a,
    P5a4b,
    P5a4c,
    P5a4d,
}
export enum Eng3ChoiceState{
    P5c2a,
    P5c2b,
}
export enum FinalChoiceState {
    P7a2a,
    P7a2b,
}