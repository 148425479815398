export enum Text {
    Ea0a = `Where do you want to go first?`,
    Ea0b = `You do know how to use an elevator, right?`,

    Ea1 = `The heart it is! You know, I started in the heart myself… Many years ago now. I was a pump muscle engineer, helping pump blood throughout the body to deliver nutrients to, and remove waste products from, the muscles and organs.`,
    Ea2 = `So… uh… why did you leave?`,
    Ea3 = `The stress! You have to be so careful – the cardiovascular system is essential; without it we wouldn’t survive.`,
    Ea4 = `So, what did you think? A lot to take in, huh? They can be pretty intense, but it’s a big job. Where do you want to go next?`,

    Eb1 = `You know, kidneys aren’t exactly my favourite department. The team are a good group though.`,
    Eb2 = `What do you mean?`,
    Eb3 = `Well, you know; it’s mostly waste removal. Not exactly the most glamorous job, filtering waste products from the blood. But we gotta regulate the water supply somehow!`,
    Eb4 = `Well, how about it? Are you a kidney man? No judgment from me! It’s good honest work. Which organ would you like to visit next?`,

    Ec1 = `Okay, let’s go check out the pancreas! Just a word of warning – these guys can be a bit intense.`,
    Ec2 = `Intense?`,
    Ec3 = `These guys are responsible for converting food into energy for the body’s cells. They create insulin and glucagon to regulate blood sugar – and I’m not sure they always get it quite right.`,
    Ec4 = `Boy, ten minutes with those guys and you come away needing a nap to recover. Where do you want to visit next?`,
}
