import BaseScene from "../scenes/BaseScene"
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from "../globals";
import { Depth } from "../models/Depth";
import { GameScene } from "../models/GameScene";

export default class Tutorial {
  private scene: BaseScene
  private images: Phaser.GameObjects.Image[] = [];
  public shouldShowTutorial: boolean;

  constructor(scene: BaseScene, shouldShowTutorial: boolean = true) {
    this.scene = scene;
    this.shouldShowTutorial = shouldShowTutorial;
  }

  showDialogTutorial(): void {
    this.hide();
    if (!this.shouldShowTutorial) {
      return
    }

    const arrow = this.scene.add.image(DEFAULT_WIDTH * 0.2, DEFAULT_HEIGHT * 0.74, "tutorial_arrow").setFlipY(true).setRotation(Math.PI / 4);
    const text = this.scene.add.image(DEFAULT_WIDTH * 0.2, DEFAULT_HEIGHT * 0.86, "dialog_tutorial");
    this.images.push(arrow, text);
    this.setDepth();
  }

  showTapCharacterTutorial(): void {
    this.hide();
    if (!this.shouldShowTutorial) {
      return
    }

    const arrow = this.scene.add.image(DEFAULT_WIDTH * 0.66, DEFAULT_HEIGHT * 0.46, "tutorial_arrow").setFlipX(true).setRotation(Math.PI / 4);
    const text = this.scene.add.image(DEFAULT_WIDTH * 0.66, DEFAULT_HEIGHT * 0.34, "tap_character_tutorial");
    this.images.push(arrow, text);
    this.setDepth();
  }

  showPickResponseTutorial(): void {
    this.hide();
    if (!this.shouldShowTutorial) {
      return
    }

    const arrow = this.scene.add.image(DEFAULT_WIDTH * 0.47, DEFAULT_HEIGHT * 0.39, "tutorial_arrow").setRotation(Math.PI / 2);
    const arrow2 = this.scene.add.image(DEFAULT_WIDTH * 0.47, DEFAULT_HEIGHT * 0.59, "tutorial_arrow").setRotation(Math.PI / 2).setFlipX(true);
    const text = this.scene.add.image(DEFAULT_WIDTH * 0.52, DEFAULT_HEIGHT * 0.49, "pick_response_tutorial");
    this.images.push(arrow, arrow2, text);
    this.setDepth();
  }

  showExamineObjectTutorial(): void {
    this.hide();
    if (!this.shouldShowTutorial) {
      return
    }

    const arrow = this.scene.add.image(DEFAULT_WIDTH * 0.8, DEFAULT_HEIGHT * 0.58, "tutorial_arrow").setRotation(Math.PI * 3 / 4);
    const text = this.scene.add.image(DEFAULT_WIDTH * 0.8, DEFAULT_HEIGHT * 0.7, "examine_object_tutorial");
    this.images.push(arrow, text);
    this.setDepth();
  }

  showClosePanelTutorial(): void {
    this.hide();
    if (!this.shouldShowTutorial) {
      return
    }

    const arrow = this.scene.add.image(DEFAULT_WIDTH * 0.76, DEFAULT_HEIGHT * 0.7, "tutorial_arrow").setRotation(Math.PI * 3 / 5);
    const text = this.scene.add.image(DEFAULT_WIDTH * 0.82, DEFAULT_HEIGHT * 0.8, "close_panel_tutorial");
    this.images.push(arrow, text);
    this.setDepth(Depth.PanelTutorial);
  }

  showExamineElevatorPanelTutorial(): void {
    this.hide();
    if (!this.shouldShowTutorial) {
      return
    }

    const arrow = this.scene.add.image(DEFAULT_WIDTH * 0.57, DEFAULT_HEIGHT * 0.36, "tutorial_arrow").setRotation(-Math.PI / 8);
    const text = this.scene.add.image(DEFAULT_WIDTH * 0.58, DEFAULT_HEIGHT * 0.24, "examine_tutorial");
    this.images.push(arrow, text);
    this.setDepth();
  }

  showEnterElevatorAndProgressTutorial(): void {
    this.hide();
    if (!this.shouldShowTutorial) {
      return
    }

    const enterElevatorArrow = this.scene.add.image(DEFAULT_WIDTH * 0.17, DEFAULT_HEIGHT * 0.38, "tutorial_arrow").setFlipX(true).setRotation(Math.PI / 6);
    const enterElevatorText = this.scene.add.image(DEFAULT_WIDTH * 0.17, DEFAULT_HEIGHT * 0.26, "enter_elevator_tutorial");
    const progressArrow = this.scene.add.image(DEFAULT_WIDTH * 0.88, DEFAULT_HEIGHT * 0.58, "tutorial_arrow").setRotation(-Math.PI / 2);
    const progressText = this.scene.add.image(DEFAULT_WIDTH * 0.8, DEFAULT_HEIGHT * 0.46, "progress_tutorial");
    this.images.push(enterElevatorArrow, enterElevatorText, progressArrow, progressText);
    this.setDepth();
  }

  showVisitOrganTutorial(): void {
    this.hide();
    if (!this.shouldShowTutorial) {
      return
    }

    const arrow = this.scene.add.image(DEFAULT_WIDTH * 0.28, DEFAULT_HEIGHT * 0.4, "tutorial_arrow").setRotation(-Math.PI / 2);
    const text = this.scene.add.image(DEFAULT_WIDTH * 0.22, DEFAULT_HEIGHT * 0.3, "visit_organ_tutorial");
    this.images.push(arrow, text);
    this.setDepth(Depth.PanelTutorial);
  }

  showCharactersTutorial(scene: GameScene): void {
    this.hide();
    if (!this.shouldShowTutorial) {
      return
    }

    switch (scene) {
      case GameScene.Heart:
        const heartEngineer1Arrow = this.scene.add.image(DEFAULT_WIDTH * 0.34, DEFAULT_HEIGHT * 0.24, "tutorial_arrow").setFlipX(true).setRotation(Math.PI * 2 / 5);
        const heartEngineer1Text = this.scene.add.image(DEFAULT_WIDTH * 0.38, DEFAULT_HEIGHT * 0.14, "tap_character_tutorial");
        const heartEngineer2Arrow = this.scene.add.image(DEFAULT_WIDTH * 0.7, DEFAULT_HEIGHT * 0.24, "tutorial_arrow").setRotation(-Math.PI * 2 / 5);
        const heartEngineer2Text = this.scene.add.image(DEFAULT_WIDTH * 0.64, DEFAULT_HEIGHT * 0.14, "tap_character_tutorial");
        const heartEngineer3Arrow = this.scene.add.image(DEFAULT_WIDTH * 0.36, DEFAULT_HEIGHT * 0.74, "tutorial_arrow").setFlipX(true).setRotation(Math.PI * 2 / 5);
        const heartEngineer3Text = this.scene.add.image(DEFAULT_WIDTH * 0.4, DEFAULT_HEIGHT * 0.64, "tap_character_tutorial");
        this.images.push(heartEngineer1Arrow, heartEngineer1Text, heartEngineer2Arrow, heartEngineer2Text, heartEngineer3Arrow, heartEngineer3Text);
        break;
      case GameScene.Kidneys:
        const kidneysEngineer1Arrow = this.scene.add.image(DEFAULT_WIDTH * 0.32, DEFAULT_HEIGHT * 0.24, "tutorial_arrow").setFlipX(true).setRotation(Math.PI * 2 / 5);
        const kidneysEngineer1Text = this.scene.add.image(DEFAULT_WIDTH * 0.38, DEFAULT_HEIGHT * 0.14, "tap_character_tutorial");
        const kidneysEngineer2Arrow = this.scene.add.image(DEFAULT_WIDTH * 0.74, DEFAULT_HEIGHT * 0.24, "tutorial_arrow").setRotation(-Math.PI * 2 / 5);
        const kidneysEngineer2Text = this.scene.add.image(DEFAULT_WIDTH * 0.68, DEFAULT_HEIGHT * 0.14, "tap_character_tutorial");
        const kidneysEngineer3Arrow = this.scene.add.image(DEFAULT_WIDTH * 0.64, DEFAULT_HEIGHT * 0.74, "tutorial_arrow").setRotation(-Math.PI * 2 / 5);
        const kidneysEngineer3Text = this.scene.add.image(DEFAULT_WIDTH * 0.58, DEFAULT_HEIGHT * 0.64, "tap_character_tutorial");
        this.images.push(kidneysEngineer1Arrow, kidneysEngineer1Text, kidneysEngineer2Arrow, kidneysEngineer2Text, kidneysEngineer3Arrow, kidneysEngineer3Text);
        break;
      case GameScene.Pancreas:
        const pancreasEngineer1Arrow = this.scene.add.image(DEFAULT_WIDTH * 0.36, DEFAULT_HEIGHT * 0.32, "tutorial_arrow").setFlipX(true).setRotation(Math.PI * 2 / 5);
        const pancreasEngineer1Text = this.scene.add.image(DEFAULT_WIDTH * 0.42, DEFAULT_HEIGHT * 0.22, "tap_character_tutorial");
        const pancreasEngineer2Arrow = this.scene.add.image(DEFAULT_WIDTH * 0.7, DEFAULT_HEIGHT * 0.32, "tutorial_arrow").setRotation(-Math.PI * 2 / 5);
        const pancreasEngineer2Text = this.scene.add.image(DEFAULT_WIDTH * 0.64, DEFAULT_HEIGHT * 0.22, "tap_character_tutorial");
        this.images.push(pancreasEngineer1Arrow, pancreasEngineer1Text, pancreasEngineer2Arrow, pancreasEngineer2Text);
        break;
      default:
        break;
    }

    this.setDepth();
  }

  showPanelsTutorial(scene: GameScene): void {
    this.hide();
    if (!this.shouldShowTutorial) {
      return
    }

    switch (scene) {
      case GameScene.Heart:
        const leftHeartExamineArrow = this.scene.add.image(DEFAULT_WIDTH * 0.16, DEFAULT_HEIGHT * 0.44, "tutorial_arrow").setRotation(-Math.PI / 8);
        const leftHeartExamineText = this.scene.add.image(DEFAULT_WIDTH * 0.16, DEFAULT_HEIGHT * 0.34, "examine_tutorial");
        const rightHeartExamineArrow = this.scene.add.image(DEFAULT_WIDTH * 0.84, DEFAULT_HEIGHT * 0.44, "tutorial_arrow").setFlipX(true).setRotation(Math.PI / 8);
        const rightHeartExamineText = this.scene.add.image(DEFAULT_WIDTH * 0.84, DEFAULT_HEIGHT * 0.34, "examine_tutorial");
        this.images.push(leftHeartExamineArrow, leftHeartExamineText, rightHeartExamineArrow, rightHeartExamineText);
        break;
      case GameScene.Pancreas:
        const pancreasExamineArrow = this.scene.add.image(DEFAULT_WIDTH * 0.18, DEFAULT_HEIGHT * 0.24, "tutorial_arrow").setRotation(-Math.PI / 8);
        const pancreasExamineText = this.scene.add.image(DEFAULT_WIDTH * 0.18, DEFAULT_HEIGHT * 0.14, "examine_tutorial");
        this.images.push(pancreasExamineArrow, pancreasExamineText);
        break;
      default:
        break;
    }

    this.setDepth();
  }

  showTunnelsTutorial(): void {
    this.hide();
    if (!this.shouldShowTutorial) {
      return
    }

    const leftTunnelArrow = this.scene.add.image(DEFAULT_WIDTH * 0.1, DEFAULT_HEIGHT * 0.78, "tutorial_arrow").setRotation(Math.PI * 3 / 5);
    const leftTunnelText = this.scene.add.image(DEFAULT_WIDTH * 0.16, DEFAULT_HEIGHT * 0.88, "tunnel_tutorial");
    const rightTunnelArrow = this.scene.add.image(DEFAULT_WIDTH * 0.9, DEFAULT_HEIGHT * 0.78, "tutorial_arrow").setFlipY(true).setRotation(Math.PI * 2 / 5);
    const rightTunnelText = this.scene.add.image(DEFAULT_WIDTH * 0.84, DEFAULT_HEIGHT * 0.88, "tunnel_tutorial");
    this.images.push(leftTunnelArrow, leftTunnelText, rightTunnelArrow, rightTunnelText);

    this.setDepth();
  }

  hide(): void {
    this.images.forEach(image => image.destroy());
    this.images = []
  }

  private setDepth(depth: Depth = Depth.Tutorial) {
    this.images.forEach(image => image.setDepth(depth));
  }
}