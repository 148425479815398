import { DEFAULT_HEIGHT, DEFAULT_WIDTH, RENDER_SCALE } from "../globals";
import { Depth } from "../models/Depth";
import { ReferencesText } from "../texts/references";

export default class ReferencesBox {
  private background: Phaser.GameObjects.Image;
  private closeButton: Phaser.GameObjects.Image;
  private title: Phaser.GameObjects.Text;
  private htmlText: Phaser.GameObjects.DOMElement;

  private onClose?: Function;

  constructor(scene: Phaser.Scene) {
    this.background = scene.add
      .image(DEFAULT_WIDTH / 2, DEFAULT_HEIGHT / 2, "popup_big_background")
      .setDepth(Depth.UI)
      .setInteractive();

    this.closeButton = scene.add
      .image(0, 0, "popup_close")
      .setOrigin(1, 0)
      .setDepth(Depth.UI)
      .setInteractive()
      .on("pointerup", () => this.hide());

    const halfInnerWidth = this.background.displayWidth * 0.46;
    this.closeButton.setPosition(this.background.x + halfInnerWidth, this.background.y - this.background.displayHeight * 0.45);

    const x = this.background.x - halfInnerWidth;
    const y = this.background.y - this.background.displayHeight * 0.45;
    const width = halfInnerWidth * 2;
    const height = this.background.displayHeight * 0.9 - this.closeButton.displayHeight * 2;

    this.title = scene.add.text(x, y, "References", {
      fontFamily: "Quicksand",
      fontSize: (60 * RENDER_SCALE) + "px",
      color: "#0a005b",
    })
    .setOrigin(0, 0)
    .setDepth(Depth.UI);

    const referencesText = ReferencesText.r0.replaceAll(/<(.*)>/g, "<a href='$1' target='_blank'>$1</a>").replaceAll("\n", "<br/>");
    this.htmlText = scene.add
      .dom(x, y + this.closeButton.displayHeight * 2)
      .createFromHTML(`<div style='font: 20px Quicksand; color: #0a005b; width: ${width}px; height: ${height}px; overflow: auto; word-wrap: break-word; overflow-wrap: break-word'>${referencesText}</div>`)
      .setOrigin(0, 0)
      .setDepth(Depth.UI);

    this.hide();
  }

  show(onClose?: Function): void {
    this.setVisible(true);
    this.onClose = onClose;
  }

  hide(): void {
    this.setVisible(false);
    this.onClose?.();
  }

  private setVisible(visible: boolean): void {
    this.background.setVisible(visible);
    this.closeButton.setVisible(visible);
    this.title.setVisible(visible);
    this.htmlText.setVisible(visible);
  }
}